import React, { useState, useRef, useEffect } from 'react';
import PromptDialog from '../components/PromptDialog';
import { getCurrentDomain, getImagePath, getImagePathToDownload } from '../utils';
import CustomSelect from '../components/CustomSelect';
import checkedIcon from '../images/checked.svg'
import uncheckedIcon from '../images/unchecked.svg';
import { RangeSliderWithCustomDisplay } from '../components/RangeSliderWithCustomDisplay/RangeSliderWithCustomDisplay';
import InlineInputWithTitle from '../components/InlineInputWithTitle/InlineInputWithTitle';
import Modal from 'react-bootstrap/Modal';
import { APP_ACTIVE_COLOR } from '../constant';


const DEFAULT_PROMPT_TEXT = "car photography, a white Nissan X-trail, on a highway, mountain at the back, near the ocean, beautiful scenery, realistic, Cinematic, Cinematic Shot, cinematic lighting, 8k uhd, high quality, film grain, Fujifilm XT3";
const START_GENERATING_TEXT = '开始生成';
const ONE_MIN_IN_MILLISECONDS = 60000;
const SET_VIDEO_RATIO = 0.6;




const AI_MODES = [
  {
    label: 'AI营销',
    value: 'adver'
  },
  {
    label: 'AI渲染',
    value: 'render'
  }
]

const FODLER_TO_GET_MODELS = [
  {
    label: 'sd15',
    value: 'sd15'
  },
  {
    label: 'sdxl',
    value: 'sdxl'
  },
  
]

const VAE_SDXL = {
  label: 'sdxl_vae.safetensors',
  value: 'sdxl_vae.safetensors'
}

const VEA_SD15 = {
  label: 'vae-ft-mse-840000-ema-pruned.safetensors',
  value: 'vae-ft-mse-840000-ema-pruned.safetensors'
}

const RENDERING_SCRIPTS = [
  {
    label: '线稿白模渲染',
    value: 'rendering_canny.py'
  },
  {
    label: '参考图渲染',
    value: 'rendering_adapter.py'
  },
  {
    label: '文生图',
    value: 'rendering_t2i.py'
  }
]


const MOCK_LORA_MODELS = [
  {
    label: '卡通_十二生肖_1.0.safetensors',
    value: '卡通_十二生肖_1.0.safetensors',
    weight: 0.4 * 100,
    isSelect: false
  },
  {
    label: '工业设计多角度手绘产品_工业设计多角度手绘产品.safetensors',
    value: '工业设计多角度手绘产品_工业设计多角度手绘产品.safetensors',
    weight: 0.4 * 100,
    isSelect: false
  },
  {
    label: '工业设计手绘草图_工业设计手绘草图1.0.safetensors',
    value: '工业设计手绘草图_工业设计手绘草图1.0.safetensors',
    weight: 0.4 * 100,
    isSelect: false
  },
  {
    label: '未来城-2_v2.0.safetensors',
    value: '未来城-2_v2.0.safetensors',
    weight: 0.4 * 100,
    isSelect: false
  },
]


const SAMPLER_NAMES = [
  {
    label: "euler_accentral",
    value: "euler_accentral"
  },
  {
    label: "dpmpp_2m",
    value: "dpmpp_2m"
  },
]

const SCHEDULER_EULER = {
  label: "normal",
  value: "normal"
}

const SCHEDULER_DPMPP = {
  label: "karras",
  value: "karras"
}

const smallerViewVideoWidth = 300;

const ScreenCapture = ({ isRenderingTab }) => {
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const screenshotFromVideoToSelectArea = useRef(null);
  const modalRef = useRef(null);
  const canvasRef = useRef(null);
  const [selectArea, setSelectArea] = useState(null);
  const videoWrapper = useRef(null);
  const cropedImageCanvas = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [negativePromp, setNegativePrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState(0);
  const [displayTextOfButtonStartGenerating, setDisplayTextOfButtonStartGenerating] = useState(START_GENERATING_TEXT);
  const [image_res_list, setImageResList] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [ai_mode, setAiMode] = useState(AI_MODES[0]);
  const [folder_to_get_models, setFolderToGetModels] = useState(FODLER_TO_GET_MODELS[0]);
  const [sdxlModels, setSdxlModels] = useState([]);
  const [sd15Models, setSd15Models] = useState([]);
  const [selectedVaeModel, setSelectedVaeModel] = useState(VEA_SD15);
  const [sd15loraModels, setSd15LoraModels] = useState(MOCK_LORA_MODELS);
  const [sdxlLoraModels, setSdxlLoraModels] = useState(MOCK_LORA_MODELS);
  const [isSelectedLora, setIsSelectedLora] = useState(false);
  const [loraWeight, setLoraWeight] = useState(50);
  const [lightingWeight, setLightingWeight] = useState(50);
  const [samplerNames, setSamplerNames] = useState(SAMPLER_NAMES);
  const [selectedSamplerName, setSelectedSamplerName] = useState(SAMPLER_NAMES[0]);
  const [selectedSchedulerName, setSelectedSchedulerName] = useState(SCHEDULER_EULER);
  const [cfgScale, setCfgScale] = useState(7);
  const [steps, setSteps] = useState(25);
  const [clipSkip, setClipSkip] = useState(2);
  const [selectedRenderingScript, setSelectedRenderingScript] = useState(RENDERING_SCRIPTS[0]);
  const [adataperWeight, setAdataperWeight] = useState(50);
  const [selectedTab, setSelectedTab] = useState('advertisement');
  const [showLoraModelModal, setShowLoraModelModal] = useState(false);
  const streamingVideoSmallerView = useRef(null);

  const updateTextOfButtonStartGenerating = (text) => {
    setDisplayTextOfButtonStartGenerating(text);
  }


  useEffect(() => {
    // init croped image canvas 
    drawPlaceholderImage()

  }, []);

  const drawPlaceholderImage = () => {
    const canvas = cropedImageCanvas.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    //text: center
    // 上传或录屏的原始图片显示区域
    ctx.textAlign = 'center';
    ctx.fillStyle = 'black';
    ctx.font = '20px Arial';
    ctx.fillText('上传或录屏的原始图片显示区域', canvas.width / 2, canvas.height / 2);
  }
    

  useEffect(() => {
    // register event mousemove, mouseup, mousedown to draw select are on modal
    const modal = modalRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let startX = 0;
    let startY = 0;
    let width = 0;
    let height = 0;
    let isDrawing = false;

    modal.addEventListener('mousedown', (e) => {
      isDrawing = true;
      startX = e.clientX;
      startY = e.clientY;
    }
    );

    modal.addEventListener('mousemove', (e) => {
      if (!isDrawing) return;
      width = e.clientX - startX;
      height = e.clientY - startY;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.strokeStyle = 'red';
      ctx.strokeWidth = 1;
      ctx.strokeRect(startX, startY, width, height);
    }
    );

    modal.addEventListener('mouseup', (e) => {
      isDrawing = false;
      //store the selected area to state
      setSelectArea({
        startX,
        startY,
        width,
        height,
      });

      getImageFromCropAreaToDrawImage(startX, startY, width, height);
      modal.style.display = 'none';
    }
    );
  }, []);

  const convertToOptions = (data) => {
    return data.map((item) => {
      return {
        label: item,
        value: item,
      }
    })
  }


  const convertToLoraModelOptions = (data) => {
    return data.map((item) => {
      return {
        label: item,
        value: item,
        weight: 0.4 * 100,
        isSelected: false
      }
    })
  }


  useEffect(() => {
    const getModels = async () => {
      try {
        const result = await fetch(getCurrentDomain() + '/get_models', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: ONE_MIN_IN_MILLISECONDS * 30,
        });
        const res = await result.json();
        const data = res.all_models;


        setSdxlModels(convertToOptions(data.sdxl));
        setSd15Models(convertToOptions(data.sd15));
        setSdxlLoraModels(convertToLoraModelOptions(data.sdxllora));
        setSd15LoraModels(convertToLoraModelOptions(data.sd15lora));
        setSelectedModel(convertToOptions(data.sd15)[0]);
      }
      catch (e) {
        console.log(e);
      }
    }
    getModels();
  }, []);


  const getImageFromCropAreaToDrawImage = (startX, startY, width, height) => {
    if (width < 0) {
      startX = startX + width;
      width = Math.abs(width);
    }
    if (height < 0) {
      startY = startY + height;
      height = Math.abs(height);
    }
    const img = screenshotFromVideoToSelectArea.current;
    const ratio = img.naturalWidth / img.width;
    width = width * ratio;
    height = height * ratio;
    startX = startX * ratio;
    startY = startY * ratio;
    const canvas = cropedImageCanvas.current;
    const canvasHeight = height * 600 / width;

    canvas.width = 600;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(img, startX, startY, width, height, 0, 0, 600, canvasHeight);
  }

  const handleCapture = async () => {
    try {
      const capturedStream = await navigator.mediaDevices.getDisplayMedia(
        {
          video: true
        },
      );

      setStream(capturedStream)
      if (videoRef.current) {
        videoRef.current.srcObject = capturedStream;
        const sourceVideoWidth = capturedStream.getVideoTracks()[0].getSettings().width;
        const sourceVideoHeight = capturedStream.getVideoTracks()[0].getSettings().height;
        dumpOptionsInfo();
      }
    } catch (error) {
      console.error('Error accessing screen:', error);
    }
  };

  function dumpOptionsInfo() {
    const videoTrack = videoRef.current.srcObject.getVideoTracks()[0];
  }

  const handleStopCapture = () => {
    setSelectArea(null);
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      setStream(null);
    }
    drawPlaceholderImage();
  };


  const getBase64ImageFromStreamingVideo = () => {
    const video = stream.getVideoTracks()[0];
    const image = new ImageCapture(video);
    return image.grabFrame().then((bitmap) => {
      const canvas = document.createElement('canvas');
      canvas.width = bitmap.width;
      canvas.height = bitmap.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(bitmap, 0, 0);
      return canvas.toDataURL();
    });
  }


  const openPopupToSetArea = async () => {
    // display the video in a full screen modal
    const modal = modalRef.current;
    const videoWidth = videoRef.current.clientWidth;
    const videoHeight = videoRef.current.clientHeight;
    let imageWidth = window.innerWidth * SET_VIDEO_RATIO;
    if (videoHeight > videoWidth) {
      imageWidth = (videoWidth / videoHeight) * imageWidth;  
    }

    screenshotFromVideoToSelectArea.current.src = await getBase64ImageFromStreamingVideo();
    screenshotFromVideoToSelectArea.current.width = imageWidth;
    modal.style.width = imageWidth + 'px';
    modal.style.display = 'block';

  }

  const computeSizeOfArea = (area) => {
    const widthRatio = screenshotFromVideoToSelectArea.current.width / videoRef.current.clientWidth;
    console.log('widthRatio', widthRatio);
    console.log(screenshotFromVideoToSelectArea.current.clientWidth, videoRef.current.clientWidth)
    console.log('area', area);
    if (area.width < 0) {
      area.startX = area.startX + area.width;
      area.width = Math.abs(area.width);
    }

    if (area.height < 0) {
      area.startY = area.startY + area.height;
      area.height = Math.abs(area.height);
    }

    return {
      top: area.startY / widthRatio + videoRef.current.offsetTop,
      left: area.startX / widthRatio + videoRef.current.offsetLeft,
      width: area.width / widthRatio,
      height: area.height / widthRatio,
    }
  }

  const renderSelectArea = () => {
    if (!selectArea) {
      return null;
    }

    const sizeOfArea = computeSizeOfArea(selectArea);

    return (
      <div style={{
        position: 'absolute',
        border: '1px solid red',
        top: sizeOfArea.top,
        left: sizeOfArea.left,
        width: sizeOfArea.width,
        height: sizeOfArea.height,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 999,
      }}>
      </div>
    )
  }

  const renderStreamingVideoSmallView = () => {
    return (
      <div style={{
        width: smallerViewVideoWidth,
        position: 'relative',
        height: smallerViewVideoWidth,
        display: stream && isNotRenderingT2I() ? 'block' : 'none',
        }}
        ref={streamingVideoSmallerView}
      >
        <div ref={videoWrapper} style={{
          width: '100%',
          position: 'relative',
        }}>
          {renderSelectArea()}
          <video id='video' autoPlay ref={videoRef} style={{
            height: smallerViewVideoWidth,
            position: 'absolute',
            top: 0,
            left: 0,
            height: smallerViewVideoWidth
          }}
          />

        </div>
      </div>
    )
  }

  const renderCropdedImageCanvas = () => {
    return (
      <div style={{
        width: 600,
        marginTop: 20,
        display: isNotRenderingT2I() ? 'block' : 'none',
      }}>
        <canvas ref={cropedImageCanvas} height={400} width={600} />
      </div>
    )
  }

  const uploadImageFromLocalDevice = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      //draw file to canvas
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = cropedImageCanvas.current;
          const ctx = canvas.getContext('2d');
          canvas.width = 600
          const canvasHeight = img.height * 600 / img.width;
          canvas.height = canvasHeight;
          ctx.drawImage(img, 0, 0, 600, canvasHeight);
        }
        img.src = e.target.result;
      }
      reader.readAsDataURL(file);
    }
    input.click();
  }


  const renderButtons = () => {
    const isSelectRenderT2I = isRenderingTab && selectedRenderingScript.value === 'rendering_t2i.py';
    return (
      <div style={{
        marginLeft: 10,
        paddingTop: 20,
        paddingLeft:10,
        paddingRight:10,
        display: 'flex',
        justifyContent: 'space-between',
        display: isNotRenderingT2I() ? 'flex' : 'none',
      
      }}>
          <div className='button-start-generating' onClick={!isSelectRenderT2I ? uploadImageFromLocalDevice : null} style={{
            marginBottom: 20,
          }}
            disabled={isSelectRenderT2I}
          >上传图片</div>
        
        {!stream ? (
          <div className='button-start-generating' onClick={!isSelectRenderT2I ? handleCapture : null}>录屏</div>
        ) : (
          <>
            <div className='button-start-generating' onClick={handleStopCapture}>停止屏幕录制</div>
            <div style={{
            }} className='button-start-generating' onClick={openPopupToSetArea}>选择相框</div>
        </>
          
        )}
        </div>
    )
  }

  const renderPopupToSetArea = () => {
    return (
      <div
        id='modal-to-set-area'
        style={{
          display: 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '70vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 9999999,
        }}
        ref={modalRef}
      >
        <canvas
          width={SET_VIDEO_RATIO * window.innerWidth}
          height={window.innerHeight}
          id='canvas'
          ref={canvasRef}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            pointerEvents: 'none',
          }} />

        <img ref={screenshotFromVideoToSelectArea} style={{
          userSelect: 'none',
          "-webkit-user-drag": 'none',
          pointerEvents: 'none',

        }} />
      </div>
    )
  }

  const renderPromptDialog = () => {
    return (
      <div className='col-12' style={{
      }}>
        <PromptDialog
          title={"提示词输入"}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
      </div>
    )
  }

  const renderNegativePromptDialog = () => {
    return (
      <div className='col-12' style={{
        marginTop: 20,
      }}>
        <PromptDialog
          title={"反向提示词输入"}
          value={negativePromp}
          onChange={(e) => setNegativePrompt(e.target.value)}
        />
      </div>
    )
  }

  const renderProgressBackground = () => {
    return (
      <div className='progress-background' style={{
        width: `${generatingProgress}%`,
      }} />
    )
  }

  const startNewIntervalToUploadProgress = () => {
    let progress = 0;
    const timeInSeconds = 2.5;
    const ESTIMATE_TIME_TO_FINISH_IN_MILLISECOND = 1000 * 60 * timeInSeconds;
    const interval = setInterval(() => {
      progress += 1;
      if (progress > generatingProgress) {
        setGeneratingProgress(progress);
      }
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, ESTIMATE_TIME_TO_FINISH_IN_MILLISECOND / 100);
    return interval;
  }


  const getSelectedLoraModels = () => {
    if (!isSelectedLora) {
      return [];
    }
    if (isSelectedSDXL()) {
      return sdxlLoraModels.filter((model) => model.isSelected);
    }
    return sd15loraModels.filter((model) => model.isSelected);
  }

  const handleGenerate = async () => {
    setLoading(true);
    setGeneratingProgress(0);
    console.log(getSelectedLoraModels())

    // get image from croped canvas
    const canvas = cropedImageCanvas.current;
    const data = canvas.toDataURL('image/png');
    const body = JSON.stringify({
      image: data,
      prompt: prompt + ', award-winning picture,highly detailed,ultra-high resolutions,32K UHD,sharp focus,best quality,masterpiece,',
      sd_model: folder_to_get_models.value + '/' + selectedModel.value,
      negative_prompt: negativePromp + ' ,(worst quality:2),(low quality:2),(normal quality:2),lowres,normal quality,((monochrome)),((grayscale)),skin spots,acnes,skin blemishes,age spot,(ugly:1.331),(duplicate:1.331),(morbid:1.21),(mutilated:1.21),(tranny:1.331),mutated hands,(poorly drawn hands:1.5),blurry,(bad anatomy:1.21),(bad proportions:1.331),extra limbs,(disfigured:1.331),(missing arms:1.331),(extra legs:1.331),(fused fingers:1.61051),(too many fingers:1.61051),(unclear eyes:1.331),lowers,bad hands,missing fingers,extra digit,bad hands,missing fingers,(((extra arms and legs))),',
      folder: folder_to_get_models.value,
      vae: selectedVaeModel.value,
      lora: isSelectedLora,
      lora_models: getSelectedLoraModels().map((model) => [folder_to_get_models.value + '/' + model.value, model.weight / 100 * 2]),
      clip_skip: clipSkip,
      cfg_scale: cfgScale,
      steps: steps,
      sampler_name: selectedSamplerName.value,
      scheduler_name: selectedSchedulerName.value,
      rendering_script: selectedRenderingScript.value,
      adapter_weight: adataperWeight / 100,
      isRenderingTab: isRenderingTab,
      lighting_weight: lightingWeight / 100,
    });
    setImageResList(null);
    try {
      const numberOfImagesToGenerateWhenClick = 1;
      for (let i = 0; i < numberOfImagesToGenerateWhenClick; i++) {
        const updateProgressInterval = startNewIntervalToUploadProgress();
        try {
          const numberOfRemainingImages = numberOfImagesToGenerateWhenClick - i;
          updateTextOfButtonStartGenerating(`剩余图片: ${numberOfRemainingImages}`);
          const result = await fetch(getCurrentDomain() + 'generate_from_image', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: body,
            timeout: ONE_MIN_IN_MILLISECONDS * 30,
          });
          clearInterval(updateProgressInterval);
          setGeneratingProgress(100);
          const data = await result.json();
          const image_res = data.new_images;
          setImageResList(image_res);
        }
        catch (e) {
          console.log(e);
          setGeneratingProgress(0);
          clearInterval(updateProgressInterval);
        }
      }
    }
    catch (e) {
      console.log("error", e);
    }
    setLoading(false);
    setGeneratingProgress(0);
    updateTextOfButtonStartGenerating(START_GENERATING_TEXT);


  };


  const renerButtonStartGenerating = () => {
    return (
      <div className='col-12' style={{
        marginLeft: 10,
        padding: 10
      }}>
        <div
          className='button-start-generating'
          onClick={loading ? null : handleGenerate}
          style={{
            color: loading ? 'white' : 'white'
          }}
        >
          {loading && renderProgressBackground()}
          <div style={{
            zIndex: 1000
          }}>
            {displayTextOfButtonStartGenerating}
          </div>

        </div>
      </div>

    )
  }

  const renderPlaceholderImage = () => {
    return (
      <div style={{
        width: 600,
        height: 400,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 30,
        color: 'black'
      }}>
        生成图片区域
      </div>
    )
  }
  const downloadImage = async (image) => {
    try {
      setLoading(true);
      const selectedImage = image.replace('.jpg', '.png');
      const fileName = selectedImage.split('/').pop();
      console.log("selectedImage", selectedImage);
      //create a new anchor tag
      // const a = document.createElement('a');
      //set the href attribute to the path of the file
      const downloadurl = getImagePathToDownload(selectedImage);

      
      //set the download attribute to the file name
      // a.download = fileName;
      //trigger the download
      // document.body.appendChild(a);
      // a.click();
      // console.log("a", a);

      // Construct the URL with the image path
      const response = await fetch(downloadurl, {
        method: 'GET',
        credentials: 'include', // Include credentials if your Flask app requires authentication
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a new anchor tag and set its href to the blob URL
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Use the file name from the path
      document.body.appendChild(a);
      a.click();

      // Clean up and remove the anchor tag
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (ex) {
      console.log(ex);
    }
    finally {
      setLoading(false);
    }

  }

  const renderButtonDownloadImage = (image) => {
    return (
      <div className='button-start-generating' style={{
        marginTop: 10,
        marginBottom: 20,
        color: 'white',
        backgroundColor: APP_ACTIVE_COLOR,
        padding: 10,
        width: 600,
        textAlign: 'center',
      }}
        onClick={() => downloadImage(image)}
      >
        下载图片
      </div>
    )
  }

  const renderResultImages = () => {
    if (!image_res_list) {
      return renderPlaceholderImage();
    }
    return (
      <div>
        {
          image_res_list.map((image) => {
            return (
              <div style={{
                width: 600,
                marginTop: 30,
              }}>
                <img src={getImagePath(image)} alt='' style={{
                  width: '100%',
                }} />
                {renderButtonDownloadImage(image)}
              </div>
            )
          })
        }
      </div>
    )
  }


  const renderSDModelSelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='模型选择' options={
          folder_to_get_models.value === 'sdxl' ? sdxlModels : sd15Models
        } value={selectedModel} onChange={setSelectedModel} />
      </div>

    )
  }

  const renderScriptSelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='功能选择' options={
          RENDERING_SCRIPTS
        } value={selectedRenderingScript} onChange={setSelectedRenderingScript} />
      </div>

    )
  }

  const renderVAESelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='选择VAE' options={[selectedVaeModel]} value={selectedVaeModel} onChange={setSelectedVaeModel} />
      </div>

    )
  }

  const renderRadioButtonsToSelectWithTitle = (title, options, value, onChange) => {
    return (
      <div className='col-12'>
        <div style={{
          marginLeft: 20,
          fontSize: 14,
          marginTop: 10
        }}>
          {title}
        </div>

        <div className='row' style={{
          margin: 10,
          fontSize: 14,
        }}>
          {
            options.map((option, index) => {
              return (
                <div className='col-3'>
                  <input type="radio" id={option.value} value={option.value} checked={value.value == option.value} onChange={() => onChange(option)} />
                  <label htmlFor={option.value}>{option.label}</label>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const changeFolderToGetModels = (folder) => {
    setFolderToGetModels(folder);
    //set selected model to the first model in the list
    if (folder.value === 'sdxl') {
      setSelectedModel(sdxlModels[0]);
      setSelectedVaeModel(VAE_SDXL);
    }
    else {
      setSelectedModel(sd15Models[0]);
      setSelectedVaeModel(VEA_SD15);
    }
  }

  const renderSelectionToChooseBetweenSDXLAndSD15 = () => {
    return renderRadioButtonsToSelectWithTitle('SD版本选择', FODLER_TO_GET_MODELS, folder_to_get_models, changeFolderToGetModels);
  }

  const toggleSelectLora = () => {
    setIsSelectedLora(!isSelectedLora);
  }

  const renderLoraCheckbox = () => {
    const disabled = false;
    const title = "是否使用LoRA";
    return (
      <div className='col-12'>
        <div className='title-with-checkbox'>
          <div className="title" style={{
            color: disabled ? 'inherit' : '#C8C8C8'
          }}>
            {title}
          </div>
          <div className='custom-checkbox' onClick={toggleSelectLora}>
            <img src={isSelectedLora ? checkedIcon : uncheckedIcon} />
          </div>
        </div>

      </div>
    )
  }

  const toggleSelectLoraModel = (model) => {
    if (isSelectedSDXL()) {
      const newModels = sdxlLoraModels.map((m) => {
        if (m.label === model.label) {
          return {
            ...m,
            isSelected: !m.isSelected
          }
        }
        return m;
      });
      setSdxlLoraModels(newModels);
    } else {
      const newModels = sd15loraModels.map((m) => {
        if (m.label === model.label) {
          return {
            ...m,
            isSelected: !m.isSelected
          }
        }
        return m;
      });
      setSd15LoraModels(newModels);
    }
  }

  const isSelectedLoraModel = (model) => {
    return model.isSelected;
  }

  const getLoraModelWeight = (model) => {
    return model.weight;
  }

  const isSelectedSDXL = () => {
    return folder_to_get_models.value === 'sdxl';
  }

  const updateLoraModelWeight = (model, newWeight) => {
    if (isSelectedSDXL()) {
      const newModels = sdxlLoraModels.map((m) => {
        if (m.label === model.label) {
          return {
            ...m,
            weight: newWeight
          }
        }
        return m;
      });
      setSdxlLoraModels(newModels);
    }
    else {
      const newModels = sd15loraModels.map((m) => {
        if (m.label === model.label) {
          return {
            ...m,
            weight: newWeight
          }
        }
        return m;
      });
      setSd15LoraModels(newModels);
    }
  }


  const renderLoraModelsSelectionMultiple = () => {
    const models = folder_to_get_models.value === 'sdxl' ? sdxlLoraModels : sd15loraModels;
    return (
      <div style={{
        marginBottom: 20
      }}>
        {
          models.map(model => {
            const isSelectedModel = isSelectedLoraModel(model) && isSelectedLora;
            const modelWeight = getLoraModelWeight(model);
            return (
              <div className={"lora-model-item row " + (isSelectedModel ? "selected-lora-model" : "")} >
                <div className='lora-model-name col-6' onClick={() => isSelectedLora ? toggleSelectLoraModel(model) : null} >
                  {model.label}
                </div>
                <div className='col-6'>
                  <RangeSliderWithCustomDisplay
                    value={modelWeight}
                    setValue={(newWeight) => { updateLoraModelWeight(model, newWeight) }}
                    max={100}
                    min={0}
                    maxDisplay={2}
                    minDisplay={0}
                    step={1}
                    disabled={!isSelectedModel}
                  />
                </div>

              </div>
            )
          })
        }
      </div>
    )
  }

  const renderCfgInput = () => {
    return (
      <InlineInputWithTitle
        title={"CFG"}
        value={cfgScale}
        onChange={(number) => {}}
        min={0}
        max={100}
      />
    )
  }

  const renderClipSkipInput = () => {
    return (
      <InlineInputWithTitle
        title={"Clip Skip"}
        value={clipSkip}
        onChange={(number) => {}}
        min={0}
        max={100}
      />
    )
  }


  const renderStepsInput = () => {
    return (
      <InlineInputWithTitle
        title={"采样步数"}
        value={steps}
        onChange={(number) => {}}
        min={0}
        max={200}
        numberType='integer'
      />
    )
  }

  const handleChangeSamplerName = (sampler) => {
    setSelectedSamplerName(sampler);
    if (sampler.value === 'dpmpp_2m') {
      setSelectedSchedulerName(SCHEDULER_DPMPP);
    }
    else {
      setSelectedSchedulerName(SCHEDULER_EULER);
    }
  }

  const renderSamplerNameSelect = () => {
    return (
      <CustomSelect
        title='采样算法'
        options={samplerNames}
        value={selectedSamplerName}
        onChange={handleChangeSamplerName}
      />
    )
  }

  const renderSchedulerNameSelect = () => {
    return (
      <CustomSelect
        title='调度器'
        options={[selectedSchedulerName]}
        value={selectedSchedulerName}
        onChange={setSelectedSchedulerName}
      />
    )
  }


  const renderAdataperWeight = () => {
    return (
      <div className='col-12'>
        <div style={{
          marginLeft: 20,
          fontSize: 14,
          marginTop: 10
        }}>
          Apdater Weight
        </div>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={adataperWeight}
            setValue={setAdataperWeight}
            max={100}
            min={0}
            maxDisplay={1}
            minDisplay={0}
            step={1}
            disabled={false}
          />
        </div>

      </div>
    )
  }

  const isSelectRenderdingAdapter = () => {
    return selectedRenderingScript.value === 'rendering_adapter.py';
  }

  const renderModalToChangeLoraModels = () => {
    return (
      <Modal
        show={showLoraModelModal}
        onHide={() => setShowLoraModelModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>选择LoRA模型</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderLoraModelsSelectionMultiple()}
        </Modal.Body>
      </Modal>
    )
  }

  const renderLoraModelsTitleAndButtonToOpenModal = () => {
    return (
      <div className='col-12 lora-button-wrapper'>
        <div className='button-start-generating' style={{
          marginBottom: 20,
          marginLeft: 20,
        }}
          disabled={isSelectedLoraModel}
          onClick={() => isSelectedLora ? setShowLoraModelModal(true) : null}

        >LoRA选择
        </div>
      </div>
    )
  }

  const renderLightingWeight = () => {
    return (
      <div className='col-12'>
        <div style={{
          marginLeft: 20,
          fontSize: 14,
          marginTop: 10
        }}>
          Lighting Weight
        </div>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={lightingWeight}
            setValue={setLightingWeight}
            max={100}
            min={0}
            maxDisplay={1}
            minDisplay={0}
            step={1}
            disabled={false}
          />
        </div>

      </div>
    )
  }

  const isNotRenderingT2I = () => {
    return (!isRenderingTab) || (isRenderingTab && selectedRenderingScript.value !== 'rendering_t2i.py');
  }
  

  return (
    <div style={{
    }}
    className='row col-12'
    >
      <div className='row col-12'>
        <div className='col-4'>
          {renderButtons()}
          {renderStreamingVideoSmallView()}
          {isRenderingTab && renderScriptSelection()}
          {isRenderingTab && isSelectRenderdingAdapter() && renderAdataperWeight()}
          {renderPromptDialog()}
          {renderNegativePromptDialog()}
          {/* {!isRenderingTab && renderLightingWeight()} */}
          {renderSelectionToChooseBetweenSDXLAndSD15()}
          {renderSDModelSelection()}
          {renderVAESelection()}
          {renderLoraCheckbox()}
          {renderLoraModelsTitleAndButtonToOpenModal()}
          {renderClipSkipInput()}
          {renderCfgInput()}
          {renderStepsInput()}
          {renderSamplerNameSelect()}
          {renderSchedulerNameSelect()}
          {renerButtonStartGenerating()}


        </div>
        <div className='offset-2 col-6'>
          {renderCropdedImageCanvas()}
          {renderResultImages()}

        </div>

      </div>

      {renderPopupToSetArea()}
      {renderModalToChangeLoraModels()}

    </div>
  );
};

export default ScreenCapture;
