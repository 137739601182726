/* global fabric */
import { useEffect, useState, useRef } from 'react';
import checkedIcon from '../images/checked.svg'
import uncheckedIcon from '../images/unchecked.svg';
import default_car from '../images/cocker.png';

import './style.css'
import { AI_CAR, AI_CREATIVITY, AI_RENDERING, APP_ACTIVE_COLOR, CMF, EDITING, OTHER_CATEGORY, PRODUCT_CATEGORIES } from '../constant';
import CustomSelect from './CustomSelect';
import PromptDialog from './PromptDialog';
import ButtonListSelections from './ButtonListSelections';
import NumberAdjustment from './NumberAdjustment/NumberAdjustment';
import CustomSelectWithoutTitle from './CustomSelect/CustomSelectWithoutTitle';


import BeatLoaderSpinner from './LoadingPage/BeatLoaderSpinner';
import { RangeSliderWithCustomDisplay } from './RangeSliderWithCustomDisplay/RangeSliderWithCustomDisplay';
import ICON_NEXT_IMAGE_BIG from '../images/image_slider_right_big.svg';
import ICON_PREV_IMAGE_BIG from '../images/image_slider_left_big.svg';
import Slider from './ImagesSlider/Customslider';

import downloadImageDropdownIcon from '../images/download_image_dropdown.svg';

import closeButtonIcon from '../images/close_button.svg';
import DrawingCanvas from '../pages/DrawingCanvasCustom';
import { convertArrayToArrayWithLabelAndValue, convertToArrayFromDict, getCurrentDomain, getImagePath, getImagePathToDownload, removePathFromImage } from '../utils';
import CustomInput from './CustomInput/Input';
import { CAR_AI_CAR_DEFAULT_PROMPT_TEXT, CAR_AI_CAR_MODELS, CAR_AI_IMAGE_RESOLUTIONS, CAR_AI_SAMPLER_NAMES, CAR_AI_SCENE_OF_GENERATED_IMAGE, CAR_AI_SCHEDULER_NAMES, CAR_AI_SD_MODELS, CAR_AI_STYLES_OF_GENERATED_IMAGE, CAR_AI_VIEW_ANGLES_OF_THE_VEHICLE, CAR_AI_WEATHER_OF_GENERATED_IMAGE } from './GenerateCar';
import InlineInputWithTitle from './InlineInputWithTitle/InlineInputWithTitle';
import PANTONE_SIMULATED_COLORS from './pantone.json';
import RAL_SIMULATED_COLORS from './ral.json';
import searchIcon from '../images/search_icon.svg';
import ImageEditingTool from '../pages/ImageEditingTool';
import exclamationInforIcon from '../images/exclamation_InforIcon.svg';
import wooden from '../images/wooden_dummy.png';
import reddummy from '../images/red_dummy.png';
import { MOCK_IMAGES } from './mock_images';

const styles_of_generated_image_dict = {

  "摄影写实": "Photography Realism",
  "极简主义": "Minimalism",
  "日系家居": "Japanese-style Home",
  "电商风格": "E-commerce Style",
  "未来科技": "Future Technology",
  "轻奢时尚": "Casual Luxury Fashion",
  "活力可爱": "Vibrant and Cute",
  "国风国潮": "Chinese-style Trend",
  "怀旧复古": "Nostalgic Retro",
}



const draft_sketch_dict = {
  "产品手绘/草图/线稿图": "Hand-drawing/Draft sketch",
  "产品CAD模型图/白模图/素模图": "CAD model image/blank model",
  // "支持的图片格式": "image",
}
const AI_COLORING_PANTON = "Pantone";
const AI_COLORING_RAL = "Ral";
const AI_COLORING_CUSTOMIZED = "customized";
const AI_COLORING_UPLOAD_IMAGE = "upload image";
const ai_coloring_dict = {
  "潘通色彩体系": AI_COLORING_PANTON,
  "劳尔色彩体系": AI_COLORING_RAL,
  "自定义色值": AI_COLORING_CUSTOMIZED,
  "上传颜色图片": AI_COLORING_UPLOAD_IMAGE,
}

const camera_dict = {
  "特写": "close-up shot",
  "远景": "scenery",
  "中景": "mid-shot",
  "近景": "nearby shot",
}

const sceneraio_dict = {
  "室内场景": "Interior scenes",
  "室外场景": "Outdoor scenes",
  "厨房场景": "Kitchen scenes",
  "客厅场景": "Living room scenes",
  "草坪": "Lawn",
  "海滩": "Beach",
  "简约场景": "Minimalist scenes",
  "纯色背景": "Solid color background",
  "西式中岛台": "Western-style Nakajima",
  "单桌子上": " Single table",
  "嵌入墙体": "Embedded in the wall",

}

const angle_dict = {
  "正面": "Front",
  "俯视": "Overhead view",
  "仰视": "Upward view",
  "45度右侧": "45 Degrees to right",
  "45度左侧": "45 Degrees to left",
}
const sd_model_dict = {
  "LoRA-现代简约设计.safetensors": "LoRA-现代简约设计.safetensors",
}

//Added Reference Content
const CUSTOM_DRAW_MASK = "select/draw mask";
const reference_content_image_dict = {
  整体参考: "linear",
  色彩风格参考: "style transfer",
  页面构图参考: "composition",
  自定义内容: CUSTOM_DRAW_MASK
};

const START_GENERATING_TEXT = '生成图片';
const REMAINING_IMAGES_TEXT = '剩余图片: ';


const DEFAULT_PROMPT_TEXT = "请输入产品描述及场景要求等提示词，提示词之间用”,”隔开";


const CUSTOMIZED_COLOR_MODE_CMYK = "CMYK";
const CUSTOMIZED_COLOR_MODE_HEX = "Hex";
const CUSTOMIZED_COLOR_MODE_RGB = "RGB";

const CUSTIMIZED_COLOR_OPTIONS = [
  {
    value: CUSTOMIZED_COLOR_MODE_CMYK,
    label: CUSTOMIZED_COLOR_MODE_CMYK
  },
  {
    value: CUSTOMIZED_COLOR_MODE_HEX,
    label: CUSTOMIZED_COLOR_MODE_HEX
  },
  {
    value: CUSTOMIZED_COLOR_MODE_RGB,
    label: CUSTOMIZED_COLOR_MODE_RGB
  },
]

const VIEW_ANGLES_OF_THE_VEHICLE = convertToArrayFromDict(angle_dict);
const SCENARIO_OF_GENERATED_IMAGE = convertToArrayFromDict(sceneraio_dict);
const CAMERA_OF_GENERATED_IMAGE = convertToArrayFromDict(camera_dict);
const STYLES_OF_GENERATED_IMAGE = convertToArrayFromDict(styles_of_generated_image_dict);
const AI_COLORING_SELECT_OPTIONS = convertToArrayFromDict(ai_coloring_dict);
export const SD_MODELS = convertToArrayFromDict(sd_model_dict);
const REFERENCE_CONTENT_OF_IMAGE = convertToArrayFromDict(
  reference_content_image_dict
);
const ONE_MIN_IN_MILLISECONDS = 60000;
const THUBMMAIL_PREFIX = 'thumbnail_';
const DRAFT_SKETCH_TYPE_SELECTIONS = convertToArrayFromDict(draft_sketch_dict);
const MAX_IMAGE_SIZE = 1024;
const GenerateTab = ({ tab, changeTab }) => {
  const [prompt, setPrompt] = useState('');
  const [selectedProductCategory, setSelectedProductCategory] = useState(PRODUCT_CATEGORIES[0]);
  const [selectedStyleOfGeneratedImage, setSelectedStyleOfGeneratedImage] = useState(STYLES_OF_GENERATED_IMAGE[0]);
  const [selectedViewAngleOfTheVehicle, setSelectedViewAngleOfTheVehicle] = useState(VIEW_ANGLES_OF_THE_VEHICLE[0]);
  const [selectedScenarioOfGeneratedImage, setSelectedScenarioOfGeneratedImage] = useState(SCENARIO_OF_GENERATED_IMAGE[0]);
  const [selectedCameraOfGeneratedImage, setSelectedCameraOfGeneratedImage] = useState(CAMERA_OF_GENERATED_IMAGE[0]);
  const [selectedSDModel, setSelectedSDModel] = useState(SD_MODELS[0]);
  const [imageWidth, setImageWidth] = useState(MAX_IMAGE_SIZE);
  const [imageHeight, setImageHeight] = useState(MAX_IMAGE_SIZE);

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(default_car);
  const [selectedImageFullPage, setSelectedImageFullPage] = useState(null);


  const [numberOfImagesToGenerate, setNumberOfImagesToGenerate] = useState(1);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatingProgress, setGeneratingProgress] = useState(0);
  const [displayTextOfButtonStartGenerating, setDisplayTextOfButtonStartGenerating] = useState(START_GENERATING_TEXT);
  const [isSelectedUploadImageMaskRef, setIsSelectedUploadImgRef] = useState(false);
  const [promptWeight, setPromptWeight] = useState(50);
  const [imgRefWeight, setImgRefWeight] = useState(50);
  const [sd_models, setSDModels] = useState(SD_MODELS);

  const [isShowErrorWhenClickOnButtonUploadImage, setIsShowErrorWhenClickOnButtonUploadImage] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedImageOrCadRef, setSelectedImageOrCadRef] = useState(null);
  const [draftSketchType, setDraftSketchType] = useState(DRAFT_SKETCH_TYPE_SELECTIONS[0]);
  const [selectedButtonToEditImage, setSelectedButtonToEditImage] = useState(null);
  const [isRemovingBackground, setIsRemovingBackground] = useState(false);
  const editImageRef = useRef(null);
  const [selectedFileToEdit, setSelectedFileToEdit] = useState(null);
  const [frontProductCategoryInPrompt, setFrontProductCategoryInPrompt] = useState('');
  const [afterProductCategoryInPrompt, setAfterProductCategoryInPrompt] = useState('');
  const [frontStyleOfGeneratedImageInPrompt, setFrontStyleOfGeneratedImageInPrompt] = useState('');
  const [afterStyleOfGeneratedImageInPrompt, setAfterStyleOfGeneratedImageInPrompt] = useState('');
  const [frontScenarioOfGeneratedImageInPrompt, setFrontScenarioOfGeneratedImageInPrompt] = useState('');
  const [afterScenarioOfGeneratedImageInPrompt, setAfterScenarioOfGeneratedImageInPrompt] = useState('');
  const [frontViewAngleOfTheVehicleInPrompt, setFrontViewAngleOfTheVehicleInPrompt] = useState('');
  const [afterViewAngleOfTheVehicleInPrompt, setAfterViewAngleOfTheVehicleInPrompt] = useState('');
  const [frontCameraOfGeneratedImageInPrompt, setFrontCameraOfGeneratedImageInPrompt] = useState('');
  const [afterCameraOfGeneratedImageInPrompt, setAfterCameraOfGeneratedImageInPrompt] = useState('');
  const promptDialogRef = useRef(null);
  const [displayDownloadImageDropdown, setDisplayDownloadImageDropdown] = useState(false);
  const [shouldShowLoadingBackground, setShouldShowLoadingBackground] = useState(false);
  const [freeTextAfterLastComma, setFreeTextAfterLastComma] = useState('');
  const [allImagesOfUser, setAllImagesOfUser] = useState([]);
  const [maskInDb, setMaskInDb] = useState('');
  const [otherProductCategory, setOtherProductCategory] = useState('');

  const [CAR_AI_prompt, CAR_AI_setPrompt] = useState(CAR_AI_CAR_DEFAULT_PROMPT_TEXT);
  const [CAR_AI_cfgScale, CAR_AI_setCfgScale] = useState(2.0);
  const [CAR_AI_steps, CAR_AI_setSteps] = useState(7);
  const [CAR_AI_selectedMode, CAR_AI_setSelectedMode] = useState('txt2img');

  const [CAR_AI_selectedCarModel, CAR_AI_setSelectedCarModel] = useState(null);
  const [CAR_AI_carModels, CAR_AI_setCarModels] = useState([]);
  const [CAR_AI_selectedStyleOfGeneratedImage, CAR_AI_setSelectedStyleOfGeneratedImage] = useState(CAR_AI_STYLES_OF_GENERATED_IMAGE[0]);
  const [CAR_AI_selectedViewAngleOfTheVehicle, CAR_AI_setSelectedViewAngleOfTheVehicle] = useState(CAR_AI_VIEW_ANGLES_OF_THE_VEHICLE[0]);
  const [CAR_AI_selectedSceneOfGeneratedImage, CAR_AI_setSelectedSceneOfGeneratedImage] = useState(CAR_AI_SCENE_OF_GENERATED_IMAGE[0]);
  const [CAR_AI_selectedWeatherOfGeneratedImage, CAR_AI_setSelectedWeatherOfGeneratedImage] = useState(CAR_AI_WEATHER_OF_GENERATED_IMAGE[0]);
  const [CAR_AI_selectedSDModel, CAR_AI_setSelectedSDModel] = useState(CAR_AI_SD_MODELS[0]);

  const [CAR_AI_isSelectedPreciselyControl, CAR_AI_setISelectedPreciselyControl] = useState(true);
  const [CAR_AI_proportionOfTheBodyToTheOverallScreen, CAR_AI_setProportionOfTheBodyToTheOverallScreen] = useState(50);
  const [CAR_AI_samplerNames, CAR_AI_setSamplerNames] = useState(CAR_AI_SAMPLER_NAMES);
  const [CAR_AI_schedulerNames, CAR_AI_setSchedulerNames] = useState(CAR_AI_SCHEDULER_NAMES);
  const [CAR_AI_selectedSamplerName, CAR_AI_setSelectedSamplerName] = useState({
    label: "dpmpp_sde",
    value: "dpmpp_sde"
  });
  const [CAR_AI_selectedSchedulerName, CAR_AI_setSelectedSchedulerName] = useState({
    label: "karras",
    value: "karras"
  });

  const [isUsingAIColoring, setIsUsingAIColoring] = useState(false);
  const [isUsingAIMaterial, setIsUsingAIMaterial] = useState(false);
  const fileInputToUploadMaterialImageRef = useRef(null);
  const fileInputToUploadCMFColorImageRef = useRef(null);
  const fileInputToUploadCMFImageRef = useRef(null);
  const [selectedMaterialRefImage, setSelectedRefMaterialImage] = useState(null);
  const [selectedCMFColorImageFile, setSelectedCMFColorImageFile] = useState(null);
  const [cmfImageInput, setCmfImageInput] = useState(null);
  const [selectedAIColoringOption, setSelectedAIColoringOption] = useState(AI_COLORING_SELECT_OPTIONS[0]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [colorSearchText, setColorSearchText] = useState('');
  const [customizedColorMode, setCustomizedColorMode] = useState(CUSTIMIZED_COLOR_OPTIONS[0]);
  const [customizedColorValue, setCustomizedColorValue] = useState({
    hex: '#'
  });

  const [editingImage, setEditingImage] = useState(null);
  const [selectedStyleOfReferenceContentImage,setSelectedStyleOfReferenceContentImage]=useState(REFERENCE_CONTENT_OF_IMAGE[0]);
  const fileInputToUploadMaskImageRef = useRef(null);
  const [selectedImageMaskRef, setSelectedImageMaskRef] = useState(null);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const editImageMaskToolRef = useRef(null);
  const [edittingMaskInBase64, setEdittingMaskInBase64] = useState(null);
  const [edittingMaskInBase64OfCMFInput, setEdittingMaskInBase64OfCMFInput] = useState(null);
  const [cmfPromptText, setCmfPromptText] = useState('');
  const [cmfColorWeight, setCmfColorWeight] = useState(50);
  const [cmfMaterialWeight, setCmfMaterialWeight] = useState(50);
  const [editingImageMasksAutoSam, setEditingImageMasksAutoSam] = useState(null);
  const [editingImageAutoSamImageResult, setEditingImageAutoSamImageResult] = useState(null);
  const [shouldDisplayMaskToSelect, setShouldDisplayMaskToSelect] = useState(false);
  //ClickableImage
  //const [sourceValue, setSourceValue] = useState(null);

  useEffect(() => {
    setLoading(true);
    setFrontProductCategoryInPrompt('');
    setAfterProductCategoryInPrompt('');
    setFrontStyleOfGeneratedImageInPrompt('');
    setAfterStyleOfGeneratedImageInPrompt('');
    setFrontScenarioOfGeneratedImageInPrompt(''); 
    setAfterScenarioOfGeneratedImageInPrompt(''); 
    setFrontViewAngleOfTheVehicleInPrompt('');
    setAfterViewAngleOfTheVehicleInPrompt('');
    setFrontCameraOfGeneratedImageInPrompt('');
    setAfterCameraOfGeneratedImageInPrompt('');
    setOtherProductCategory('');


    fetch(getCurrentDomain() + `get_all_images/${tab}`)
      .then(res => res.json())
      .then(data => {
        const allImagesToDisplay = data.all_images_in_db.filter((image) => image.generateType === tab);
        //sort all images to display by createdDate
        allImagesToDisplay.sort((a, b) => {
          return new Date(b.createdTime) - new Date(a.createdTime);
        });
        const imagesPath = allImagesToDisplay.map(item => item.imagePath).map(getImagePath);
        if (imagesPath.length > 0) {
          setSelectedImage(imagesPath[0]);
        }
        setGeneratedImages(imagesPath);
        setAllImagesOfUser(data.all_images_in_db);

      }).catch
      (e => {
        console.log("error", e);
        


        // setGeneratedImages([default_car,
        //   default_car, default_car, default_car, default_car
        // ])
        const allImagesToDisplay = MOCK_IMAGES.filter((image) => image.generateType === tab);
        //sort all images to display by createdDate
        allImagesToDisplay.sort((a, b) => {
          return new Date(b.createdTime) - new Date(a.createdTime);
        });
        const imagesPath = allImagesToDisplay.map(item => item.imagePath).map(getImagePath);
        if (imagesPath.length > 0) {
          setSelectedImage(imagesPath[0]);
        }
        setGeneratedImages(imagesPath);
        setAllImagesOfUser(MOCK_IMAGES);
      })
      .finally(() => {
        setLoading(false);
      });

    fetch(getCurrentDomain() + `get_all_models/${tab}`)
      .then(res => res.json())
      .then(data => {
        console.log("data", data);
        setSDModels(data.all_models.map((model) => {
          return {
            label: model,
            value: model,
          }
        }));
        setSelectedSDModel({
          label: data.all_models[0],
          value: data.all_models[0],
        });
      })
      .catch(e => {
        console.log("error", e);
      });


    fetch(getCurrentDomain() + `car_models`)
      .then(res => res.json())
      .then(data => {
        console.log("data", data);
        CAR_AI_setCarModels(data.carModels.map((model) => {
          return {
            label: model,
            value: model,
          }
        }));
        CAR_AI_setSelectedCarModel({
          label: data.carModels[0],
          value: data.carModels[0],
        });
      })
      .catch(e => {
        console.log("error", e);
      });
  }, [tab]);

     //Added ReferceContent Part
    const renderReferenceContent = () => {
      return(
      <>
        <div className="col-12">
          <ButtonListSelections
            title="参考内容"
            selections={REFERENCE_CONTENT_OF_IMAGE}
            onClick={(selection) => {
              setSelectedStyleOfReferenceContentImage(selection)
              if (selection.value === CUSTOM_DRAW_MASK) {
                setImageToEditOnTool(URL.createObjectURL(selectedImageMaskRef))
                setIsEditingImage(true);
                

              }
              }}
            selected={selectedStyleOfReferenceContentImage}
          />
        </div>
      </>
      ); 
    };


  const startNewIntervalToUploadProgress = () => {
    let progress = 0;
    const timeInSeconds = isSelectedUploadImageMaskRef ? 1 : 0.3;
    const ESTIMATE_TIME_TO_FINISH_IN_MILLISECOND = 1000 * 60 * timeInSeconds;
    const interval = setInterval(() => {
      progress += 1;
      if (progress > generatingProgress) {
        setGeneratingProgress(progress);
      }
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, ESTIMATE_TIME_TO_FINISH_IN_MILLISECOND / 100);
    return interval;
  }

  const updateTextOfButtonStartGenerating = (text) => {
    setDisplayTextOfButtonStartGenerating(text);
  }



  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);

    });
  }

  const getRawImage = async () => {
    if (isFromLocal(selectedImage) && selectedFileToEdit) {
      const newImagePath = await uploadImageToServer(selectedFileToEdit);
      return newImagePath;
    } else {
      return removePathFromImage(selectedImage);
    }
  }

  const getImageUrlOrShouldUpload = async (image) => {
    if (isImageFromServer(image)) {
      return removePathFromImage(image);
    }
    else {
      const newImagePath = await uploadImageToServer(image);
      return newImagePath;
    }
  }

  const isImageFromServer = (image) => {
    try {
      return image.includes(getCurrentDomain());
    }
    catch (e) {
      return false;
    }
  }

  const uploadImageToServer = async (selectedImage) => {
    const path = getCurrentDomain() + '/upload-image';
    const formData = new FormData();
    formData.append('file', selectedImage);
    const result = await fetch(path, {
      method: 'POST',
      body: formData,
    });
    const data = await result.json();
    return data.newImage;
  }

  const handleGenerate = async () => {
    console.log(tab, AI_RENDERING)
    let urlOfImageRef = ""
    let urlOfMaskImageRefOnServer = "";
    let urlOfCMFInputImageRefOnServer = "";
    let urlOfCMFMaterialImageRefOnServer = "";
    let cmfUserInputColor = await getUserInputColor();
    console.log({cmfUserInputColor})
    if (tab == AI_RENDERING || tab  == AI_CREATIVITY) {
      if (isSelectedUploadImageMaskRef){
        if (selectedImageMaskRef) {
          setLoading(true);
          urlOfMaskImageRefOnServer = await uploadImageToServer(selectedImageMaskRef);
        }
        else {
          alert("Please upload image reference");
          return;
        }
      }
      
    }

    if (tab == AI_RENDERING) {
      console.log(selectedImageOrCadRef)
      if (selectedImageOrCadRef) {
        setLoading(true);
        urlOfImageRef = await uploadImageToServer(selectedImageOrCadRef);
      }
      else {
        alert("Please upload image reference");
        return;
      }
    }

    if (tab == CMF) {

      if (!isUsingAIColoring && !isUsingAIMaterial) {
        alert("Please select AI Coloring or AI Material");
        return;
      }

      if (isUsingAIColoring && !cmfUserInputColor) {
        alert("Please select or input color");
        return;
      }

      
      if (cmfImageInput) {
        setLoading(true);
        urlOfCMFInputImageRefOnServer = await uploadImageToServer(cmfImageInput);
      }
      else {
        alert("Please upload image reference");
        return;
      }

      if (isUsingAIMaterial) {
        if (selectedMaterialRefImage) {
          setLoading(true);
          urlOfCMFMaterialImageRefOnServer = await getImageUrlOrShouldUpload(selectedMaterialRefImage);
        }
        else {
          alert("Please upload image reference");
          return;
        }
      }
    }

    let markImage = "";
    if (isEditingTab()) {
      markImage = await getDrawnCanvas();
    }
    setLoading(true);
    setGeneratingProgress(0);
    let allImages = [...generatedImages];
    const body = JSON.stringify({
      "scenario": selectedScenarioOfGeneratedImage.value ? selectedScenarioOfGeneratedImage.value : "",
      "camera": selectedCameraOfGeneratedImage.value ? selectedCameraOfGeneratedImage.value : "",
      "angle": selectedViewAngleOfTheVehicle.value ? selectedViewAngleOfTheVehicle.value : "",
      "style": selectedStyleOfGeneratedImage.value ? selectedStyleOfGeneratedImage.value : "",
      "image_width": parseInt(imageWidth),
      "image_height": parseInt(imageHeight),
      "number_of_images": numberOfImagesToGenerate,
      "sd_model": selectedSDModel.value,
      "prompt": getPrompt(),
      "promptWeight": promptWeight,
      "isSelectedUploadImgRef": isSelectedUploadImageMaskRef ? 1 : 0,
      "product": selectedProductCategory.label,
      "imgRefWeight": imgRefWeight,
      "selected_image_ref": urlOfImageRef,
      "sketch_type": draftSketchType.value,
      "marked_image": markImage,
      "raw_image": isEditingTab() ? await getRawImage() : "",
      "isSelectedUploadImageMaskRef": isSelectedUploadImageMaskRef ? 1 : 0,
      "selected_image_mask_ref": urlOfMaskImageRefOnServer,
      "mask_of_selected_image_mask_ref": edittingMaskInBase64,
      "selectedStyleOfReferenceContentImage": selectedStyleOfReferenceContentImage ? selectedStyleOfReferenceContentImage.value : "",
      "maskImgRefWeight": imgRefWeight,
      "cmf_selected_color": cmfUserInputColor,
      "cmf_selected_material": urlOfCMFMaterialImageRefOnServer,
      "cmf_image_input": urlOfCMFInputImageRefOnServer,
      "cmf_image_mask": edittingMaskInBase64OfCMFInput,
      "cmf_isUsingAIColoring": isUsingAIColoring ? 1 : 0,
      "cmf_isUsingAIMaterial": isUsingAIMaterial ? 1 : 0,
      "cmf_color_weight": cmfColorWeight,
      "cmf_material_weight": cmfMaterialWeight,
      "carAI": {
        "background": CAR_AI_selectedSceneOfGeneratedImage ? CAR_AI_selectedSceneOfGeneratedImage.value : "",
        "weather": CAR_AI_selectedWeatherOfGeneratedImage ? CAR_AI_selectedWeatherOfGeneratedImage.value : "",
        "angle": CAR_AI_selectedViewAngleOfTheVehicle ? CAR_AI_selectedViewAngleOfTheVehicle.value : "",
        "style": CAR_AI_selectedStyleOfGeneratedImage ? CAR_AI_selectedStyleOfGeneratedImage.value : "",
        "resolution": `${imageWidth}x${imageHeight}`,
        "cfg_scale": CAR_AI_cfgScale,
        "car_model": CAR_AI_selectedCarModel ? CAR_AI_selectedCarModel.value : "",
        "sd_model": CAR_AI_selectedSDModel ? CAR_AI_selectedSDModel.value : "",
        "prompt": CAR_AI_prompt,
        "proportionOfTheBodyToTheOverallScreen": CAR_AI_proportionOfTheBodyToTheOverallScreen,
        "isSelectedPreciselyControl": CAR_AI_isSelectedPreciselyControl,
        "sampler_name": CAR_AI_selectedSamplerName ? CAR_AI_selectedSamplerName.value : "",
        "scheduler": CAR_AI_selectedSchedulerName ? CAR_AI_selectedSchedulerName.value : "",
        "steps": CAR_AI_steps,
      }
    });
    try {
      const numberOfImagesToGenerateWhenClick = parseInt(numberOfImagesToGenerate);
      for (let i = 0; i < numberOfImagesToGenerateWhenClick; i++) {
        const updateProgressInterval = startNewIntervalToUploadProgress();
        try {
          const numberOfRemainingImages = numberOfImagesToGenerateWhenClick - i;
          updateTextOfButtonStartGenerating(`剩余图片: ${numberOfRemainingImages}`);
          const result = await fetch(getCurrentDomain() + 'generate' + '/' + tab, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: body,
            timeout: ONE_MIN_IN_MILLISECONDS * 30,
          });
          clearInterval(updateProgressInterval);
          setGeneratingProgress(100);
          const data = await result.json();
          const newImage = data.new_image;

          allImages = [getImagePath(newImage.imagePath), ...allImages]
          console.log("allImages", allImages);
          setGeneratedImages([...allImages]);
          setSelectedImage(allImages[0]);
          setAllImagesOfUser([newImage, ...allImagesOfUser]);
          setMaskInDb('');
        }
        catch (e) {
          console.log(e);
          setGeneratingProgress(0);
          clearInterval(updateProgressInterval);
        }
      }
    }
    catch (e) {
      console.log("error", e);
      if (allImages.length > 0) {
        setSelectedImage(allImages[0]);
      } else {
        setSelectedImage(default_car);

      }
    }
    setLoading(false);
    setGeneratingProgress(0);
    updateTextOfButtonStartGenerating(START_GENERATING_TEXT);
  };




  const renderSDModelSelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='模型选择' options={sd_models} value={selectedSDModel} onChange={setSelectedSDModel} />
      </div>

    )
  }

  const updateProductCategory = (productCategory) => {
    setSelectedProductCategory(productCategory);
  }


  const renderProductCategorySelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='产品品类' options={PRODUCT_CATEGORIES} value={selectedProductCategory} onChange={updateProductCategory} />
      </div>
    )
  }
  // Function to get the complete prompt
  const getPrompt = () => {
    if(selectedProductCategory.label === OTHER_CATEGORY)
    {
      return prompt;
    }
    if (isCMFTab()) {
      return cmfPromptText;
    }
    if (isEditingTab()) {
      return prompt;
    }
    else if (isRenderingTab()) {
      return getPromptForRendering() + freeTextAfterLastComma;
    }
    return getPromptForCreativity() + freeTextAfterLastComma;
  };

  const getFullDisplayTextOfEachPart = (part) => {
    return (part.before ? part.before : '') + part.text + (part.after ? part.after : '');
  }

  const getPromptDataForCreativity = () => {
    return [
      {
        part: 'category',
        before: frontProductCategoryInPrompt,
        text: isSelectedOtherProductCategory() ? otherProductCategory : selectedProductCategory.label,
        after: afterProductCategoryInPrompt,
        setBefore: setFrontProductCategoryInPrompt,
        setAfter: setAfterProductCategoryInPrompt,
      },
      {
        part: 'style',
        before: frontStyleOfGeneratedImageInPrompt,
        text: selectedStyleOfGeneratedImage.label,
        after: afterStyleOfGeneratedImageInPrompt,
        setBefore: setFrontStyleOfGeneratedImageInPrompt,
        setAfter: setAfterStyleOfGeneratedImageInPrompt,
      },
      {
        part: 'scenario',
        before: frontScenarioOfGeneratedImageInPrompt,
        text: selectedScenarioOfGeneratedImage.label,
        after: afterScenarioOfGeneratedImageInPrompt,
        setBefore: setFrontScenarioOfGeneratedImageInPrompt,
        setAfter: setAfterScenarioOfGeneratedImageInPrompt,
      },
      {
        part: 'angle',
        before: frontViewAngleOfTheVehicleInPrompt,
        text: selectedViewAngleOfTheVehicle.label,
        after: afterViewAngleOfTheVehicleInPrompt,
        setBefore: setFrontViewAngleOfTheVehicleInPrompt,
        setAfter: setAfterViewAngleOfTheVehicleInPrompt,
      },
      {
        part: 'camera',
        before: frontCameraOfGeneratedImageInPrompt,
        text: selectedCameraOfGeneratedImage.label,
        after: afterCameraOfGeneratedImageInPrompt,
        setBefore: setFrontCameraOfGeneratedImageInPrompt,
        setAfter: setAfterCameraOfGeneratedImageInPrompt,
      }
    ]
  }

  const getPromptDataForRendering = () => {
    return [
      {
        part: 'category',
        before: frontProductCategoryInPrompt,
        text: isSelectedOtherProductCategory() ? otherProductCategory : selectedProductCategory.label,
        after: afterProductCategoryInPrompt,
        setBefore: setFrontProductCategoryInPrompt,
        setAfter: setAfterProductCategoryInPrompt,
      },
      {
        part: 'style',
        before: frontStyleOfGeneratedImageInPrompt,
        text: selectedStyleOfGeneratedImage.label,
        after: afterStyleOfGeneratedImageInPrompt,
        setBefore: setFrontStyleOfGeneratedImageInPrompt,
        setAfter: setAfterStyleOfGeneratedImageInPrompt,
      },
      {
        part: 'scenario',
        before: frontScenarioOfGeneratedImageInPrompt,
        text: selectedScenarioOfGeneratedImage.label,
        after: afterScenarioOfGeneratedImageInPrompt,
        setBefore: setFrontScenarioOfGeneratedImageInPrompt,
        setAfter: setAfterScenarioOfGeneratedImageInPrompt,
      },
    ]
  }



  const getPromptForCreativity = () => {
    const promoptData = getPromptDataForCreativity();
    return promoptData.map((data) => getFullDisplayTextOfEachPart(data)).join(',');
  }

  const getPromptForRendering = () => {
    const promoptData = getPromptDataForRendering();
    return promoptData.map((data) => getFullDisplayTextOfEachPart(data)).join(',');
  }



  // Function to compare two strings and find the first difference
  const compare = (oldString, newString) => {
    console.log(oldString, newString)
    let index = -1;
    for (let i = 0; i < Math.max(oldString.length, newString.length); i++) {
      if (oldString[i] !== newString[i]) {
        index = i;
        break;
      }
    }
    return {
      index,
      isDelete: oldString.length > newString.length
    };
  };


  const getIndexOfLastAfterPart = (promptData) => {
    let index = 0
    for (let i = 0; i < promptData.length - 1; i++) {
      index += promptData[i].text.length;
      index += promptData[i].before ? promptData[i].before.length : 0;
      index += promptData[i].after ? promptData[i].after.length : 0;
      index += 1;
    }
    index += promptData[promptData.length - 1].text.length;
    index += promptData[promptData.length - 1].before ? promptData[promptData.length - 1].before.length : 0;
    return index;
  }

  const isAddedInvalidComma = (oldPrompt, newPrompt, event, indexOfLastAfterPart) => {
    if (newPrompt.length > oldPrompt.length) {
      const changedCharacters = newPrompt.slice(event.target.selectionStart - 1, event.target.selectionStart);
      console.log('changedCharacters', changedCharacters)
      if (changedCharacters === ',') {
        const selectionStart = event.target.selectionStart;
        if (selectionStart < indexOfLastAfterPart) {
          return true;
        }
      }
    }
    return false;
  }


  // Function called when the prompt changes
  const onChangePrompt = (oldPrompt, event) => {
    const value = event.target.value;
    if(selectedProductCategory.label === OTHER_CATEGORY)
      {
        setPrompt(value);
        return value;
      }
    if (isEditingTab()) {
      setPrompt(value);
      return value;
    }
    else {
      const promptData = isRenderingTab() ? getPromptDataForRendering() : getPromptDataForCreativity();
      const indexOfLastAfterPart = getIndexOfLastAfterPart(promptData);
      const isAddInvalidComma = isAddedInvalidComma(oldPrompt, value, event, indexOfLastAfterPart);
      if (isAddInvalidComma) {
        return oldPrompt;
      }
      const indexOfTheFirstChinseComma = value.indexOf('，');
      console.log('indexOfTheFirstChinseComma', indexOfTheFirstChinseComma)
      console.log('indexOfLastAfterPart', indexOfLastAfterPart)
      if (indexOfTheFirstChinseComma !== -1 && indexOfTheFirstChinseComma < indexOfLastAfterPart && oldPrompt.length < value.length) {
        console.log('return old prompt')
        return oldPrompt;
      }
      const newParts = value.split(',');
      console.log('newParts', newParts)
      console.log('promptData', promptData)
      if (promptData.length > newParts.length) {
        return oldPrompt;
      }
      const changedPartIndex = findChangedPartIndex(oldPrompt, newParts);
      console.log({
        oldPrompt,
        newParts,
        changedPartIndex
      })
      if (changedPartIndex === -1 != changedPartIndex >= promptData.length) {
        console.log('last part changed')
        //append new character to the last part

        console.log('indexOfAfterPart', indexOfLastAfterPart)
        const newAfterPartOfLastPart = value.slice(indexOfLastAfterPart);
        console.log('newAfterPartOfLastPart', newAfterPartOfLastPart)
        promptData[promptData.length - 1].after = newAfterPartOfLastPart;
        promptData[promptData.length - 1].setAfter(newAfterPartOfLastPart);
        return value;

      }
      const part = promptData[changedPartIndex];
      const newPartValue = newParts[changedPartIndex];
      const { index, isDelete } = compare(getFullDisplayTextOfEachPart(part), newPartValue);

      if (index === -1) {
        return oldPrompt
      }

      else if (index > part.before.length && index < part.before.length + part.text.length) {
        console.log('ignore')
        return oldPrompt;
      }
      else {
        //update based on the new partValue

        if (isDelete && newPartValue.length < part.text.length) {
          return oldPrompt;
        }
        const newPartDataBeforeAndAfter = newPartValue.split(part.text);
        part.before = newPartDataBeforeAndAfter[0];
        part.after = newPartDataBeforeAndAfter[1];
        part.setBefore(newPartDataBeforeAndAfter[0]);
        part.setAfter(newPartDataBeforeAndAfter[1]);
        const newPrompt = promptData.map((data) => getFullDisplayTextOfEachPart(data)).join(',');
        return newPrompt;
      }

    }
  };

  const handleChangeAndKeepCursor = (oldPrompt, event) => {
    const value = event.target.value;
    const newPrompt = onChangePrompt(oldPrompt, event);
    if (newPrompt !== oldPrompt) {
      setPrompt(newPrompt);
    } else {
      const selectionStart = event.target.selectionStart;
      const selectionEnd = event.target.selectionEnd;
      setTimeout(() => {
        if (value.length > oldPrompt.length) {
          event.target.setSelectionRange(selectionStart - 1, selectionEnd - 1);
        } else {
          event.target.setSelectionRange(selectionStart + 1, selectionEnd + 1);
        }
      }
        , 0);
    }

  }

  const findChangedPartIndex = (oldPrompt, parts) => {
    const oldParts = oldPrompt.split(',');
    for (let i = 0; i < oldParts.length; i++) {
      if (oldParts[i] !== parts[i]) {
        return i;
      }
    }
    return -1;
  }




  const renderPromptDialog = () => {
    const promptDisplay = getPrompt();
    return (
      <div className='col-12'>
        <PromptDialog
          ref={promptDialogRef}
          title={"提示词"}
          value={promptDisplay}
          onChange={(e) => handleChangeAndKeepCursor(promptDisplay, e)}
        />
      </div>
    )
  }

  const renderButtonToSelectStyleOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"图像风格"}
          selections={STYLES_OF_GENERATED_IMAGE}
          onClick={(selection) => setSelectedStyleOfGeneratedImage(selection)}
          selected={selectedStyleOfGeneratedImage}
        />
      </div>
    )
  }

  const renderButtonToSelectViewAngleOfTheVehice = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"角度/视角"}
          selections={VIEW_ANGLES_OF_THE_VEHICLE}
          onClick={(selection) => setSelectedViewAngleOfTheVehicle(selection)}
          selected={selectedViewAngleOfTheVehicle}
        />
      </div>
    )
  }

  const renderButtonToSelectScenarioOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"场景选择"}
          selections={SCENARIO_OF_GENERATED_IMAGE}
          onClick={(selection) => setSelectedScenarioOfGeneratedImage(selection)}
          selected={selectedScenarioOfGeneratedImage}
        />
      </div>
    )
  }

  const renderButtonToSelectCameraViewOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"镜头视角"}
          selections={CAMERA_OF_GENERATED_IMAGE}
          onClick={(selection) => setSelectedCameraOfGeneratedImage(selection)}
          selected={selectedCameraOfGeneratedImage}
        />
      </div>
    )
  }

  const renderNumberOfImagesToGenerate = () => {
    return (
      <div className='col-3 row button-set-number-of-images-wrapper'>
        <div className='col-6' style={{
          padding: 0,
          color: 'black',
          fontSize: 16
        }}>
          一次生成图片数量
        </div>
        <div className='col-6' style={{
          padding: 0
        }}>
          <NumberAdjustment
            value={numberOfImagesToGenerate}
            onChange={setNumberOfImagesToGenerate}
            min={1}
            max={4}
            step={1}
          />
        </div>

      </div>
    )
  }

  const setImageWidthValue = (value) => {
    if (value > MAX_IMAGE_SIZE) {
      value = MAX_IMAGE_SIZE;
    }
    if (value < 0) {
      value = 0;
    }

    setImageWidth(value);
  }

  const setImageHeightValue = (value) => {
    if (value > MAX_IMAGE_SIZE) {
      value = MAX_IMAGE_SIZE;
    }
    setImageHeight(value);
  }

  const inputNumberBox = (value, onChange, min, max) => {
    return (
      <div className='input-number-wrapper'>
        <input
          className='input-number-box'
          type='number'
          value={value}
          onChange={(e) => onChange(e.target.value)}
          min={min}
          max={max}
          step={1}
          disabled={isCMFTab()}
        />
        <div>
          PX
        </div>
      </div>
    )
  }


  const renderImagesResolutionsSetting = () => {
    return (
      <div className='col-5 offset-sm-2 images-resolution-settings-wrapper'>
        <div className='col-3 col-sm-3' 
             style={{
             color:isCMFTab() ? '#888888' : '#000000',
             padding: 0
        }}>
          生成图片大小
        </div>
        <div className='col-8 col-sm-8' style={{
          padding: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          {inputNumberBox(imageWidth, setImageWidthValue, 0, MAX_IMAGE_SIZE)}
          <div style={{
            margin: '0 10px',
            fontSize: '20px',
            color: '#969696'
          }}>
            *
          </div>
          {inputNumberBox(imageHeight, setImageHeightValue, 0, MAX_IMAGE_SIZE)}
        </div>
      </div>
    )
  }



  const renerButtonStartGenerating = () => {
    return (
      <div className='col-2 button-start-generating-wrapper'
        style={{
          paddingRight: 0
        }}>
        <div
          className='button-start-generating'
          onClick={loading ? null : handleGenerate}
          style={{
            color: 'white'
          }}
        >
          {loading && renderProgressBackground()}
          <div style={{
            zIndex: 1000
          }}>
            {displayTextOfButtonStartGenerating}
          </div>

        </div>
      </div>
    )
  }

  const renderImageSettings = () => {
    return (
      <div className='row col-12 image-settings-wrapper' style={{
        width: '100%',
      }}>
        {renderNumberOfImagesToGenerate()}
        {renderImagesResolutionsSetting()}
        {/* {renderCGFScaleSetting()} */}
        {renerButtonStartGenerating()}
      </div>
    )
  }

  const renderButtonSaveToLibrary = () => {
    return renderButtonForSelectedImage('保存到素材中心');
  }

  const renderButtonForSelectedImage = (text, action, isEnabled) => {
    return (
      <div
        style={{
          backgroundColor: '#215EF9',
        }}
      >
        {text}
      </div>
    )
  }

  const downloadImage = async (selectedImage) => {
    try {
      setLoading(true);
      console.log("selectedImage", selectedImage);
      const fileName = removePathFromImage(selectedImage).split('/').pop();
      //create a new anchor tag
      // const a = document.createElement('a');
      //set the href attribute to the path of the file
      const downloadurl = getImagePathToDownload(removePathFromImage(selectedImage));


      //set the download attribute to the file name
      // a.download = fileName;
      //trigger the download
      // document.body.appendChild(a);
      // a.click();
      // console.log("a", a);

      // Construct the URL with the image path
      const response = await fetch(downloadurl, {
        method: 'GET',
        credentials: 'include', // Include credentials if your Flask app requires authentication
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a new anchor tag and set its href to the blob URL
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName; // Use the file name from the path
      document.body.appendChild(a);
      a.click();

      // Clean up and remove the anchor tag
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (ex) {
      console.log(ex);
    }
    finally {
      setLoading(false);
    }

  }


  const toggleDisplayStateOfDownloadImageDropdown = () => {
    setDisplayDownloadImageDropdown(!displayDownloadImageDropdown);
  }


  const renderButtonDownloadImage = (selectedImage) => {
    return (
      <div style={{
        backgroundColor: '#215EF9',
        width: 160,
        height: 35,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontSize: 12,
        fontWeight: 400,
        borderRadius: 5,
        cursor: 'pointer',
        zIndex: 10000,
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
          onClick={() => { toggleDisplayStateOfDownloadImageDropdown() }}

        >
          <div style={{
            textAlign: 'center',
            cursor: 'pointer',
            marginLeft: 10,
          }} >
            原图
          </div>
          <div style={{
            borderRight: '1px solid white',
            paddingRight: 10,
            paddingLeft: 10,
            marginTop: -3
          }}>
            <img src={downloadImageDropdownIcon}
              style={{
                width: 10,
                height: 10,
              }}
            />
          </div>
        </div>

        <div style={{
          color: 'white',
          textAlign: 'center',
          cursor: 'pointer',
          paddingLeft: 10,
        }}
          onClick={() => downloadImage(selectedImage)}
        >
          下载到本地
        </div>
        {
          displayDownloadImageDropdown && renderDownloadImageDropdown(selectedImage)
        }
      </div>
    );
  }

  const upscaleImage = async (selectedImage, scale) => {

    console.log("scale", scale);
    setLoading(true);
    setDisplayDownloadImageDropdown(false);
    const isFromLocal = selectedImage.startsWith('blob:');
    let image = removePathFromImage(selectedImage);
    if (isFromLocal) {
      image = await getBase64(selectedFileToEdit);
    }
    const result = await fetch(getCurrentDomain() + 'upscale_image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "image": image,
        "scale": scale,
      })
    });
    const data = await result.json();
    const newImage = data.new_image;
    const newImagePath = getImagePathToDownload(newImage);
    // fetch(newImagePath);
    // Step 3: Trigger downloading the object using that URL
    const a = document.createElement('a');
    a.href = newImagePath;
    a.download = newImage.split('/').pop();
    a.click(); // triggering it manually
    // saveAs(newImagePath, newImage.split('/').pop());
    setLoading(false);
  }

  const renderUpscaleOption = (selectedImage, numberOfScale) => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        height: 35,
        alignItems: 'center',
        fontSize: 12,
        justifyContent: 'space-between',
        cursor: 'pointer',
      }}
        onClick={() => { upscaleImage(selectedImage, numberOfScale) }}
      >
        <div style={{
          color: 'black',
          marginLeft: 29
        }}>
          {numberOfScale}X
        </div>
        <div style={{
          color: '#D9D6D6',
          paddingRight: 25
        }}>

          {numberOfScale}倍大小

        </div>
      </div>
    )
  }

  const renderDownloadImageDropdown = (selectedImage) => {
    return (
      <div style={{
        position: 'absolute',
        top: 35,
        width: 160,
        backgroundColor: 'white',
      }}>
        {renderUpscaleOption(selectedImage, 2)}
        {renderUpscaleOption(selectedImage, 4)}
      </div>
    )
  }

  const renderButtonSendToEdit = (selectedImage) => {
    return (
      <div style={{
        backgroundColor: '#215EF9',
        width: 140,
        height: 35,
        fontSize: 12,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        marginLeft: 50,
        cursor: 'pointer',
      }}
        onClick={() => {
          sendImageToEdit(selectedImage);
        }}
      >
        发送到图片编辑
      </div>
    )
  }

  const copyImageToEdit = async (selectedImage) => {
    const url = getCurrentDomain() + 'copy_image_to_edit';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "image": removePathFromImage(selectedImage),
      })
    });
    const data = await response.json();
    return data.new_image;
  }

  const sendImageToEdit = async (selectedImage) => {
    setLoading(true);
    const newImage = await copyImageToEdit(selectedImage);
    setSelectedImageFullPage(null)
    changeTab(EDITING);
    setLoading(false);
  }

  const renderButtonMoveToPreviousImage = () => {
    return (
      <div className='button-move-to-previous-image' onClick={() => {
        const index = generatedImages.indexOf(selectedImage);
        if (index > 0) {
          setSelectedImage(generatedImages[index - 1]);
          setMaskInDb('')
        }
      }}>
        <div>
          <img src={ICON_PREV_IMAGE_BIG} />
        </div>
      </div>
    )
  }

  const renderButtonMoveToNextImage = () => {
    return (
      <div className='button-move-to-next-image' onClick={() => {
        const index = generatedImages.indexOf(selectedImage);
        if (index < generatedImages.length - 1) {
          setSelectedImage(generatedImages[index + 1]);
          setMaskInDb('')
        }
      }}>
        <div>
          <img src={ICON_NEXT_IMAGE_BIG} />
        </div>
      </div>
    )
  }


  const renderButtonWithIcon = (feature, icon, text, onClick, shouldDrawBorder = true) => {
    const isSelected = selectedButtonToEditImage === feature;
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        cursor: 'pointer',
        fontSize: 14,
        backgroundColor: isSelected ? APP_ACTIVE_COLOR : '#215EF9',
        width: 129,
        height: 45,

      }}
        onClick={onClick}
      >
        <div
          style={{
            borderRight: shouldDrawBorder ? '1px solid white' : 'none',
            width: '100%',
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img src={icon} style={{
            marginRight: 10,
          }} />ender
          {text}
        </div>

      </div>
    )
  }


  const renderSelectedImage = () => {
    return (
      loading && selectedImage == null ?
        <div className='loading-div'>
          <BeatLoaderSpinner />
        </div>
        : <img
          src={selectedImage}
          style={{
            maxWidth: '75%',
            maxHeight: 498,
            height: 'auto',
            width: 'auto',
          }}
        />

    )
  }

  const changeImageToDraw = (image) => {
    console.log("image", image);
    setSelectedImage(getImagePath(image.originalImagePath));
    setMaskInDb(getImagePath(image.originMarkPath));
  }

  const renderImageToEdit = () => {
    return (
      <DrawingCanvas
        allImagesOfUser={allImagesOfUser}
        changeImageToDraw={changeImageToDraw}
        maskInDB={maskInDb}
        ref={editImageRef} imageUrl={selectedImage} imagePathOnly={removePathFromImage(selectedImage)} handleRemoveBackground={handleRemoveBackground} isLoading={isRemovingBackground}
      />
    )
  }



  const isFromLocal = (image) => {
    return image.startsWith('blob:');
  }

  const handleRemoveBackground = async () => {
    setIsRemovingBackground(true);
    let imageBase64 = '';
    let isUseUserFile = true;
    if (isFromLocal(selectedImage) && selectedFileToEdit) {
      imageBase64 = await getBase64(selectedFileToEdit);
      isUseUserFile = false;
    }
    fetch(getCurrentDomain() + 'remove_background', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "image": removePathFromImage(selectedImage),
        "imageBase64": imageBase64,
        isUseUserFile,
      })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        const newImage = getImagePath(data.new_images[0]);
        setSelectedImage(newImage);
        setGeneratedImages([newImage, ...generatedImages]);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsRemovingBackground(false);
      });
  }

  const handleAutoSam = async () => {
    if (editingImageAutoSamImageResult) {
      setShouldDisplayMaskToSelect(true);
      return
    }

    setIsRemovingBackground(true);
    let imageBase64 = '';
    let isUseUserFile = true;
    fetch(getCurrentDomain() + 'auto-sam', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "image": await uploadImageToServer(cmfImageInput),
        isUseUserFile,
      })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        const newImage = getImagePath(data.new_images[0]);
        setEditingImageAutoSamImageResult(newImage);
        const masks = data.mask_data;
        setEditingImageMasksAutoSam(masks);
        setShouldDisplayMaskToSelect(true);

      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsRemovingBackground(false);
      });
  }

  const getDrawnCanvas = async () => {
    const markedCanvas = await editImageRef.current.getMarkedCanvas();
    const base64 = convertCanvasToBase64(markedCanvas);
    return base64;
  }

  const convertCanvasToBase64 = (canvas) => {
    return canvas.toDataURL('image/png');
  }


  const shouldRenderImageEditingMainView = () => {
    return isEditingTab() || isCMFTab();
  }



  const renderMainImage = () => {
    return (
      <div className='row col-12 main-image-wrapper'>
        <div className='col-12 selected-image' style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 500,
          top: 36
        }}>
          {isEditingTab() && renderImageToEdit()}
          {!isEditingTab() && renderSelectedImage()}
          {renderButtonMoveToPreviousImage()}
          {renderButtonMoveToNextImage()}
        </div>
        <div className='col-12 selected-button-control-list'>
          {renderButtonDownloadImage(selectedImage)}
          {!isEditingTab() && renderButtonSendToEdit(selectedImage)}
        </div>
      </div>
    )
  }

  const renderProgressBackground = () => {
    return (
      <div className='progress-background' style={{
        width: `${generatingProgress}%`,
      }} />
    )
  }

  const renderCarImagesSlider = () => {
    return (
      <div className='row col-12 car-images-slider-wrapper'>
        <div className='col-12'>
          {
            generatedImages.length > 0 &&
            <Slider
              images={generatedImages}
              autoPlay={false}
              autoPlayTime={3000}
              selectImage={setSelectedImageFullPage}
            />
          }
        </div>

      </div>
    )
  }

  const renderTitleWithNewLine = (title, disabled) => {
    return (
      <div className='col-12'>
        <div className='row' style={{
          marginTop: 10,
        }}>
          <div className="title" style={{
            color: 'black'
          }}>
            {title}
          </div>
        </div>

      </div>
    )
  }

  const toggleSelectUploadImgRefControl = () => {
    const newIsSelectedUploadImgRefControl = !isSelectedUploadImageMaskRef;
    setIsSelectedUploadImgRef(newIsSelectedUploadImgRefControl);
  }


  const renderPromptWeight = () => {
    return (
      <></>
    )
    return (
      <>
        {renderTitleWithNewLine("提示词影响权重", true)}
        {renderSliderToSetPromptWeight()}
      </>
    )
  }

  const renderSliderToSetImageMaskWeight = () => {
    return (
      <>
        {renderTitleWithNewLine("参考图影响权重", true)}
        {renderSliderToSetMaskImageRefWeight()}
      </>
    )
  }

  const renderSliderToSetMaskImageRefWeight = () => {
    return (
      <div className='col-12'>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={imgRefWeight}
            setValue={setImgRefWeight}
            max={100}
            min={0}
            maxDisplay={100}
            minDisplay={0}
            step={1}
            disabled={false}
            postFix={"%"}
          />
        </div>

      </div>
    )
  }


  const renderSliderToSeCMFColorWeightWeight = () => {
    return (
      <div className='col-12'>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={cmfColorWeight}
            setValue={setCmfColorWeight}
            max={100}
            min={0}
            maxDisplay={100}
            minDisplay={0}
            step={1}
            disabled={false}
            postFix={"%"}
          />
        </div>

      </div>
    )
  }

  const renderSliderToSeCMFMaterialWeight = () => {
    return (
      <div className='col-12'>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={cmfMaterialWeight}
            setValue={setCmfMaterialWeight}
            max={100}
            min={0}
            maxDisplay={100}
            minDisplay={0}
            step={1}
            disabled={false}
            postFix={"%"}
          />
        </div>

      </div>
    )
  }

  const renderSliderToSetPromptWeight = () => {
    return (
      <div className='col-12'>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={promptWeight}
            setValue={setPromptWeight}
            max={100}
            min={0}
            maxDisplay={100}
            minDisplay={0}
            step={1}
            disabled={false}
            postFix={"%"}
          />
        </div>

      </div>
    )
  }


  const renderCheckBoxToUploadImageMaskReference = () => {
    return (
      <div className='custom-checkbox' onClick={toggleSelectUploadImgRefControl} style={{
        justifyContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        color: 'black'
      }}>
        <img src={isSelectedUploadImageMaskRef ? checkedIcon : uncheckedIcon} style={{
          marginRight: 10
        }}
          height={20}
        />
        参考图片
      </div>
    )
  }

  const isRenderingTab = () => {
    return tab === AI_RENDERING;
  }

  const isEditingTab = () => {
    return tab === EDITING;
  }

  const isCMFTab = () => {
    return tab === CMF;
  }

  const handleUploadImage = () => {
    console.log("handleUploadImage", isEditingTab());
    if (isRenderingTab() || isEditingTab() || isCMFTab()) {
      fileInputRef.current.click();
    }
    else {
      if (isSelectedUploadImageMaskRef) {
        fileInputRef.current.click();
      }
      else {
        setIsShowErrorWhenClickOnButtonUploadImage(true);
      }
    }
  }

  const handleUploadImageMaskRef = () => {
    if (isSelectedUploadImageMaskRef) {
      fileInputToUploadMaskImageRef.current.click();
    }
    else {
      setIsShowErrorWhenClickOnButtonUploadImage(true);
    }
  }

  const handleUploadMaterialImage = () => {
    if (isUsingAIMaterial) {
      fileInputToUploadMaterialImageRef.current.click();
    }
    else {
      setIsShowErrorWhenClickOnButtonUploadImage(true);
    }
  }

  const handleUploadCMFColorImageInput = () => {
    //ClickableImage
     //setSourceValue(source.value);
    if (isUsingAIColoring) {
      fileInputToUploadCMFColorImageRef.current.click();
    }
    else {
      setIsShowErrorWhenClickOnButtonUploadImage(true);
    }
  }

  const handleUploadCMFImageInput = () => {
    fileInputToUploadCMFImageRef.current.click();
  }

  const renderButtonToUploadImageMaskRef = () => {
    return (
      <div className='button-upload-image-ref' onClick={() => handleUploadImageMaskRef()}>
        上传图片
      </div>
    )
  }

  const renderCheckBoxForUploadImagesAndButtonUploadingImage = () => {
    return (
      <div className='col-12'>
        <div className='row' style={{
          marginTop: 20,
        }}>
          <div className='col-4'>
            {renderCheckBoxToUploadImageMaskReference()}
          </div>
          <div className='col-8' style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            {renderButtonToUploadImageMaskRef()}
          </div>
        </div>
      </div>
    )
  }

  const renderModalToShowCannotUploadImageRef = () => {
    return (
      <div className='modal-cannot-upload-image-ref' style={{
        display: isShowErrorWhenClickOnButtonUploadImage ? 'flex' : 'none',
        position: 'fixed',
        top: window.innerHeight / 3 - 100,
        left: window.innerWidth / 2 - 150,
        zIndex: 1000000000,
        width: 650,
        height: 413,
        backgroundColor: 'white',
        color: 'black',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 20,
      }}>
        <div style={{
          marginTop: 122
        }}>
          请先勾选启用参考图片功能
        </div>
        <div style={{
          marginTop: 29
        }}>
          勾选后再重新点击图片上传功能进行上传
        </div>
        <div style={{
          width: 270,
          height: 51,
          backgroundColor: "#215EF9",
          marginTop: 62,
          color: "white",
          borderRadius: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
          onClick={() => setIsShowErrorWhenClickOnButtonUploadImage(false)}
        >
          确 定
        </div>
      </div>
    )
  }

  const renderCheckIConHiddenForLoading = () => {
    return (
      <img src={checkedIcon} style={{
        display: 'none'
      }} />
    )
  }

  const handleCMFImageInputFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      setCmfImageInput(file);
      setEdittingMaskInBase64OfCMFInput('')
      setShouldDisplayMaskToSelect(false);
      setEditingImageAutoSamImageResult('');
      setEditingImageMasksAutoSam(null);
      // Reset the input value to allow uploading the same file again
      event.target.value = '';
    }
  };

  const handleImageMaskInputFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      setSelectedImageMaskRef(file);
      setEdittingMaskInBase64('')
      // Reset the input value to allow uploading the same file again
      event.target.value = '';
    }
  }

  const handleMaterialImageFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      setSelectedRefMaterialImage(file);
      // Reset the input value to allow uploading the same file again
      event.target.value = '';
    }
  };


  const handleCMFColorImageImageFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      setSelectedCMFColorImageFile(file);
      // Reset the input value to allow uploading the same file again
      event.target.value = '';
    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      if (shouldRenderImageEditingMainView()) {
        //add file to the editor
        //add file to list 
        const fileURL = URL.createObjectURL(file);
        console.log(fileURL);
        setSelectedImage(fileURL);
        //append new image to the list
        setGeneratedImages([fileURL, ...generatedImages]);
      } else {
        setSelectedImageOrCadRef(file);
      }
      // Reset the input value to allow uploading the same file again
      event.target.value = '';
    }
  };


  const renderSelectedImageOrCadRef = () => {
    if (selectedImageOrCadRef && (isSelectedUploadImageMaskRef || isRenderingTab())) {
      return (
        <div className='col-12 selected-image-ref-wrapper' style={{
        }}>
          <img src={URL.createObjectURL(selectedImageOrCadRef)} width={"100%"} style={{
            marginTop: 20,
            marginBottom: 10,
            paddingLeft: 10,
            paddingRight: 10
          }} />
        </div>
      )
    }
  }

  const renderSelectedMaskImageRef = () => {
    if (selectedImageMaskRef && isSelectedUploadImageMaskRef) {
      return (
        <div className='col-12 selected-image-ref-wrapper' style={{
        }}>
          <div style={{
            display: 'flex',
            position: 'relative',
          }}>
            <img src={URL.createObjectURL(selectedImageMaskRef)} width={"100%"} style={{
              marginTop: 20,
              marginBottom: 10,
              paddingLeft: 10,
              paddingRight: 10
            }} />
            {
              selectedStyleOfReferenceContentImage.value == CUSTOM_DRAW_MASK &&  edittingMaskInBase64 && <img src={edittingMaskInBase64} width={"100%"} style={{
                marginTop: 20,
                marginBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                position: 'absolute',
              }} />
            }  
          </div>
          
              {/* {renderButtonToEditImageMaskRef()} */}
              {renderReferenceContent()}
              {renderSliderToSetImageMaskWeight()}             
        </div>
      )
    }
  }

  // const renderButtonToEditImageMaskRef = () => {
  //   return (
  //     <div className='row'>
  //       <div className='col-3 offset-9 button-upload-image-ref' onClick={() => setImageToEditOnTool(URL.createObjectURL(selectedImageMaskRef))}>
  //         选择编辑区域
  //       </div>
  //     </div>
      
  //  )
  // }

  const setImageToEditOnTool = (image) => {
    setEditingImage(image);
    setIsEditingImage(true);
  }

  const renderDownloadButtonAndSendToEditButton = (selectedImage) => {
    return (
      <div className='row col-12' style={{
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {renderButtonDownloadImage(selectedImage)}
        {!isEditingTab() && renderButtonSendToEdit(selectedImage)}
      </div>
    )
  }

  const renderSelectedImageFullSize = () => {
    return (
      <div style={{
        display: selectedImageFullPage ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000000000,
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',

        }}>
          <img src={selectedImageFullPage} style={{
            height: '678px',
          }} />

          <img src={closeButtonIcon} style={{
            cursor: 'pointer',
            width: 27,
            marginLeft: 20,
          }}
            onClick={() => setSelectedImageFullPage(null)}
          />
        </div>
        {renderDownloadButtonAndSendToEditButton(selectedImageFullPage)}

      </div>
    )
  }

  const renderDraftSketchSelection = () => {
    return (
      <>
        {renderTitleWithNewLine("产品草图上传", true)}
        <div className='col-12' style={{
          marginTop: 10,
        }}>
          <div className='row'>
            <div className='col-8' style={{
              marginRight: 0,
              paddingRight: 0
            }}>
              <CustomSelectWithoutTitle options={DRAFT_SKETCH_TYPE_SELECTIONS} value={draftSketchType} onChange={setDraftSketchType} />
            </div>
            <div className='col-4' style={{
              marginLeft: 0,
              paddingRight: 0,
              paddingLeft: 3,
            }}>
              <div
                className='button-upload-image-ref'
                onClick={() => handleUploadImage()}
                style={{
                  height: 41,
                  width: '100%',
                  borderRadius: 2,
                }}
              >
                上传图片
              </div>
            </div>
          </div>
        </div>
        {renderSelectedImageOrCadRef()}
      </>

    )
  }


  const renderButtonToUploadImageWithTitle = () => {
    return (
      <>

        <div className='col-12' style={{
          marginTop: 30,
        }}>
          <div className='row'>
            <div className='col-8' style={{
              marginRight: 0,
              paddingRight: 0,
              color: '#363636',
              display: 'flex',
              alignItems: 'center',
              fontSize: 16,
            }}>请上传需要编辑的图片
            </div>
            <div className='col-4' style={{
              marginLeft: 0,
              paddingRight: 0,
              paddingLeft: 3,
            }}>
              <div
                className='button-upload-image-ref'
                onClick={() => handleUploadImage()}
                style={{
                  height: 41,
                  width: '100%',
                  borderRadius: 2,
                }}
              >
                上传图片
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderButtonToUploadCMFInputImage = () => {
    return (
      <>

        <div className='col-12' style={{
          marginTop: 30,
        }}>
          <div className='row'>
            <div className='col-8' style={{
              marginRight: 0,
              paddingRight: 0,
              color: '#363636',
              display: 'flex',
              alignItems: 'center',
              fontSize: 16,
            }}>请上传需要编辑的图片
            </div>
            <div className='col-4' style={{
              marginLeft: 0,
              paddingRight: 0,
              paddingLeft: 3,
            }}>
              <div
                className='button-upload-image-ref'
                onClick={() => handleUploadCMFImageInput()}
                style={{
                  height: 41,
                  width: '100%',
                  borderRadius: 2,
                }}
              >
                上传图片
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  
  const isUsingColorUploadImage=()=>{
    return selectedAIColoringOption && selectedAIColoringOption.value===AI_COLORING_UPLOAD_IMAGE;
  }

  

  const renderLoadingPage = () => {
    return (
      <div className='col-12' style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        display: loading ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10000000000000
      }}>
        <BeatLoaderSpinner />
      </div>
    )
  }

  const isSelectedOtherProductCategory = () => {
    return selectedProductCategory.label === OTHER_CATEGORY;
  }

  const renderOtherProductCategoryInput = () => {
    return (
      <div className='col-12' style={{
        marginTop: 10,
      }}>
         {/* <CustomInput
          value={otherProductCategory}
          onChange={(e) => setOtherProductCategory(e.target.value)}
          placeholder={"请输入其他产品类别"}
        /> */}
      </div>
    )
  }

  const isAICarTab = () => {
    return tab === AI_CAR;
  }

  const AI_CAR_renderButtonsSelectMode = () => {
    return (
      <div className='row col-12' style={{
        marginTop: '30px',
        height: '50px',
      }}>
        {AI_CAR_renderButtonSelectMode('文生图', 'txt2img', 'offset-2')}
        {AI_CAR_renderButtonSelectMode('图生图', 'img2img', '')}
      </div>
    )
  }


  const AI_CAR_renderButtonSelectMode = (label, mode, additionalClass = "") => {
    return (
      <div
        className={`col-3 generate-button-select-mode ${additionalClass}`}
        onClick={() => CAR_AI_setSelectedMode(mode)}
        style={{
          backgroundColor: CAR_AI_selectedMode === mode ? APP_ACTIVE_COLOR : 'inherit',
          color: CAR_AI_selectedMode === mode ? 'white' : 'black'
        }}
      >
        {label}
      </div>
    )
  }


  const AI_CAR_renderSDModelSelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='风格模型选择（整体设计风格)' options={sd_models} value={CAR_AI_selectedSDModel} onChange={CAR_AI_setSelectedSDModel} />
      </div>

    )
  }

  const AI_CAR_renderCarModelSelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='车型模型选择（造型精准控制)' options={CAR_AI_carModels} value={CAR_AI_selectedCarModel} onChange={CAR_AI_setSelectedCarModel} />
      </div>

    )
  }

  const AI_CAR_renderPromptDialog = () => {
    return (
      <div className='col-12'>
        <PromptDialog
          title={"提示词输入"}
          value={CAR_AI_prompt}
          onChange={(e) => CAR_AI_setPrompt(e.target.value)}
        />
      </div>
    )
  }

  const AI_CAR_renderTitleWithNewLine = (title, disabled) => {
    return (
      <div className='col-12'>
        <div className='row list-of-button-selections'>
          <div className="title" style={{
            color: disabled ? 'inherit' : '#C8C8C8'
          }}>
            {title}
          </div>
        </div>

      </div>
    )
  }

  const AI_CAR_renderPreciselyControlProducAppearanceAndDetails = () => {
    const disabled = false;
    const title = "精准控制 产品外造型及细节";
    return (
      <div className='col-12'>
        <div className='title-with-checkbox'>
          <div className="title" style={{
            color: disabled ? 'inherit' : '#C8C8C8'
          }}>
            {title}
          </div>
          <div className='custom-checkbox' onClick={toggleSelectedPreciselyControl}>
            <img src={CAR_AI_isSelectedPreciselyControl ? checkedIcon : uncheckedIcon} />
          </div>
        </div>

      </div>
    )
  }

  const getNewPromptFromBackgroundDictAndWeather = () => {
    return `Car photography, a white Nissan X-trial, ${CAR_AI_selectedSceneOfGeneratedImage.value}, ${CAR_AI_selectedWeatherOfGeneratedImage.value}, realistic, Cinematic shot, Cinematic lighting, 8k uhd, high quality, film grain, Fujifilm XT3`;
  }


  const toggleSelectedPreciselyControl = () => {
    const newIsSelectedPreciselyControl = !CAR_AI_isSelectedPreciselyControl;
    CAR_AI_setISelectedPreciselyControl(newIsSelectedPreciselyControl);
    if (!newIsSelectedPreciselyControl) {
      CAR_AI_setProportionOfTheBodyToTheOverallScreen(0);
      CAR_AI_setPrompt("")
    } else {
      CAR_AI_setPrompt(getNewPromptFromBackgroundDictAndWeather())
    }

  }


  const AI_CAR_renderProportionOfTheBodyToTheOverallScreen = () => {
    return (
      <>
        {AI_CAR_renderTitleWithNewLine("车身占整体画面比例", !CAR_AI_isSelectedPreciselyControl)}
        {AI_CAR_renderSliderToAdjustProportionOfTheBodyToTheOverallScreen()}
      </>
    )
  }

  const AI_CAR_renderSliderToAdjustProportionOfTheBodyToTheOverallScreen = () => {
    return (
      <div className='col-12'>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={CAR_AI_proportionOfTheBodyToTheOverallScreen}
            setValue={CAR_AI_setProportionOfTheBodyToTheOverallScreen}
            max={100}
            min={0}
            maxDisplay={1}
            minDisplay={0}
            step={CAR_AI_isSelectedPreciselyControl ? 50 : 1}
            disabled={!CAR_AI_isSelectedPreciselyControl}
          />
        </div>

      </div>
    )
  }

  const AI_CAR_renderCfgInput = () => {
    return (
      <InlineInputWithTitle
        title={"CFG"}
        value={CAR_AI_cfgScale}
        onChange={CAR_AI_setCfgScale}
        min={0}
        max={100}
      />
    )
  }

  const AI_CAR_renderStepsInput = () => {
    return (
      <InlineInputWithTitle
        title={"采样步数"}
        value={CAR_AI_steps}
        onChange={CAR_AI_setSteps}
        min={0}
        max={200}
        numberType='integer'
      />
    )
  }
  const AI_CAR_renderSchedulerNameSelect = () => {
    return (
      <CustomSelect
        title='调度器'
        options={CAR_AI_schedulerNames}
        value={CAR_AI_selectedSchedulerName}
        onChange={CAR_AI_setSelectedSchedulerName}
      />
    )
  }

  const AI_CAR_renderSamplerNameSelect = () => {
    return (
      <CustomSelect
        title='采样算法'
        options={CAR_AI_samplerNames}
        value={CAR_AI_selectedSamplerName}
        onChange={CAR_AI_setSelectedSamplerName}
      />
    )
  }

  const AI_CAR_renderButtonToSelectStyleOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"图像风格"}
          selections={CAR_AI_STYLES_OF_GENERATED_IMAGE}
          onClick={(selection) => CAR_AI_setSelectedStyleOfGeneratedImage(selection)}
          selected={CAR_AI_selectedStyleOfGeneratedImage}
          disabled={true}
        />
      </div>
    )
  }

  const AI_CAR_renderButtonToSelectViewAngleOfTheVehice = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"角度/视角"}
          selections={CAR_AI_VIEW_ANGLES_OF_THE_VEHICLE}
          onClick={(selection) => CAR_AI_setSelectedViewAngleOfTheVehicle(selection)}
          selected={CAR_AI_selectedViewAngleOfTheVehicle}
          disabled={!CAR_AI_isSelectedPreciselyControl}
        />
      </div>
    )
  }

  const AI_CAR_renderButtonToSelectSenceOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"场景选择"}
          selections={CAR_AI_SCENE_OF_GENERATED_IMAGE}
          onClick={(selection) => CAR_AI_setSelectedSceneOfGeneratedImage(selection)}
          selected={CAR_AI_selectedSceneOfGeneratedImage}
          disabled={!CAR_AI_isSelectedPreciselyControl}
        />
      </div>
    )
  }

  const AI_CAR_renderButtonToSelectWeatherOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"天气选择"}
          selections={CAR_AI_WEATHER_OF_GENERATED_IMAGE}
          onClick={(selection) => CAR_AI_setSelectedWeatherOfGeneratedImage(selection)}
          selected={CAR_AI_selectedWeatherOfGeneratedImage}
          disabled={!CAR_AI_isSelectedPreciselyControl}
        />
      </div>
    )
  }



  const renderAICARConfig = () => {
    return (
      <div className='col-3' style={{
      }}>
        {/* {AI_CAR_renderButtonsSelectMode()} */}
        {AI_CAR_renderSDModelSelection()}
        {AI_CAR_renderCarModelSelection()}
        {AI_CAR_renderPromptDialog()}
        {AI_CAR_renderTitleWithNewLine("车型外观控制")}
        {/* {AI_CAR_renderPreciselyControlProducAppearanceAndDetails()} */}
        {AI_CAR_renderProportionOfTheBodyToTheOverallScreen()}
        {AI_CAR_renderCfgInput()}
        {AI_CAR_renderStepsInput()}
        {AI_CAR_renderSamplerNameSelect()}
        {AI_CAR_renderSchedulerNameSelect()}
        {AI_CAR_renderButtonToSelectStyleOfGeneratedImage()}
        {AI_CAR_renderButtonToSelectViewAngleOfTheVehice()}
        {AI_CAR_renderButtonToSelectSenceOfGeneratedImage()}
        {AI_CAR_renderButtonToSelectWeatherOfGeneratedImage()}
      </div>
    )

  }

  const renderAIEditingConfig = () => {
    return (
      <>
        {renderButtonToUploadImageWithTitle()}
        {renderSDModelSelection()}
        {renderPromptDialog()}
        {renderPromptWeight()}
      </>
    )
  }

  const toggleSelectIsUsingAIColoring = () => {
    setIsUsingAIColoring(!isUsingAIColoring);
  }

  const toggleSelectIsUsingAIMaterial = () => {
    setIsUsingAIMaterial(!isUsingAIMaterial);
  }

  const renderCheckboxToSelectAIColoring = () => {
    return (
      <div className='col-12'>
        <div className='row' style={{
          marginTop: 20,
        }}>
          <div className='custom-checkbox' onClick={toggleSelectIsUsingAIColoring} style={{
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
            color: 'black'
          }}>
            <img src={isUsingAIColoring ? checkedIcon : uncheckedIcon} style={{
              marginRight: 10
            }}
              height={20}
            />
            配色设计（请选择需要生成的颜色）
          </div>
        </div>
      </div>
    )
  }

  const renderCheckboxToSlectAIMaterial = () => {
    return (
      <div className='col-12'>
        <div className='row' style={{
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',

        }}>
          <div className='col-8'>
            <div className='custom-checkbox' onClick={toggleSelectIsUsingAIMaterial} style={{
              justifyContent: 'flex-start',
              display: 'flex',
              alignItems: 'center',
              color: 'black'
            }}>
              <img src={isUsingAIMaterial ? checkedIcon : uncheckedIcon} style={{
                marginRight: 10
              }}
                height={20}
              />
              材质工艺设计（请选择表面效果）
            </div>
          </div>
          <div className='col-4' style={{
            marginLeft: 0,
            paddingRight: 0,
            paddingLeft: 3,
          }}>
            <div
              className='button-upload-image-ref'
              onClick={() => handleUploadMaterialImage()}
              style={{
                height: 41,
                width: '100%',
                borderRadius: 2,
              }}
            >
              上传图片
            </div>
          </div>
        </div>
      </div>
    )
  }


  const renderSelectedMateriaImglRef = () => {
    return renderImage(selectedMaterialRefImage);
  }

  const renderImage = (imgScr) => {
    return (
      <div className='col-12 selected-image-ref-wrapper' style={{
      }}>
        <img src={URL.createObjectURL(imgScr)} width={"100%"} style={{
          marginTop: 20,
          marginBottom: 10,
          paddingLeft: 10,
          paddingRight: 10
        }} />
      </div>
    )
}

  const renderDropdownToSelectColorTypeForAIColoring = () => {
    return (
      <>

        <div className='col-12' style={{
          marginTop: 20,
          marginBottom: 10,
        }}>
          <div className='row'>
            <div className='col-5' style={{
              marginRight: 0,
              paddingRight: 0,
              color: '#363636',
              display: 'flex',
              alignItems: 'center',
              fontSize: 16,
            }}>
              请选择颜色标准
            </div>
            <div className='col-7' style={{
              marginLeft: 0,
              paddingRight: 0,
              paddingLeft: 3,
            }}>
              <CustomSelectWithoutTitle options={AI_COLORING_SELECT_OPTIONS} value={selectedAIColoringOption} onChange={setSelectedAIColoringOption} />
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderSearchColorInput = () => {
    return (
      <div style={{
        width: 150,
        height: 25,
        border: '1px solid rgba(150, 150, 150, 1)',
        borderRadius: 2,
        alignItems: 'center',
        display: 'flex',
      }}>
        <img src={searchIcon} style={{
          width: 11,
          height: 11,
          marginLeft: 5
        }} />

        <input
          type="text"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            outline: 'none',
            fontSize: 11,
            marginLeft: 5
          }}
          value={colorSearchText}
          onChange={(e) => setColorSearchText(e.target.value)}
        />

      </div>
    )
  }

  const renderSearchBoxForColorPanel = () => {
    return (
      <div className='row' style={{
      }}>
        <div className='col-6' style={{
          color: 'black',
          fontSize: 16,
          display: 'flex',
          alignItems: 'center',
        }}>
          请选择颜色
        </div>
        <div className='col-6' style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          {renderSearchColorInput()}
        </div>
      </div>
    )
  }

  const getFilteredColors = (colors) => {
    //search in on both color name and color hex
    return colors.filter(color => color.Name.toLowerCase().includes(colorSearchText.toLowerCase()) || color.Hex.toLowerCase().includes(colorSearchText.toLowerCase()));
  }

  const renderColorPanelSelectetion = (colors) => {
    const colorsToDisplay = getFilteredColors(colors);
    return (
      <div className='row col-12'>
        {renderSearchBoxForColorPanel()}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap", // Auto-wrap the colors
            justifyContent: "flex-start",
            margin: "20px auto",
            height: 300,
            overflow: "auto",
            alignContent: "flex-start",
          }}
        >
          {colorsToDisplay.map((color, index) => (
            <ColorBox
              key={index}
              color={color}
              isSelected={selectedColor === color.Hex}
              onClick={() => setSelectedColor(color.Hex)}
            />
          ))}
        </div>
      </div>
    );
  }


  const isUsingPantone = () => {
    return selectedAIColoringOption && selectedAIColoringOption.value === AI_COLORING_PANTON;
  }

  const isUsingRAL = () => {
    return selectedAIColoringOption && selectedAIColoringOption.value === AI_COLORING_RAL;
  }

  const isUsingCustomizedColor = () => {
    return selectedAIColoringOption && selectedAIColoringOption.value === AI_COLORING_CUSTOMIZED;
  }

  const renderCustomizedColorSelection = () => {
    return (
      <div className='row col-12'style={{ alignItems: 'center' }}>
         <div className='col-5' style={{
              color: '#363636',
              display: 'flex',
              alignItems: 'center',
              fontSize: 16,
            }}>
              请选择类型并输入色值
            </div>
            <div className='col-7' style={{
              marginLeft: 233,
              paddingRight: 0,
              paddingLeft: 3,
              width:'100px',
              justifyContent: 'flex-end'

             
            }}>
               <CustomSelectWithoutTitle options={CUSTIMIZED_COLOR_OPTIONS} value={customizedColorMode} onChange={setCustomizedColorMode} />
            </div>
         
            {
              customizedColorMode && customizedColorMode.value === CUSTOMIZED_COLOR_MODE_HEX &&
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                maxWidth: '580px',  
                paddingLeft: '10px', 
                paddingRight: '0px', 
                marginTop: '15px',
            }}>
                {renderSmallInputWithTitle(100, "色值", customizedColorValue['hex'], (e) => updateCustomizeColor('hex', e.target.value), true)}
                
            </div>
              
            }
            {
              customizedColorMode && customizedColorMode.value === CUSTOMIZED_COLOR_MODE_CMYK &&
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                maxWidth: '580px',  // 略微增大容器的宽度
                paddingLeft: '10px',  // 控制左侧对齐
                paddingRight: '0px', // 控制右侧对齐
                marginTop: '15px',
            }}>
                {renderSmallInputWithTitle(94, "C", customizedColorValue['C'], (e) => updateCustomizeColor('C', e.target.value))}
                {renderSmallInputWithTitle(94, "M", customizedColorValue['M'], (e) => updateCustomizeColor('M', e.target.value))}
                {renderSmallInputWithTitle(94, "Y", customizedColorValue['Y'], (e) => updateCustomizeColor('Y', e.target.value))}
                {renderSmallInputWithTitle(94, "K", customizedColorValue['K'], (e) => updateCustomizeColor('K', e.target.value))}
            </div>
             

            }
            {
              customizedColorMode && customizedColorMode.value === CUSTOMIZED_COLOR_MODE_RGB &&
              // <>
              //   {renderSmallInputWithTitle(50, "R", customizedColorValue['R'], (e) => updateCustomizeColor('R', e.target.value))}
              //   {renderSmallInputWithTitle(50, "G", customizedColorValue['G'], (e) => updateCustomizeColor('G', e.target.value))}
              //   {renderSmallInputWithTitle(50, "B", customizedColorValue['B'], (e) => updateCustomizeColor('B', e.target.value))}
              // </>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                maxWidth: '580px',  // 略微增大容器的宽度
                paddingLeft: '10px',  // 控制左侧对齐
                paddingRight: '0px', // 控制右侧对齐
                marginTop: '15px',
            }}>
                 {renderSmallInputWithTitle(150, "R", customizedColorValue['R'], (e) => updateCustomizeColor('R', e.target.value))}
                 {renderSmallInputWithTitle(150, "G", customizedColorValue['G'], (e) => updateCustomizeColor('G', e.target.value))}
                 {renderSmallInputWithTitle(150, "B", customizedColorValue['B'], (e) => updateCustomizeColor('B', e.target.value))}
            </div>
             
            }

          {/* </div> */}
        {/* </div> */}
      </div>
    )
  }

  const updateCustomizeColor = (keyData, value) => {
    if (keyData === 'hex') {
      if (value.length > 7 || value.length < 1) {
        return;
      }
      if (value[0] !== '#') {
        return;
      }
    }

     if(keyData ==="C" || keyData ==="M"|| keyData ==="Y"|| keyData ==="K"){
      const numericValue = Number(value);
      if(isNaN(numericValue) || numericValue < 0 || numericValue > 100){
        return;
      }
    }
    if(keyData ==="R" || keyData ==="G"|| keyData ==="B"){
      const numericValue = Number(value);
      if(isNaN(numericValue) || numericValue < 0 || numericValue > 255){
        return;
      }
    }

    setCustomizedColorValue({
      ...customizedColorValue,
      [keyData]: value
    })
  }

  const getUserInputColor =  async () => {
    if (selectedAIColoringOption.value === AI_COLORING_UPLOAD_IMAGE) {
      if (selectedCMFColorImageFile) {
        setLoading(true);
        const image_url = await uploadImageToServer(selectedCMFColorImageFile);
        return image_url;
      }
      return null;
    }
    if (selectedAIColoringOption.value === AI_COLORING_PANTON || selectedAIColoringOption.value === AI_COLORING_RAL)
    {
      return selectedColor;
    }

    let hexColor = null;
  
    const { C, M, Y, K, R, G, B, hex } = customizedColorValue;
  
    if (customizedColorMode.value === CUSTOMIZED_COLOR_MODE_CMYK) {
      if (C !== undefined && M !== undefined && Y !== undefined && K !== undefined) {
        const c = C / 100;
        const m = M / 100;
        const y = Y / 100;
        const k = K / 100;
  
        const r = Math.round(255 * (1 - c) * (1 - k));
        const g = Math.round(255 * (1 - m) * (1 - k));
        const b = Math.round(255 * (1 - y) * (1 - k));
  
        if (isValidRgb(r, g, b)) {
          hexColor = rgbToHex(r, g, b);
        }
      }
    }
  
    if (customizedColorMode.value === CUSTOMIZED_COLOR_MODE_RGB) {
      if (R !== undefined && G !== undefined && B !== undefined) {
        const r = parseInt(R);
        const g = parseInt(G);
        const b = parseInt(B);
  
        if (isValidRgb(r, g, b)) {
          hexColor = rgbToHex(r, g, b);
        }
      }
    }
    if(customizedColorMode.value===CUSTOMIZED_COLOR_MODE_HEX)
    {
      if(hex!==undefined){
          hexColor=hex;
      }
    }
    return hexColor;
  };
  
  const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  };
  
  const isValidRgb = (r, g, b) => {
    return (
      r >= 0 && r <= 255 &&
      g >= 0 && g <= 255 &&
      b >= 0 && b <= 255
    );
  };

  const renderColorUploadImage = () => {
    return (
      <div className='row col-12'>
          <div className='row'>
            <div className='col-8' style={{
              marginRight: 0,
              paddingRight: 0,
              color: '#363636',
              display: 'flex',
              alignItems: 'center',
              fontSize: 16,
            }}>请上传需要参考的颜色图片
            </div>
            <div className='col-4' style={{
              marginLeft: 0,
              paddingRight: 0,
              paddingLeft: 3,
            }}>
              <div
                className='button-upload-image-ref'
                onClick={() => handleUploadCMFColorImageInput()}
                style={{
                  height: 41,
                  width: '100%',
                  borderRadius: 2,
                }}
              >
                上传图片
              </div>
            </div>
          </div>
          {selectedCMFColorImageFile && renderImage(selectedCMFColorImageFile)}        
      <div className='row mt-4'>
        <div className='col-4'>
          <img     
            src={reddummy}    
            // onClick={()=>handleUploadCMFColorImageInput({ value: 'image' })}         
            style={{
              width: '100%',
              height: '100%',
              display: 'block',
              cursor: 'pointer',
            }}
          />
        </div>
        <div className='col-8' style={{
          paddingLeft: '15px',
          color: '#B0B0B0',
          fontSize: '14px',
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={exclamationInforIcon} 
               style={{
               width: '20px',  
               height: '20px',
               marginRight: '5px',}}
                       />
          <span>颜色图片示例及要求</span>
          </div>
          <ol style={{ paddingLeft: '20px', marginTop: '10px' }}>
            <li>颜色图片不建议太大，清晰饱和即可</li>
            <li>图片中不能出现与颜色无关的内容，例如文字或者水印</li>
            <li>颜色需充满整个图片，不能出现白色或其他颜色边框</li>
          </ol>
        </div>
      </div>
      </div>
    )
  }


  const renderSmallInputWithTitle = (width, title, value, onChange, isHex) => {
    return (
      <div style={{
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: 2,         
      }}>
         <label style={{ marginRight: 4 }}>{title} :</label> 
        <input
          type="text"
          style={{
            width: width,
            height: 26,
            border: '1px solid rgba(150, 150, 150, 1)',                   
            fontSize: 11,
            outline: 'none',
            marginLeft: 5
          }}
          value={value}
          onChange={onChange}
          pattern={isHex ? "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" : ""}
        />
      </div>
    )
  }

  const renderCMFImageInput = () => {
    return (
      <div style={{
        display: 'flex',
        position: 'relative',
      }}> 
        {renderImage(cmfImageInput)}
        {edittingMaskInBase64OfCMFInput && <img src={edittingMaskInBase64OfCMFInput} width={"100%"} style={{
                marginTop: 20,
                marginBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                position: 'absolute',
              }} />
            }
      </div>
      
    )
  }

  const renderButtonEditCMFImageInput = () => {
    return (
      <div className='row'>
        <div className='col-3 offset-9 button-upload-image-ref' onClick={() => setImageToEditOnTool(URL.createObjectURL(cmfImageInput))}>
          选择编辑区域
        </div>
      </div>
      
   )
  }

  const renderCMFPromptDialog = () => {
    return (
      <div className='col-12'>
        <PromptDialog
          title={"提示词输入"}
          value={cmfPromptText}
          onChange={(e) => setCmfPromptText(e.target.value)}
        />
      </div>
    )
  }


  const renderAICMFConfig = () => {
    return (
      <>
        {renderButtonToUploadCMFInputImage()}
        {cmfImageInput && renderCMFImageInput()}
        {cmfImageInput && renderButtonEditCMFImageInput()}
        {renderCMFPromptDialog()}
        {renderCheckboxToSelectAIColoring()}
        {isUsingAIColoring && renderSliderToSeCMFColorWeightWeight()}
        {isUsingAIColoring && renderDropdownToSelectColorTypeForAIColoring()}
        {isUsingAIColoring && isUsingPantone() && renderColorPanelSelectetion(PANTONE_SIMULATED_COLORS)}
        {isUsingAIColoring && isUsingRAL() && renderColorPanelSelectetion(RAL_SIMULATED_COLORS)}
        {isUsingAIColoring && isUsingCustomizedColor() && renderCustomizedColorSelection()}
        {isUsingAIColoring && isUsingColorUploadImage() && renderColorUploadImage()}
        {renderCheckboxToSlectAIMaterial()}
        {isUsingAIMaterial && renderSliderToSeCMFMaterialWeight()}
        {isUsingAIMaterial && selectedMaterialRefImage && renderSelectedMateriaImglRef()}
        {isUsingAIMaterial && (
                  <div className='row mt-4'>
                  <div className='col-4'>
                    <img
                      src={wooden} 
                      // onClick={()=>handleUploadCMFColorImageInput({ value: 'image' })}
                      alt='参考颜色图片'
                      style={{
                        width: '100%',
                        height: '100%',                     
                        cursor: 'pointer',
                      }}
                    />
                  </div>

                  <div className='col-8' style={{
                    paddingLeft: '15px',
                    color: '#B0B0B0',
                    fontSize: '14px',
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={exclamationInforIcon} 
                           style={{
                           width: '20px',  
                           height: '20px',
                           marginRight: '5px',}}
                       />
                      <span>材质图片示例及要求</span>
                    </div>
                    <ol style={{ paddingLeft: '20px', marginTop: '10px' }}>
                      <li>材质图片不建议太大，清晰饱和即可</li>
                      <li>图片中不能出现与颜色无关的内容，例如文字或者水印</li>
                      <li>材质需充满整个图片，不能出现白色或其他颜色边框</li>
                    </ol>
                  </div>
                </div>
          )}
      </>
    )
  }

  // Color Box component
  const ColorBox = ({ color, isSelected, onClick }) => {
    return (
      <div
        onClick={onClick}
        style={{
          backgroundColor: color.Hex,
          paddingLeft: "10px",
          width: "72px",
          height: "30px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          color: "black",
          cursor: "pointer",
          border: isSelected ? "3px solid #000" : "0,5px solid #fff", // Highlight selected color
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        {color.Name}
      </div>
    );
  }

  const saveEdittingImageMaks = async () => {
    const markedCanvas = await editImageMaskToolRef.current.getMarkedCanvas();
    const base64 = convertCanvasToBase64(markedCanvas);
    if (tab == CMF) setEdittingMaskInBase64OfCMFInput(base64);
    else setEdittingMaskInBase64(base64);
    setIsEditingImage(false);
    setShouldDisplayMaskToSelect(false);
  }

  const renderEditingImageComponent = () => {
    const imageToEdit = shouldDisplayMaskToSelect ? editingImageAutoSamImageResult : editingImage
    return (
      <div style={{
        display: isEditingImage ? 'block' : 'none',
      }}>

      <ImageEditingTool
        allImagesOfUser={allImagesOfUser}
        changeImageToDraw={changeImageToDraw}
        edittingMaskInBase64={tab == CMF ? edittingMaskInBase64OfCMFInput: edittingMaskInBase64}
        ref={editImageMaskToolRef} imageUrl={imageToEdit} imagePathOnly={removePathFromImage(imageToEdit)} 
        handleRemoveBackground={handleRemoveBackground} 
        handleAutoSam={handleAutoSam}
        isLoading={isRemovingBackground}
        saveMask={saveEdittingImageMaks}
        closeImageEditingTool={() => setIsEditingImage(false)}
        editingImageMasksAutoSam={editingImageMasksAutoSam}
        shouldDisplayMaskToSelect={shouldDisplayMaskToSelect}
      />
      </div>
    )
  }


  return (
    <div className='row col-12 generate-image-container'>
      {isAICarTab() ?
        renderAICARConfig() :

        <div className='col-4' style={{
        }}>
          {
            isEditingTab() ? renderAIEditingConfig()
              : isCMFTab() ? renderAICMFConfig() :

                <>
                  {isRenderingTab() && renderDraftSketchSelection()}
                  {renderSDModelSelection()}
                  {renderProductCategorySelection()}
                  {isSelectedOtherProductCategory() && renderOtherProductCategoryInput()}
                  {renderPromptDialog()}
                  {renderPromptWeight()}
                  {renderSelectedMaskImageRef()}
                  {renderCheckBoxForUploadImagesAndButtonUploadingImage()}
                  {selectedProductCategory.label !== OTHER_CATEGORY &&(
                    <>                                     
                     {renderButtonToSelectStyleOfGeneratedImage()}
                     {!isRenderingTab() && renderButtonToSelectViewAngleOfTheVehice()}
                     {!isRenderingTab() && renderButtonToSelectCameraViewOfGeneratedImage()}
                     {renderButtonToSelectScenarioOfGeneratedImage()}
                    </>

                  )}
                  
                </>
          }
        </div>
      }

      <div className='col-8' style={{
        paddingLeft: 30
      }}>
        {renderImageSettings()}
        {renderMainImage()}
        {renderCarImagesSlider()}
      </div>
      {renderModalToShowCannotUploadImageRef()}
      {renderCheckIConHiddenForLoading()}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleFileChange}
      />
      <input
        type="file"
        ref={fileInputToUploadMaterialImageRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleMaterialImageFileChange}
      />
      <input
        type="file"
        ref={fileInputToUploadCMFImageRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleCMFImageInputFileChange}
      />
      <input
        type="file"
        ref={fileInputToUploadMaskImageRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageMaskInputFileChange}
      />
      <input
        type="file"
        ref={fileInputToUploadCMFColorImageRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleCMFColorImageImageFileChange}
      />
      {renderSelectedImageFullSize()}
      {renderLoadingPage()}
      {renderEditingImageComponent()}
    </div>
  );




};




export default GenerateTab;