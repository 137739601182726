import React, { useState } from 'react';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getCurrentDomain = () => {
    const href =  window.location.href.split('/').slice(0, 3).join('/');
    if (href.endsWith('/')) {
      return href;
    }
    return href + '/';
  }

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await fetch( getCurrentDomain() + '/upload', {
          method: 'POST',
          body: formData,
          // Report upload progress
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            console.log('Upload progress:', progress);
            setUploadProgress(progress);
          }
        });

        if (!response.ok) {
          throw new Error('Upload failed');
        }

        const data = await response.json();
        console.log('Upload successful', data);
      } catch (error) {
        console.error('Error uploading file', error);
      }
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploadProgress > 0 && (
        <div>Uploading: {uploadProgress}%</div>
      )}
    </div>
  );
};

export default FileUpload;
