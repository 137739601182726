import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../images/new_logo.jpeg';
import './style.css';
import dropdownIcon from '../images/dropdown.svg';
import avatar from '../images/avatar.svg';
import change_password_icon from '../images/change_password_icon.svg';
import change_user_nickname_icon from '../images/change_user_nickname_icon.svg';
import logout_icon from '../images/logout_icon.svg';
import right_arrow_icon from '../images/right_arrow_icon.svg';
import axios from 'axios';
import { getCurrentDomain } from '../utils';
import BeatLoaderSpinner from './LoadingPage/BeatLoaderSpinner';
import closeIcon from '../images/close_button.svg';
import errorIcon from '../images/error_icon.svg';

const LINK_WITH_TITLE = [
  {
    link: 'home',
    title: '首页'
  },
  {
    link: 'library',
    title: '素材中心'
  },

]

const USER_MANAGEMENT =
{
  link: 'user_management',
  title: '账号管理 '
}


const Header = ({
  user,
  updateUserNickName,
}) => {
  let currentSelectedTab = window.location.pathname.split('/')[1];
  const USER_SETTING_MODAL_ID = 'user-setting-modal';
  const BUTTON_SHOW_SETTING_MENU = 'button-show-setting-menu';

  const [showUserSettingModal, setShowUserSettingModal] = useState(false);
  const [showChangeUserNickNameModal, setShowChangeUserNickNameModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newUserNickName, setNewUserNickName] = useState('');
  const [newChangePassword, setNewChangePassword] = useState('');
  const [newChangePasswordConfirm, setNewChangePasswordConfirm] = useState('');


  const closeModalChangePassword = () => {
    setShowChangePasswordModal(false);
  }
  const renderModalToChangeUserPassword = () => {
    return (
      <div className="user-management-modal" style={{
        zIndex: 100000,
      }}>
            <div className="user-management-modal-content">
                <div className="user-management-modal-header" style={{
                    marginBottom: 10
                }}>
                    请为该账号重置密码
                </div>
                <div className="user-management-modal-body">
                    <div className="user-management-modal-input" style={{
                        marginBottom: 10
                    }}>
                        <div className="user-management-modal-input-label">
                            账号
                        </div>
                        <div>
                            {user.userName}
                        </div>
                    </div>
                    <div className="user-management-modal-input">
                        <div className="user-management-modal-input-label">
                            新密码
                        </div>
                        <input className="user-management-modal-input-field"
                            placeholder="6位新密码"
                            value={newChangePassword}
                            onChange={(e) => setNewChangePassword(e.target.value)}
                        />
                    </div>
                    <div className="user-management-modal-input" style={{
                        position: 'relative'
                    }}>
                        <div className="user-management-modal-input-label">
                            确认新密码
                        </div>
                        <input
                            className="user-management-modal-input-field"
                            value={newChangePasswordConfirm}
                            onChange={(e) => setNewChangePasswordConfirm(e.target.value)}
                            placeholder="6位新密码"
                        />
                        {newChangePasswordConfirm != newChangePassword && renderErrorMessage("两次输入的密码不一致")}
                    </div>
                </div>
                <div className="user-management-modal-footer" style={{
                    marginTop: 40
                }}>
                    <div className="user-management-modal-button" onClick={changeUserPassword}>
                        确定
                    </div>
                </div>
                {renderButtonCloseModal(closeModalChangePassword)}
            </div>
        </div>
    )
}

const changeUserPassword = async () => {
  if (newChangePassword !== newChangePasswordConfirm || newChangePassword.length < 6) {
      //show error message
      return;
  }
  setLoading(true);
  const url = `${getCurrentDomain()}/change-password`;
  const data = {
      newPassword: newChangePassword,
  }
  try {
      const response = await axios.post(url, data);

      if (response.status === 200) {
          setShowChangePasswordModal(false);
          setNewChangePassword('');
          setNewChangePasswordConfirm('');
      }
          

  } catch (error) {
      //show create user error
      
  }
  finally {
      setLoading(false);
  }
}



  if (currentSelectedTab === "") {
    currentSelectedTab = LINK_WITH_TITLE[0].link;
  }

  const clickOutsideElement = (e, id) => {
    let target = e.target;
    while (target) {
      if (target.id === id) {
        return false;
      }
      target = target.parentNode;
    }
    return true;
  }


  useEffect(() => {
    const handleClick = (e) => {
      if (clickOutsideElement(e, USER_SETTING_MODAL_ID) && clickOutsideElement(e, BUTTON_SHOW_SETTING_MENU)) {
        setShowUserSettingModal(false);
      }
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    }
  }
    , [])

  const renderUserMenu = () => {
    return (
      <div className='user-menu' style={{
        color: 'black',
        fontSize: 16,
      }}>
        设置
      </div>
    )
  }

  const renderUserAvatar = () => {
    return (
      <div className='user-avatar' style={{
        marginLeft: 35
      }}>
        <img
          alt=""
          src={avatar}
          height="27"
          className="d-inline-block align-top user-avatar"
        />
      </div>
    )
  }

  const renderDropDownIcon = () => {
    return (
      <img
        alt=""
        src={dropdownIcon}
        height="8"
        className="d-inline-block align-top dropdown-icon"
        style={{
          paddingLeft: 10,
          cursor: 'pointer'
        }}
        id={BUTTON_SHOW_SETTING_MENU}
        onClick={() => setShowUserSettingModal(true)}
      />
    )
  }

  const renderUserAvatarAndName = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        alignItems: 'center',
        borderBottom: '1px solid #E3E3E3',
        width: 178
      }}>
        <img

          alt=""
          src={avatar}
          height="43"
          className="d-inline-block align-top user-avatar"
        />
        <div style={{
          fontSize: 16,
          color: 'black',
          marginTop: 12
        }}>
          {user ? user.userNickName : ''}
        </div>
      </div>
    )
  }

  const renderUserSettingMenuItem = (icon, title, onClick) => {
    return (
      <div className='user-setting-menu-item' style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        marginLeft: 40,
        marginBottom: 17,
        justifyContent: 'space-between',

      }}
        onClick={onClick}
      >
        <div style={{
          display: 'flex',
        }}>
          <div style={{
            width: 30
          }}>
            <img
              alt=""
              src={icon}
              height="21"
              className="d-inline-block align-top"
            />
          </div>

          <div style={{
            color: 'black',
            marginLeft: 10
          }}>
            {title}
          </div>

        </div>
        <img
          alt=""
          src={right_arrow_icon}
          height="10"
          className="d-inline-block align-top"
          style={{
            marginRight: 40,
            cursor: 'pointer'
          }}
        />
      </div>
    )
  }

  const renderUserSettingMenuOptions = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        paddingTop: 23,
      }}>
        {renderUserSettingMenuItem(change_user_nickname_icon, '修改昵称', () => setShowChangeUserNickNameModal(true))}
        {renderUserSettingMenuItem(change_password_icon, '修改密码', () => { setShowChangePasswordModal(true) })}
        {renderUserSettingMenuItem(logout_icon, '退出登录', logout)}
      </div>
    )
  }

  const logout = async () => {
    window.location.href = '/logout';
  }

  const renderUserSettingModal = () => {
    return (
      <div className='user-setting-modal' style={{
        display: showUserSettingModal ? 'flex' : 'none',
        flexDirection: 'column',
        position: 'absolute',
        right: 80,
        top: 50,
        backgroundColor: 'white',
        width: 216,
        height: 260,
        border: '1px solid #D8D8D8',
        boxShadow: '0px 0px 5px 0px #d3d3d3',
        zIndex: 1000000,
        alignItems: 'center',
      }}
        id={USER_SETTING_MODAL_ID}>
        {renderUserAvatarAndName()}
        {renderUserSettingMenuOptions()}
      </div>
    )
  }

  const renderChangeUserNickNameModal = () => {
    return (
      <div className="user-management-modal" style={{
        zIndex: 100000,
      }}>
        <div className="edit-user-nickname-wrappar">
          <div style={{
            marginTop: 45,
            fontSize: 24,
            fontWeight: 700,
            display: 'flex',
            marginBottom: 55
          }}>
            修改昵称
          </div>
          <div className="user-management-modal-input">
            <div className="user-management-modal-input-label">
              账号名称
            </div>
            <div className="user-management-modal-input-label">
              {user.userNickName}
            </div>
          </div>
          <div className="user-management-modal-input" style={{
            position: 'relative'
          }}>
            <div className="user-management-modal-input-label">
              请输入昵称
            </div>
            <input className="user-management-modal-input-field"
              placeholder=""
              value={newUserNickName}
              onChange={(e) => setNewUserNickName(e.target.value)}
            />
            {newUserNickName.length > 20 && renderErrorMessage("昵称字数已超过系统限制")}
          </div>
          <div className="user-management-modal-footer" style={{
            marginLeft: 120,
            marginTop: 20
          }}>
            <div className="user-management-modal-button" onClick={handleChangeUserNickName} style={{
              marginLeft: 0
            }}>
              确定
            </div>
          </div>
          {renderButtonCloseModal(closeModalChangeNickName)}
        </div>

      </div>
    )
  }

  const renderErrorMessage = (message) => {
    return (
      <div className='error-message-below-input'>
        <img src={errorIcon}  width={16} className='error-icon' />
        <div className='error-message' style={{
          marginLeft: 10
        }}>
          {message}
        </div>
      </div>
    )
  }

  const closeModalChangeNickName = () => {
    setShowChangeUserNickNameModal(false);
  }


  const renderButtonCloseModal = (close) => {
    return (
      <div className="user-management-modal-close" onClick={close}>
        <img className="user-management-modal-close-icon" src={closeIcon} />
      </div>
    )
  }

  const handleChangeUserNickName = async () => {
    if (newUserNickName === '' && newUserNickName.length > 20) {
      return;
    }
    setLoading(true);
    try {
      await axios.post(`${getCurrentDomain()}/change-nick-name`, {
        newNickName: newUserNickName
      });
      setNewUserNickName('');
      updateUserNickName(newUserNickName);
    }
    catch (e) {
      console.log(e);
    }
    finally {
      setLoading(false);
      setShowChangeUserNickNameModal(false);
    }
    
    
  }

  const renderLoadingPage = () => {
    return (
      <div className='col-12' style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        display: loading ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10000000000000
      }}>
        <BeatLoaderSpinner />
      </div>
    )
  }


  return (
    <div className='app-header row'>
      <div className='col-4 app_logo' style={{
        mixBlendMode: 'darken',
      }}>
        <img
          alt=""
          src={logo}
          height="27"
          className="d-inline-block align-top logo"
          // style={{
          //   background: 'purple',
          // }}
        />
      </div>
      <div className='col-6'>
        <Nav className="ml-auto app-nav">
          {
            LINK_WITH_TITLE.map((link, index) => {
              return (
                <Nav.Link key={index} href={getCurrentDomain() + link.link} className={currentSelectedTab === link.link ? 'app-nav-link active' : 'app-nav-link inactive'}>{link.title}</Nav.Link>
              )
            })
          }
          {
            user && user.Role == 'admin' &&
            <Nav.Link key={'user-manamgement'} href={getCurrentDomain() + USER_MANAGEMENT.link} className={currentSelectedTab === USER_MANAGEMENT.link ? 'app-nav-link active' : 'app-nav-link inactive'}>{USER_MANAGEMENT.title}</Nav.Link>
          }
        </Nav>
      </div>
      <div className='col-2' style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 80,
        alignItems: 'center'
      }}
      >
        {renderUserMenu()}
        {renderDropDownIcon()}
        {renderUserAvatar()}
        {renderUserSettingModal()}
      </div>
      {showChangeUserNickNameModal && renderChangeUserNickNameModal()}
      {showChangePasswordModal && renderModalToChangeUserPassword()}
      {renderLoadingPage()}
    </div>
  );
};

export default Header;
