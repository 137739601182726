import { Form } from "react-bootstrap";
import { APP_ACTIVE_COLOR } from "../../constant";

const CustomSelectWithoutTitle = ({ options, value, onChange }) => {
    const handleOnChange = (event) => {
        const selectedOption = JSON.parse(event.target.value);
        onChange(selectedOption);
    
    }
    return (
        <div className="">
             <Form.Select aria-label="Default select example" className='custom-select-without-title' onChange={handleOnChange}>
                {options.map((option, index) => (
                    <option 
                        key={index}
                        value={JSON.stringify(option)}
                        style={{
                            backgroundColor: value === option.value ? APP_ACTIVE_COLOR : 'inherit',
                        }}
                        selected={value.value === option.value}
                    >
                        {option.label}
                    </option>
                ))
                }
            </Form.Select>
        </div>
    )
}

export default CustomSelectWithoutTitle;