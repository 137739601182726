import React, { useState, useEffect, useRef } from 'react';
import './Slider.css';
import right_icon from '../../images/right_arrow_icon.svg';
import left_icon from '../../images/left.svg';
const Slider = ({ images, autoPlay = true, autoPlayTime = 3000, selectImage }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideInterval = useRef(null);
    const totalSlides = images.length;

    useEffect(() => {
        if (autoPlay) {
            startAutoPlay();
        }
        return () => {
            stopAutoPlay();
        };
    }, [currentIndex, autoPlay]);

    useEffect(() => {
        // auto scroll to the first image when the images change
        setCurrentIndex(0);
    }
    , [images.length]);

    const startAutoPlay = () => {
        stopAutoPlay();
        slideInterval.current = setInterval(() => {
            goToNextSlide();
        }, autoPlayTime);
    };

    const stopAutoPlay = () => {
        if (slideInterval.current) {
            clearInterval(slideInterval.current);
        }
    };

    const goToNextSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex < totalSlides - 1) {
                return prevIndex + 1;
            }
            return prevIndex; // Stay at the last image
        });
    };

    const goToPreviousSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex > 0) {
                return prevIndex - 1;
            }
            return prevIndex; // Stay at the first image
        });
    };


    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        
        }}>
            <button className="nav-button left" onClick={goToPreviousSlide} disabled={currentIndex === 0}>
                <img src={left_icon} alt="left" />
            </button>
            <div className="slider-container">
            <div className="slider-content">
                <div className="slider-track" style={{ transform: `translateX(-${currentIndex * 220}px)` }}>
                    {images.map((image, index) => (
                        <div key={index} className="slide" style={{ backgroundImage: `url(${image})` }} onClick={() => selectImage(image)}>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <button className="nav-button right" onClick={goToNextSlide} disabled={currentIndex >= totalSlides - 4}>
                <img src={right_icon} alt="right" />
        </button>
        </div>
    );
};

export default Slider;
