export const getCurrentDomain = () => {
  // return 'http://121.201.97.83:5001/'
  const href = window.location.href.split('/').slice(0, 3).join('/');
  if (href.endsWith('/')) {
    return href;
  }
  return href + '/';
}
export const convertToArrayFromDict = (dict) => {
  return Object.entries(dict).map(([key, value]) => {
    return {
      label: key,
      value: value,
    }
  })
}

export const convertArrayToArrayWithLabelAndValue = (array) => {
  return array.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
}

export const getImagePath = (image) => {
  const path = getCurrentDomain() + '/output_images?path=' + encodeURIComponent(image);
  return path;
}

export const getImagePathToDownload = (image) => {
  const path = getCurrentDomain() + 'download_image?path=' + encodeURIComponent(image) + '&time=' + new Date().getTime();
  return path;
}

export const removePathFromImage = (image) => {
  try {
    const data =  image.replace("/output_images?path=", "");
    //convert back to normal image path with / not % in url
    return data.replace(/%2F/g, '/').replace(getCurrentDomain(), '');
  } catch (error) {
    return image;
  }
  
}