import React, { useState, useEffect } from 'react';
import village from '../images/village.jpeg';
import GenerateTab from '../components/GenerateTab';
import { AI_AVERTISTMENT, AI_CAR, AI_CREATIVITY, AI_RENDERING, APP_ACTIVE_COLOR, CMF, EDITING, TAB_LIST } from '../constant';
import DrawingCanvas from './DrawingCanvas';
import UploadImages from './UploadImages';
import imageUrl from '../images/default_car.png';
import Header from '../components/Header';
import Library from '../components/Library';
import UserManagement from '../components/UserManagement';
import axios from 'axios';
import { getCurrentDomain } from '../utils';
import GenerateCar from '../components/GenerateCar';


function ImagesGenerators({
  isLibrary = false,
  isUserManagement = false,
  isAdminAccessUserPage = false,
  activeTabInit = AI_CREATIVITY,
}) {
  const [activeTab, setActiveTab] = useState(activeTabInit);
  const [selectedImage, setSelectedImage] = useState(village);
  const [isLibraryState, setIsLibraryState] = useState(isLibrary);
  const [isUserManagementState, setIsUserManagementState] = useState(isUserManagement);
  const [user, setUser] = useState({});

  useEffect(() => {
    const loadUser = async () => {
      const res = await axios.get(getCurrentDomain() + '/current-user');
      setUser(res.data.user);
    }
    loadUser();

  }, []);
    

  




  const handleTabSelect = (key) => {
    window.history.pushState({}, '', '/');
    setActiveTab(key);
  }

  const onImageSelect = (image) => {
    setSelectedImage(image);
  };

  const renderLeftMenu = () => {
    
    const activeTabsOfUsers = user && user.Tabs ? user.Tabs : `${AI_CREATIVITY},${AI_RENDERING},${EDITING},${AI_AVERTISTMENT},${CMF}`;
    
    return (
      <div className='left-menu' style={{
        width: '100px'
      }}>
        {activeTabsOfUsers.includes(AI_CREATIVITY) && renderMenuItem(["AI",'创意'], AI_CREATIVITY)}
        {activeTabsOfUsers.includes(AI_RENDERING) &&renderMenuItem(["AI", "渲染"], AI_RENDERING)}
        {activeTabsOfUsers.includes(CMF) && renderMenuItem(["CMF", "设计"], CMF)}
        {activeTabsOfUsers.includes(EDITING) && renderMenuItem(["图像", "编辑"], EDITING)}
        {activeTabsOfUsers.includes(AI_AVERTISTMENT) && renderMenuItem(["AI","营销"], AI_AVERTISTMENT)}
        {activeTabsOfUsers.includes(AI_CAR) && renderMenuItem(["AI","汽车"], AI_CAR)}
      </div>
    )
  }

  const renderMenuItem = (titles, key) => {
    return (
      <div className='left-menu-item' onClick={() => handleTabSelect(key)} style={{
        backgroundColor: activeTab === key ? APP_ACTIVE_COLOR : 'inherit',
        color: activeTab === key ? 'white' : 'black',
      }}>
        {titles.map((title, index) => {
          return (
            <div key={index} className='left-menu-item-title'>
              {title}
            </div>
          )
        })}
      </div>
    )
  }

  const updateUserNickName = (nickName) => {
    setUser({
      ...user,
      userNickName: nickName
    })
  }

  const renderContent = () => {
    return (
      <div className="" style={{
        width: '100%',
        overflowX: 'hidden',
        maxWidth: '2048px',
        minHeight: '100vh',
      }}>
        <Header  user={user} updateUserNickName={updateUserNickName}/>
      
        
      <div className='' style={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
      }}>
        {renderLeftMenu()}
        {renderContentOfTab()}
      </div>
    </div>
    )
  }

  const isSelectedImageGenerateTab = () => {
    return activeTab === TAB_LIST[0];
  }

  const isSelectedAIRendering = () => {
    return activeTab === TAB_LIST[1];
  }

  const isSelectedDrawImageTab = () => {
    return activeTab === TAB_LIST[2];
  }

  const renderGenerateTab = () => {
    return (
        <GenerateTab onImageSelect={onImageSelect} />
    )
  }

  const renderAIRenderingTab = () => {
    return (
         <GenerateTab isRenderingTab={true} />  
    )
  }

  const renderDrawImagetab = () => {
    return (
      <div className='col-11'>
        <DrawingCanvas />  
      </div>
    )
  }

  const isSelectedAIAdvertising = () => {
    return activeTab === TAB_LIST[3];
  }

  const renderAIAdvertisingTab = () => {
    return (
        <UploadImages isRenderingTab={false} />  
    )
  }

  const renderLibrary = () => {
    return (
      <Library isAdminAccessUserPage={isAdminAccessUserPage} />
    );
  }

  const renderUserManagement = () => {
    return (
      <UserManagement />
    );
  }


  const renderMainContentBasedOnURL = () => {
    if (activeTab != null || !isLibrary && !isUserManagement)
      if (activeTab === AI_AVERTISTMENT) {
        return renderAIAdvertisingTab();
      }
      // else if (activeTab === AI_CAR) {
      //   return <GenerateCar />;
      // }
      else return <GenerateTab tab={activeTab} changeTab={setActiveTab} /> 
      
    else if (isLibrary)
      return renderLibrary()
    return  renderUserManagement()
  }

  const renderContentOfTab = () => { 
    return (
      <div style={{
          padding: '5px',
          backgroundColor: '#F1F1F1',
          flex:1,
          display: 'flex',
        }}>
        {renderMainContentBasedOnURL()}
      </div>
    )
  }

  return renderContent();
}

export default ImagesGenerators;
