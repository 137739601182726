import { useEffect, useState } from 'react';
import checkedIcon from '../images/checked.svg'
import uncheckedIcon from '../images/unchecked.svg';
import default_car from '../images/default_car.png';

import './style.css'
import { APP_ACTIVE_COLOR } from '../constant';
import CustomSelect from './CustomSelect';
import PromptDialog from './PromptDialog';
import ButtonListSelections from './ButtonListSelections';
import NumberAdjustment from './NumberAdjustment/NumberAdjustment';
import CustomSelectWithoutTitle from './CustomSelect/CustomSelectWithoutTitle';
import RangeSlider from 'react-bootstrap-range-slider';
import { saveAs } from 'file-saver'


import { Col } from 'react-bootstrap';
import ImagesSlider from './ImagesSlider/ImagesSlider';
import BeatLoaderSpinner from './LoadingPage/BeatLoaderSpinner';
import { RangeSliderWithCustomDisplay } from './RangeSliderWithCustomDisplay/RangeSliderWithCustomDisplay';
import InlineInputWithTitle from './InlineInputWithTitle/InlineInputWithTitle';



const car_model_dict = {
  "2023款 日产奇骏 e-POWER": "2023款 日产奇骏 e-POWER",
};
const modes = ['txt2img', 'img2img', 'inpaint'];

const styles_of_generated_image_dict = {
  "摄影风格": "摄影风格",
  "插画风格": "插画风格",
  "黑白风格": "黑白风格",
  "科技风格": "科技风格",
  "古典风格": "古典风格",
  "极简风格": "极简风格",
}


// const VIEW_ANGLES_OF_THE_VEHICLE = [
//   "正面视角",
//   "侧面视角",
//   "背面视角",
//   "45度右侧视角",
//   "45度左侧视角",
//   "俯视视角",
//   "仰视视角"
// ]

// const SCENE_OF_GENERATED_IMAGE = [
//   "城市",
//   "乡村",
//   "海边",
//   "公路",
//   "森林",
//   "水面",
//   "建筑",
//   "舞台"
// ]

// const WEATHER_OF_GENERATED_IMAGE = [
//   "正午",
//   "清晨",
//   "黄昏",
//   "夜晚",
//   "雨天",
//   "雪天"
// ]

export const CAR_AI_IMAGE_RESOLUTIONS = [
  // {
  //   label: "4096x4096",
  //   value: "4096x4096",
  // },
  {
    label: "2048x2048",
    value: "2048x2048",
  },
  // {
  //   label: "1024x1024",
  //   value: "1024x1024",
  // }
]

const weather_dict = {
  "正午": "Daytime",
  "清晨": "Morning",
  "黄昏": "Sunset",
  "夜晚": "Evening",
  "雨天": "Wet floor",
  "雪天": "Snow",
  "晴天": "Clear sky",
  "阴天": "Cloudy",
}

const background_dict = {
  "城市": "In a City, beautiful buildings, beautiful view",
  "乡村": "In a Village, beautiful view",
  "海边": "Near the sea, beautiful beach",
  "公路": "on a highway, mountain at the back, near the ocean, beautiful, scenery",
  "森林": "In the jungle, beautiful lighting, beautiful scenery",
  "水面": "Beside a river, beautiful scenery",
  "建筑": "Beautiful buildings"
}

const angle_dict = {
  "正面视角":"front",
  "左侧面视角": "left",
  "右侧面视角": "right",
  "背面视角": "back",
  "45度前右侧视角": "front_right",
  "45度前左侧视角": "front_left",
  "45度后右侧视角": "back_right",
  "45度后左侧视角": "back_left",
}

const angle_dict_display = {
  "front": "front",
  "left": "left side",
  "right": "right side",
  "back": "back",
  "front_right": "front right three quarter",
  "front_left": "front left three quarter",
  "back_right": "back right three quarter",
  "back_left": "back left three quarter",
}

const sd_model_dict = {
  "东风日产写实风格大模型.safetensors": "东风日产写实风格大模型.safetensors",
}

const START_GENERATING_TEXT = '开始生成';
const REMAINING_IMAGES_TEXT = '剩余图片: ';

const convertToArrayFromDict = (dict) => {
  return Object.entries(dict).map(([key, value]) => {
    return {
      label: key,
      value: value,
    }
  })
}

const convertArrayToArrayWithLabelAndValue = (array) => {
  return array.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
}

const NISSAN_X_TRAIL = "a white Nissan X-trail";
const GS3_WHITE = "a white gs3 car";
export const CAR_AI_CAR_DEFAULT_PROMPT_TEXT = "car photography, " + GS3_WHITE + " , on a highway, mountain at the back, near the ocean, beautiful scenery, realistic, Cinematic, Cinematic Shot, cinematic lighting, 8k uhd, high quality, film grain, Fujifilm XT3";

export const KSAMPLER_NAMES_LIST = ["euler", "euler_ancestral", "heun", "heunpp2","dpm_2", "dpm_2_ancestral","lms", "dpm_fast", "dpm_adaptive", 
"dpmpp_2s_ancestral", "dpmpp_sde", "dpmpp_sde_gpu","dpmpp_2m", "dpmpp_2m_sde", "dpmpp_2m_sde_gpu", 
"dpmpp_3m_sde", "dpmpp_3m_sde_gpu", "ddpm", "lcm"]
export const SCHEDULER_NAMES_LIST = ["normal", "karras", "exponential", "sgm_uniform", "simple", "ddim_uniform"]

export const CAR_AI_VIEW_ANGLES_OF_THE_VEHICLE = convertToArrayFromDict(angle_dict);
export const CAR_AI_SCENE_OF_GENERATED_IMAGE = convertToArrayFromDict(background_dict);
export const CAR_AI_WEATHER_OF_GENERATED_IMAGE = convertToArrayFromDict(weather_dict);
export const CAR_AI_STYLES_OF_GENERATED_IMAGE = convertToArrayFromDict(styles_of_generated_image_dict);
export const  CAR_AI_CAR_MODELS = convertToArrayFromDict(car_model_dict);
export const CAR_AI_SD_MODELS = convertToArrayFromDict(sd_model_dict);
const ONE_MIN_IN_MILLISECONDS = 60000;
const THUBMMAIL_PREFIX = 'thumbnail_';
export const CAR_AI_SAMPLER_NAMES = convertArrayToArrayWithLabelAndValue(KSAMPLER_NAMES_LIST);
export const CAR_AI_SCHEDULER_NAMES = convertArrayToArrayWithLabelAndValue(SCHEDULER_NAMES_LIST);

const GenerateCar = ({ onImageSelect }) => {
  const [CAR_AI_prompt, CAR_AI_setPrompt] = useState(CAR_AI_CAR_DEFAULT_PROMPT_TEXT);
  const [CAR_AI_cfgScale, CAR_AI_setCfgScale] = useState(2.0);
  const [CAR_AI_steps, CAR_AI_setSteps] = useState(7);
  const [CAR_AI_selectedMode, CAR_AI_setSelectedMode] = useState('txt2img');

  const [CAR_AI_selectedCarModel, CAR_AI_setSelectedCarModel] = useState(CAR_AI_CAR_MODELS[0]);
  const [CAR_AI_selectedStyleOfGeneratedImage, CAR_AI_setSelectedStyleOfGeneratedImage] = useState(CAR_AI_STYLES_OF_GENERATED_IMAGE[0]);
  const [CAR_AI_selectedViewAngleOfTheVehicle, CAR_AI_setSelectedViewAngleOfTheVehicle] = useState(CAR_AI_VIEW_ANGLES_OF_THE_VEHICLE[0]);
  const [CAR_AI_selectedSceneOfGeneratedImage, CAR_AI_setSelectedSceneOfGeneratedImage] = useState(CAR_AI_SCENE_OF_GENERATED_IMAGE[0]);
  const [CAR_AI_selectedWeatherOfGeneratedImage, CAR_AI_setSelectedWeatherOfGeneratedImage] = useState(CAR_AI_WEATHER_OF_GENERATED_IMAGE[0]);
  const [CAR_AI_imageResolution, CAR_AI_setImageResolution] = useState(CAR_AI_IMAGE_RESOLUTIONS[0]);
  const [CAR_AI_selectedSDModel, CAR_AI_setSelectedSDModel] = useState(CAR_AI_SD_MODELS[0]);

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(default_car);


  const [numberOfImagesToGenerate, setNumberOfImagesToGenerate] = useState(1);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatingProgress, setGeneratingProgress] = useState(0);
  const [displayTextOfButtonStartGenerating, setDisplayTextOfButtonStartGenerating] = useState(START_GENERATING_TEXT);
  const [CAR_AI_isSelectedPreciselyControl, CAR_AI_setISelectedPreciselyControl] = useState(false);
  const [CAR_AI_proportionOfTheBodyToTheOverallScreen, CAR_AI_setProportionOfTheBodyToTheOverallScreen] = useState(50);
  const [CAR_AI_sd_models, CAR_AI_setSDModels] = useState(CAR_AI_SD_MODELS);
  const [CAR_AI_samplerNames, CAR_AI_setSamplerNames] = useState(CAR_AI_SAMPLER_NAMES);
  const [CAR_AI_schedulerNames, CAR_AI_setSchedulerNames] = useState(CAR_AI_SCHEDULER_NAMES);
  const [CAR_AI_selectedSamplerName, CAR_AI_setSelectedSamplerName] = useState({
    label: "dpmpp_sde",
    value: "dpmpp_sde"
  });
  const [CAR_AI_selectedSchedulerName, CAR_AI_setSelectedSchedulerName] = useState({
    label: "karras",
    value: "karras"
  });

  useEffect(() => {
    setTimeout(() => {
      CAR_AI_setISelectedPreciselyControl(true);
    }, 200);
    fetch(getCurrentDomain() + 'get_all_images')
    .then(res => res.json())
    .then(data => {
      if(data.all_images.length > 0) {
        setSelectedImage(data.all_images[0]);
      }
      setGeneratedImages(data.all_images);

      
    }).catch
    (e => {
      console.log("error", e);
      setGeneratedImages([default_car, default_car, default_car, default_car, default_car, default_car, default_car, default_car])
    });

    fetch(getCurrentDomain() + 'get_all_models')
    .then(res => res.json())
    .then(data => {
      console.log("data", data);
      CAR_AI_setSDModels(data.all_models.map((model) => {
        return {
          label: model,
          value: model,
        }
      }));
    })
    .catch(e => {
      console.log("error", e);
    });
  },[]);


  const getNewPromptFromBackgroundDictAndWeather = () => {
    console.log("CAR_AI_selectedSceneOfGeneratedImage", CAR_AI_selectedSceneOfGeneratedImage);
    return `Car photography, a white Nissan X-trial, ${CAR_AI_selectedSceneOfGeneratedImage.value}, ${CAR_AI_selectedWeatherOfGeneratedImage.value}, realistic, Cinematic shot, Cinematic lighting, 8k uhd, high quality, film grain, Fujifilm XT3`;
  }


  useEffect(() => {
    CAR_AI_setPrompt(getNewPromptFromBackgroundDictAndWeather());
  }, [CAR_AI_selectedSceneOfGeneratedImage,  CAR_AI_selectedWeatherOfGeneratedImage])


  const startNewIntervalToUploadProgress = () => {
    let progress = 0;
    const timeInSeconds = CAR_AI_isSelectedPreciselyControl ? 2.5 : 0.5;
    const ESTIMATE_TIME_TO_FINISH_IN_MILLISECOND = 1000 * 60 * timeInSeconds;
    const interval = setInterval(() => {
      progress += 1;
      if (progress > generatingProgress) {
        setGeneratingProgress(progress);
      }
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, ESTIMATE_TIME_TO_FINISH_IN_MILLISECOND / 100);
    return interval;
  }
      
  const updateTextOfButtonStartGenerating = (text) => {
    setDisplayTextOfButtonStartGenerating(text);
  }

  const getCurrentDomain = () => {
    const href =  window.location.href.split('/').slice(0, 3).join('/');
    if (href.endsWith('/')) {
      return href;
    }
    return href + '/';
  }
  const handleGenerate = async () => {
    console.log(CAR_AI_selectedSDModel)
    setLoading(true);
    setGeneratingProgress(0);
    let allImages = [...generatedImages];
    const  body = JSON.stringify({
      "background": CAR_AI_selectedSceneOfGeneratedImage.value,
      "weather": CAR_AI_selectedWeatherOfGeneratedImage.value,
      "angle": CAR_AI_selectedViewAngleOfTheVehicle.value,
      "style": CAR_AI_selectedStyleOfGeneratedImage.value,
      "resolution": CAR_AI_imageResolution.value,
      "cfg_scale": CAR_AI_cfgScale,
      "number_of_images": 1,
      "car_model": CAR_AI_selectedCarModel.value,
      "sd_model": CAR_AI_selectedSDModel.value,
      "prompt": CAR_AI_prompt,
      "proportionOfTheBodyToTheOverallScreen": CAR_AI_proportionOfTheBodyToTheOverallScreen,
      "isSelectedPreciselyControl": CAR_AI_isSelectedPreciselyControl,
      "sampler_name": CAR_AI_selectedSamplerName.value,
      "scheduler": CAR_AI_selectedSchedulerName.value,
      "steps": CAR_AI_steps,
    });
    try {
      const numberOfImagesToGenerateWhenClick = parseInt(numberOfImagesToGenerate);
      for (let i = 0; i < numberOfImagesToGenerateWhenClick; i++) {
        const updateProgressInterval = startNewIntervalToUploadProgress();
        try {
          const numberOfRemainingImages = numberOfImagesToGenerateWhenClick - i;
          updateTextOfButtonStartGenerating(`剩余图片: ${numberOfRemainingImages}`);
          const result = await fetch( getCurrentDomain() + 'generate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: body,
            timeout: ONE_MIN_IN_MILLISECONDS * 30,
          });
          clearInterval(updateProgressInterval);
          setGeneratingProgress(100);
          const data = await result.json();
          const images = data.path_of_new_files;
          allImages = [...images, ...allImages]
          console.log("allImages", allImages);
          setGeneratedImages([...allImages]);
          setSelectedImage(allImages[0]); 
        }
        catch (e) {
          console.log(e);
          setGeneratingProgress(0);
          clearInterval(updateProgressInterval);
        }  
      }
    }
    catch (e) {
      console.log("error", e);
      if (allImages.length > 0) {
        setSelectedImage(allImages[0]);
      } else {
        setSelectedImage(default_car);
      
      }
    }
    setLoading(false);
    setGeneratingProgress(0);
    updateTextOfButtonStartGenerating(START_GENERATING_TEXT);
      
  };



  const AI_CAR_renderButtonSelectMode = (label, mode, additionalClass = "") => {
    return (
      <div
        className={`col-3 generate-button-select-mode ${additionalClass}`}
        onClick={() => CAR_AI_setSelectedMode(mode)}
        style={{
          backgroundColor: CAR_AI_selectedMode === mode ? APP_ACTIVE_COLOR : 'inherit',
          color: CAR_AI_selectedMode === mode ? 'white' : 'black'
        }}
      >
        {label}
      </div>
    )
  }

  const AI_CAR_renderButtonsSelectMode = () => {
    return (
      <div className='row col-12' style={{
        marginTop: '30px',
        height: '50px',
      }}>
        {AI_CAR_renderButtonSelectMode('文生图', 'txt2img', 'offset-2')}
        {AI_CAR_renderButtonSelectMode('图生图', 'img2img', '')}
      </div>
    )
  }


  const AI_CAR_renderSDModelSelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='风格模型选择（整体设计风格)' options={CAR_AI_sd_models} value={CAR_AI_selectedSDModel} onChange={CAR_AI_setSelectedSDModel} />
      </div>

    )
  }


  const AI_CAR_renderCarModelSelection = () => {
    return (
      <div className='col-12'>
        <CustomSelect title='车型模型选择（造型精准控制)' options={CAR_AI_CAR_MODELS} value={CAR_AI_selectedCarModel} onChange={CAR_AI_setSelectedCarModel} />
      </div>

    )
  }

  const getPrompt = () => {
    return `${angle_dict_display[CAR_AI_selectedViewAngleOfTheVehicle.value]}, ${CAR_AI_selectedSceneOfGeneratedImage.value}, ${CAR_AI_selectedWeatherOfGeneratedImage.value}`
  }

  const AI_CAR_renderPromptDialog = () => {
    return (
      <div className='col-12'>
        <PromptDialog
          title={"提示词输入"}
          value={CAR_AI_prompt}
          onChange={(e) => CAR_AI_setPrompt(e.target.value)}
        />
      </div>
    )
  }

  const AI_CAR_renderButtonToSelectStyleOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"图像风格"}
          selections={CAR_AI_STYLES_OF_GENERATED_IMAGE}
          onClick={(selection) => CAR_AI_setSelectedStyleOfGeneratedImage(selection)}
          selected={CAR_AI_selectedStyleOfGeneratedImage}
          disabled={true}
        />
      </div>
    )
  }

  const AI_CAR_renderButtonToSelectViewAngleOfTheVehice = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"角度/视角"}
          selections={CAR_AI_VIEW_ANGLES_OF_THE_VEHICLE}
          onClick={(selection) => CAR_AI_setSelectedViewAngleOfTheVehicle(selection)}
          selected={CAR_AI_selectedViewAngleOfTheVehicle}
          disabled={!CAR_AI_isSelectedPreciselyControl}
        />
      </div>
    )
  }

  const AI_CAR_renderButtonToSelectSenceOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"场景选择"}
          selections={CAR_AI_SCENE_OF_GENERATED_IMAGE}
          onClick={(selection) => CAR_AI_setSelectedSceneOfGeneratedImage(selection)}
          selected={CAR_AI_selectedSceneOfGeneratedImage}
          disabled={!CAR_AI_isSelectedPreciselyControl}
        />
      </div>
    )
  }

  const AI_CAR_renderButtonToSelectWeatherOfGeneratedImage = () => {
    return (
      <div className='col-12'>
        <ButtonListSelections
          title={"天气选择"}
          selections={CAR_AI_WEATHER_OF_GENERATED_IMAGE}
          onClick={(selection) => CAR_AI_setSelectedWeatherOfGeneratedImage(selection)}
          selected={CAR_AI_selectedWeatherOfGeneratedImage}
          disabled={!CAR_AI_isSelectedPreciselyControl}
        />
      </div>
    )
  }

  const AI_CAR_renderNumberOfImagesToGenerate = () => {
    return (
      <div className='col-3 row button-set-number-of-images-wrapper'>
        <div className='col-4' style={{
          padding: 0,
          color: 'white',
        }}>
          图片数量
        </div>
        <div className='col-6' style={{
          padding: 0
        }}>
          <NumberAdjustment
            value={numberOfImagesToGenerate}
            onChange={setNumberOfImagesToGenerate}
            min={1}
            max={2}
            step={1}
          />
        </div>

      </div>
    )
  }


  const AI_CAR_renderImagesResolutionsSetting = () => {
    return (
      <div className='col-5 offset-sm-2 images-resolution-settings-wrapper'>
        <div className='col-3 col-sm-3' style={{
          padding: 0
        }}>
          图片大小
        </div>
        <div className='col-8 col-sm-8' style={{
          padding: 0
        }}>
          <CustomSelectWithoutTitle
            options={CAR_AI_IMAGE_RESOLUTIONS}
            value={CAR_AI_imageResolution}
            onChange={CAR_AI_setImageResolution}
          />
        </div>
      </div>
    )
  }


  const AI_CAR_renderCGFScaleSetting = () => {
    return (
      <div className='col-4 images-resolution-settings-wrapper row'>
        <Col xs={{
          span: 3,
          offset: 0
        }} 
        sm={{
          span: 3,
          offset: 0
        }}

        style={{
          padding: 0
        }}
        
        >
          重绘权重
        </Col>
        <Col xs={8} sm={{
          span: 7,
          offset: 0
        }}>
          <RangeSlider
            value={CAR_AI_cfgScale}
            // onChange={changeEvent => setCfgScale(changeEvent.target.value)}
            tooltip='on'
            min={0}
            max={20}
            variant='secondary'
            tooltipStyle={{
              color: 'red',
            }}
            tooltipPlacement='top'
          />
        </Col>
      </div>
    )
  }

  const renerButtonStartGenerating = () => {
    return (
      <div className='col-2 button-start-generating-wrapper'>
        <div
          className='button-start-generating'
          onClick={loading ? null : handleGenerate}
          style={{
            color: loading ? 'white' : '#E60269'
          }}
        >
          {loading && AI_CAR_renderProgressBackground()}
          <div style={{
            zIndex: 1000
          }}>
            {displayTextOfButtonStartGenerating}
          </div>

        </div>
      </div>
    )
  }

  const AI_CAR_renderImageSettings = () => {
    return (
      <div className='row col-12 image-settings-wrapper' style={{
        width: '100%',
      }}>
        {AI_CAR_renderNumberOfImagesToGenerate()}
        {AI_CAR_renderImagesResolutionsSetting()}
        {/* {AI_CAR_renderCGFScaleSetting()} */}
        {renerButtonStartGenerating()}
      </div>
    )
  }

  const AI_CAR_renderButtonSaveToLibrary = () => {
    return AI_CAR_renderButtonForSelectedImage('保存到素材中心');
  }

  const AI_CAR_renderButtonForSelectedImage = (text, action, isEnabled) => {
    return (
      <div
        className={'selected-image-action-button ' + (isEnabled ? 'enabled' : 'disabled')}
        onClick={action}
      >
        {text}
      </div>
    )
  }

  const downloadImage = async () => {
    try {
      const fullSizeFileName = selectedImage.replace(THUBMMAIL_PREFIX, '');
      const fileUrl = getCurrentDomain() + "/" + fullSizeFileName;
      const fileName = fullSizeFileName.split('/').pop();
      saveAs(fileUrl, fileName) // Put your image URL here.
    } catch (ex) {
      console.log(ex);
    }
    
  }


  const AI_CAR_renderButtonDownloadImage = () => {
    return AI_CAR_renderButtonForSelectedImage('下载到本地', downloadImage, true);
  }

  const AI_CAR_renderButtonSendToEdit = () => {
    return AI_CAR_renderButtonForSelectedImage('发送到图片编辑');
  }

  const AI_CAR_renderMainImage = () => {
    return (
      <div className='row col-12 main-image-wrapper'>
        <div className='col-12 selected-image'>
          {loading && selectedImage == null ? 
          <div className='loading-div'>
            <BeatLoaderSpinner /> 
          </div>
          : <img src={selectedImage} />}
        </div>
        <div className='col-12 selected-button-control-list'>
          {AI_CAR_renderButtonSaveToLibrary()}
          {AI_CAR_renderButtonDownloadImage()}
          {AI_CAR_renderButtonSendToEdit()}
        </div>
      </div>
    )
  }

  const AI_CAR_renderProgressBackground = () => {
    return (
      <div className='progress-background' style={{
        width: `${generatingProgress}%`,
      }} />
    )
  }

  const AI_CAR_renderCarImagesSlider = () => {
    return (
      <div className='row col-12 car-images-slider-wrapper'>
        <div className='col-10 offset-1'>
          {
            generatedImages.length > 0 &&
            <ImagesSlider
              images={generatedImages}
              onSelectImage={setSelectedImage}
              selectedImage={selectedImage}
            />
          }
        </div>

      </div>
    )
  }

  const AI_CAR_renderTitleWithNewLine = (title, disabled) => {
    return (
      <div className='col-12'>
        <div className='row list-of-button-selections'>
        <div className="title" style={{
          color: disabled ? 'inherit' : '#C8C8C8'
        }}>
            {title}
        </div>
        </div>
        
      </div>
    )
  }

  const toggleSelectedPreciselyControl = () => {
    const newIsSelectedPreciselyControl = !CAR_AI_isSelectedPreciselyControl;
    CAR_AI_setISelectedPreciselyControl(newIsSelectedPreciselyControl);
    if (!newIsSelectedPreciselyControl) {
      CAR_AI_setProportionOfTheBodyToTheOverallScreen(0);
      CAR_AI_setPrompt("")
    } else {
      CAR_AI_setPrompt(getNewPromptFromBackgroundDictAndWeather())
    }
    
  }

  const AI_CAR_renderPreciselyControlProducAppearanceAndDetails = () => {
    const disabled = false;
    const title = "精准控制 产品外造型及细节";
    return (
      <div className='col-12'>
        <div className='title-with-checkbox'>
          <div className="title" style={{
            color: disabled ? 'inherit' : '#C8C8C8'
          }}>
              {title}
          </div>
          <div className='custom-checkbox' onClick={toggleSelectedPreciselyControl}>
            <img src={CAR_AI_isSelectedPreciselyControl ? checkedIcon : uncheckedIcon} />
          </div>
        </div>
        
      </div>
    )
  }

  const AI_CAR_renderProportionOfTheBodyToTheOverallScreen = () => {
    return (
      <>
        {AI_CAR_renderTitleWithNewLine("车身占整体画面比例", !CAR_AI_isSelectedPreciselyControl)}
        {AI_CAR_renderSliderToAdjustProportionOfTheBodyToTheOverallScreen()}
      </>
    )
  }

  const AI_CAR_renderSliderToAdjustProportionOfTheBodyToTheOverallScreen = () => {
    return (
      <div className='col-12'>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
            <RangeSliderWithCustomDisplay 
              value={CAR_AI_proportionOfTheBodyToTheOverallScreen}
              setValue={CAR_AI_setProportionOfTheBodyToTheOverallScreen}
              max={100}
              min={0}
              maxDisplay={1}
              minDisplay={0}
              step={CAR_AI_isSelectedPreciselyControl ? 50 : 1}
              disabled={!CAR_AI_isSelectedPreciselyControl}
            />
          </div>
         
      </div>
    )
  }

  const AI_CAR_renderCfgInput = () => {
    return (
      <InlineInputWithTitle 
        title={"CFG"}
        value={CAR_AI_cfgScale}
        onChange={CAR_AI_setCfgScale}
        min={0}
        max={100}
      />
    )
  }

  const AI_CAR_renderStepsInput = () => {
    return (
      <InlineInputWithTitle 
        title={"采样步数"}
        value={CAR_AI_steps}
        onChange={CAR_AI_setSteps}
        min={0}
        max={200}
        numberType='integer'
      />
    )
  }

  const AI_CAR_renderSamplerNameSelect = () => {
    return (
      <CustomSelect
        title='采样算法'
        options={CAR_AI_samplerNames}
        value={CAR_AI_selectedSamplerName}
        onChange={CAR_AI_setSelectedSamplerName}
      />
    )
  }

  const AI_CAR_renderSchedulerNameSelect = () => {
    return (
      <CustomSelect
        title='调度器'
        options={CAR_AI_schedulerNames}
        value={CAR_AI_selectedSchedulerName}
        onChange={CAR_AI_setSelectedSchedulerName}
      />
    )
  }



  return (
    <div className='row col-12 generate-image-container'>
      <div className='col-4'>
        {AI_CAR_renderButtonsSelectMode()}
        {AI_CAR_renderSDModelSelection()}
        {AI_CAR_renderCarModelSelection()}
        {AI_CAR_renderPromptDialog()}
        {AI_CAR_renderTitleWithNewLine("车型外观控制")}
        {AI_CAR_renderPreciselyControlProducAppearanceAndDetails()}
        {AI_CAR_renderProportionOfTheBodyToTheOverallScreen()}
        {AI_CAR_renderCfgInput()}
        {AI_CAR_renderStepsInput()}
        {AI_CAR_renderSamplerNameSelect()}
        {AI_CAR_renderSchedulerNameSelect()}
        {AI_CAR_renderButtonToSelectStyleOfGeneratedImage()}
        {AI_CAR_renderButtonToSelectViewAngleOfTheVehice()}
        {AI_CAR_renderButtonToSelectSenceOfGeneratedImage()}
        {AI_CAR_renderButtonToSelectWeatherOfGeneratedImage()}
      </div>
      <div className='col-8'>
        {AI_CAR_renderImageSettings()}
        {AI_CAR_renderMainImage()}
        {AI_CAR_renderCarImagesSlider()}
      </div>
    </div>
  );


};




export default GenerateCar;