import React from 'react';
import './styles.css';
import { APP_ACTIVE_COLOR } from '../../constant';
import IncreaseButton from '../../images/increase.png';
import DecreaseButton from '../../images/decrease.png';

const NumberAdjustment = ({
    value,
    onChange,
    min,
    max,
    step,
}) => {
    return (
        <div className='number-adjustment row'>
            <div className='number-adjustment-value col-9'> 
                <div style={{
                    paddingLeft: 20
                }}>
                    {value}张
                </div> 
            </div>
            <div className='number-adjustment-control col-3'>
                <div 
                    className='number-adjustment-control-increase'
                    onClick={() => onChange(value + step > max ? max : value + step)}
                >
                    <img src={IncreaseButton} alt='increase' />
                </div>
                <div
                    className='number-adjustment-control-decrease'
                    onClick={() => onChange(value - step < min ? min : value - step)}
                >
                    <img src={DecreaseButton} alt='decrease' />
                </div>
            </div>
        </div>
    );
};

export default NumberAdjustment;