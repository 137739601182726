import { APP_ACTIVE_COLOR } from "../constant"
import './style.css'

const ButtonListSelections = ({ title, selections, onClick, selected, disabled }) => {
    return (
        <div className="row list-of-button-selections">
            <div className="title" style={{
                color: "#363636"
            }}>
                {title}
            </div>
            <div className="row">
            {selections.map((selection, index) => {
                let isSelected = selected.value === selection.value;
                if (disabled) isSelected = false;
                return (
                    <div 
                    key={index}
                    className='col-4'
                    onClick={() => !disabled ? onClick(selection): null}
                    style={{
                        padding: '0px',
                    }}
                    >
                        <div 
                            className="button-select-on-off-with-border"
                            style={{
                                backgroundColor: isSelected ? APP_ACTIVE_COLOR : 'inherit',
                                color: isSelected ? 'white' : '#969696',
                            }}                        
                        >
 
                        {selection.label}
                        </div>
                    </div>
                    
                )
            })}
            </div>
            
        </div>
    )
}

export default ButtonListSelections;