import React from 'react';
import { Form } from 'react-bootstrap';

const CustomSelect = ({ title, options, value, onChange, isDisabled }) => {
    const renderTitle = () => {
        return (
            <div>
                {title}
            </div>
        );
    }

    const handleOnChange = (event) => {
        const selectedOption = JSON.parse(event.target.value);
        onChange(selectedOption);
    
    }

    const renderSelectOptions = () => {
            return (
                <Form.Select aria-label="Default select example" className='custom-select' onChange={handleOnChange} disabled={isDisabled}>
                    {options.map((option, index) => (
                        <option
                            key={index}
                            value={JSON.stringify(option)}
                            selected={value.value === option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </Form.Select>
            );
        }
    

    return (
        <div className="row custom-select-wrapper">
            {renderTitle()}
            {renderSelectOptions()}
        </div>
    );
}

export default CustomSelect;
