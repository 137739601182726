import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import pen_icon from '../images/pen_icon.svg';
import earse_icon from '../images/earse_icon.svg';
import change_pen_earse_size_icon from '../images/change_pen_earse_size_icon.svg';
import undo_icon from '../images/undo_icon.svg';
import redo_icon from '../images/redo_icon.svg';
import remove_background_icon from '../images/remove_background_icon.svg';
import pen_icon_selected from '../images/pen_icon_selected.svg';
import earse_icon_selected from '../images/earse_icon_selected.svg';
import change_pen_earse_size_icon_selected from '../images/change_pen_earse_size_icon_selected.svg';
import undo_icon_selected from '../images/undo_icon_selected.svg';
import redo_icon_selected from '../images/redo_icon_selected.svg';
import remove_background_icon_selected from '../images/remove_background_icon_selected.svg';
import { APP_ACTIVE_COLOR } from '../constant';
import { RangeSliderWithCustomDisplay } from '../components/RangeSliderWithCustomDisplay/RangeSliderWithCustomDisplay';
import { getImagePath } from '../utils';
import './custom_canvas_style.css';

const EDIT_FEATURE_PEN = 'pen';
const EDIT_FEATURE_ERASE = 'erase';
const EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE = 'change_pen_erase_size';
const EDIT_FEATURE_UNDO = 'undo';
const EDIT_FEATURE_REDO = 'redo';
const EDIT_FEATURE_REMOVE_BACKGROUND = 'remove_background';
const PAINT_COLOR = 'rgba(255, 0, 0, 0.5)';

const DrawingCanvas = forwardRef(({ imageUrl, handleRemoveBackground, isLoading, allImagesOfUser, imagePathOnly, changeImageToDraw, maskInDB }, ref) => {
  const canvasRef = useRef(null);
  const [brushSize, setBrushSize] = useState(10);
  const [selectedButtonToEditImage, setSelectedButtonToEditImage] = useState(null);
  const CANVAS_HEIGHT = 450;
  const redoHistoryRef = useRef([]);
  const historyRef = useRef([]);

  

  const [isDrawing, setIsDrawing] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [context, setContext] = useState(null);
  const [mode, setMode] = useState('drawing');
  const lastXRef = useRef(0);
  const lastYRef = useRef(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const mousePositionRef = useRef(mousePosition);
  const isHoveringRef = useRef(isHovering);
  useEffect(() => {
    mousePositionRef.current = mousePosition;
  }, [mousePosition]);
  useEffect(() => {
    isHoveringRef.current = isHovering;
  }, [isHovering]);

  const getMarkedCanvas = () => {
    return new Promise((resolve, reject) => {
      if (canvasRef.current) {
        //return the current canvas, don't need to convert to image
        resolve(canvasRef.current);
      }
    })
  }

  const renderChangePenEarseSizeModal = () => {
    return (
      <div className='col-12' style={{
        position: 'absolute',
        top: 40,
        width: 200,
        left: -50,
        zIndex: 100,
      }}>
        <div className='row slider-to-adjust-proportion-of-the-body-to-the-overall-screen'>
          <RangeSliderWithCustomDisplay
            value={brushSize}
            setValue={setBrushSize}
            max={100}
            min={0}
            maxDisplay={100}
            minDisplay={0}
            step={1}
            disabled={false}
            postFix={"%"}
          />
        </div>

      </div>
    )
  }
  const renderToolBarForEditingImage = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: 810,
        height: 45,
        marginBottom: 30,
        justifyContent: 'space-between',

      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',

        }}>

          {
            renderButtonWithIcon(
              EDIT_FEATURE_PEN,
              pen_icon,
              pen_icon_selected,
              '画笔',
              () =>{
                setDrawingMode()
              } ,
            )
          }
          {
            renderButtonWithIcon(
              EDIT_FEATURE_ERASE,
              earse_icon,
              earse_icon_selected,
              '橡皮擦',
              () => {

                setEraserMode()
              },
            )
          }
          <div style={{
            position: 'relative',
          }}>
            {
              renderButtonWithIcon(
                EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE,
                change_pen_earse_size_icon,
                change_pen_earse_size_icon_selected,
                '笔触大小',
                () => setSelectedButtonToEditImage(EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE),
              )
            }
            {
              selectedButtonToEditImage == EDIT_FEATURE_CHANGE_PEN_ERASE_SIZE && renderChangePenEarseSizeModal()
            }
          </div>


          {
            renderButtonWithIcon(
              EDIT_FEATURE_UNDO,
              undo_icon,
              undo_icon_selected,
              '撤销',
              () => undo(),
            )
          }
          {renderButtonWithIcon(
            EDIT_FEATURE_REDO,
            redo_icon,
            redo_icon_selected,
            '重做',
            () => redo(),
            false
          )}
        </div>

        {
          renderButtonWithIcon(
            EDIT_FEATURE_REMOVE_BACKGROUND,
            remove_background_icon,
            remove_background_icon_selected,
            '去除背景',
            () => { removeBackgroundButtonClick() },
            false,
          )
        }
      </div>
    )
  }

  const removeBackgroundButtonClick = () => {
    setSelectedButtonToEditImage(EDIT_FEATURE_REMOVE_BACKGROUND)
    handleRemoveBackground();
  }



  const renderButtonWithIcon = (feature, icon, selected_icon, text, onClick, shouldDrawBorder = true) => {
    const isSelected = selectedButtonToEditImage === feature;
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: isSelected ?  'white' : 'black',
        cursor: 'pointer',
        fontSize: 14,
        backgroundColor: isSelected ? APP_ACTIVE_COLOR : 'white',
        width: 129,
        height: 45,

      }}
        onClick={onClick}
      >
        <div
          style={{
            borderRight: shouldDrawBorder ? '1px solid black' : 'none',
            width: '100%',
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img src={isSelected ? selected_icon : icon} style={{
            marginRight: 10,
          }} />
          {text}
        </div>

      </div>
    )
  }



  // Expose the getMarkedCanvas method to parent
  useImperativeHandle(ref, () => ({
    getMarkedCanvas,
  }));

  const setDrawingMode = () => {
    setSelectedButtonToEditImage(EDIT_FEATURE_PEN);
    toggleDrawingMode();
  }

  const setEraserMode = () => {
    setSelectedButtonToEditImage(EDIT_FEATURE_ERASE);
    toggleBrushingMode();
  }

  const renderLoadingBackground = () => {
    return (
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(7, 7, 7, 0.7)',
        top: 0,
        left: 0,
      }}>

      </div>
    )
  }



  useEffect(() => {
    //reset history when image url changes
    
    historyRef.current = [];
    redoHistoryRef.current = [];

    if (context) {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      const aspectRatio = image.width / image.height;
      setCanvasWidth(CANVAS_HEIGHT * aspectRatio); // Set canvas height based on the image aspect ratio

      if (maskInDB != '') {
        console.log('maskInDB', maskInDB)
        //load the image mask and set to canvas
        const maskImage = new Image();
        maskImage.crossOrigin = 'anonymous'; // Enable CORS
        maskImage.src = maskInDB;
        maskImage.onload = () => {
          if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            //convert maskImage to data and set to canvas

            ctx.drawImage(maskImage, 0, 0, canvasRef.current.width, canvasRef.current.height);
          }
        }
      }

    };
  }, [imageUrl]);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      setContext(ctx);
    }
  }, [canvasWidth]);

  const startDrawing = (event) => {
    if (!context) return;


    //clear the current circle to 

    setIsDrawing(true);
    lastXRef.current = event.nativeEvent.offsetX;
    lastYRef.current = event.nativeEvent.offsetY;
    drawMask(event);
  };

  const drawMask = (event) => {
    if (!context) return;
    
    context.strokeStyle = PAINT_COLOR;
    context.lineWidth = brushSize;
    context.lineCap = 'round'; // Makes the ends of the line rounded
    const newX = event.nativeEvent.offsetX;
    const newY = event.nativeEvent.offsetY;

    context.globalCompositeOperation = 'destination-out';

    // Draw a line connecting the last position and the current position
    context.beginPath();
    context.moveTo(lastXRef.current, lastYRef.current);
    context.lineTo(newX, newY);
    context.stroke();
    context.closePath();



    if (mode === 'drawing') {
      context.globalCompositeOperation = 'source-over';
      context.strokeStyle = PAINT_COLOR;
      context.lineCap = 'round'; // Makes the ends of the line rounded

      // Draw a line connecting the last position and the current position
      context.beginPath();
      context.moveTo(lastXRef.current, lastYRef.current);
      context.lineTo(newX, newY);
      context.stroke();
      context.closePath();
  
    }
    lastXRef.current = newX;
    lastYRef.current = newY;

  };

  const draw = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    setMousePosition({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    });
    if (!isDrawing) return;

    drawMask(event);
  };

  const endDrawing = () => {
    if (context) {
      context.closePath();
    }
    if (isDrawing) {
      addTheCurrentCanvasToHistory();
    }
    setIsDrawing(false);
  };

  const endDrawingAndSetNoHovering = () => {
    endDrawing();
    setIsHovering(false);
  }


  const addTheCurrentCanvasToHistory = () => {
    const newCanvas = canvasRef.current.toDataURL();
    historyRef.current.push(newCanvas);
    redoHistoryRef.current = [];
  };

  const undo = () => {
    if (historyRef.current.length === 0) return;
    const lastState = historyRef.current.pop();

    redoHistoryRef.current.push(lastState);

    if (historyRef.current.length > 0) {
      const previousState = historyRef.current[historyRef.current.length - 1];
      restoreCanvas(previousState);
    } else {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear canvas if no history
    }
  };

  const redo = () => {
    if (redoHistoryRef.current.length === 0) return;
    const redoState = redoHistoryRef.current.pop();
    historyRef.current.push(redoState);
    restoreCanvas(redoState);
  };

  const restoreCanvas = (state) => {
    const img = new Image();
    img.src = state;
    img.onload = () => {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      context.drawImage(img, 0, 0);
    };
  };

  const toggleDrawingMode = () => {
    setMode('drawing');
  };

  const toggleBrushingMode = () => {
    setMode('brushing');
  };

  const renderOriginalImageAndMaskInSmallView = (image) => {
    return (
      <div style={{
        position: 'absolute',
        width: 150,
        backgroundImage: `url(${getImagePath(image.originalImagePath)})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        left: -170,
        bottom: 0,
        cursor: 'pointer',
      }}
      onClick={() => {changeImageToDraw(image)}}
      >
        <img src={getImagePath(image.originMarkPath)} style={{
          width: 150,
          left: 0,
          bottom: 0,
        }} />
      </div>
    )
  }


  const renderRawImageAndMaskIfNeed = () => {
    const image = allImagesOfUser.find((image) => image.imagePath === imagePathOnly);
    if (image && image.originMarkPath && image.originalImagePath) {
      return renderOriginalImageAndMaskInSmallView(image)
    }
    return (
      <></>
    )
  }
  return (
    <div>
      {renderToolBarForEditingImage()}
      {
        <div
          style={{
            position: 'relative',
            backgroundImage: `url(${imageUrl})`,
            height: CANVAS_HEIGHT,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <canvas
            ref={canvasRef}
            width={canvasWidth}
            height={CANVAS_HEIGHT}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={endDrawing}
            onMouseLeave={endDrawingAndSetNoHovering}
            onMouseEnter={() => { setIsHovering(true) }}
            className="custom-canvas"

          />
          {isHovering && (
        <div
          className="custom-cursor"
          style={{
            left: `${mousePosition.x - brushSize / 2}px`,
            top: `${mousePosition.y - brushSize / 2}px`,
            width: `${brushSize}px`,
            height: `${brushSize}px`,
            zIndex: 1000,
          }}
        ></div>
      )}
          {isLoading && renderLoadingBackground()}
          {renderRawImageAndMaskIfNeed()}
        </div>
      }
    </div>
  );
});

export default DrawingCanvas;
