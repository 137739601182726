export const MOCK_IMAGES  = [
    {
      "createdTime": "Wed, 24 Jul 2024 12:47:41 GMT",
      "generateType": "ai-creativity",
      "id": 1,
      "imageName": "7dac979a-bed6-43dd-a521-1a2fea909ed8",
      "imagePath": "incretech/ai-creativity/7dac979a-bed6-43dd-a521-1a2fea909ed8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 17:05:46 GMT",
      "generateType": "ai-creativity",
      "id": 2,
      "imageName": "6d8d69e1-8bcc-4c22-998e-2e3760555f57",
      "imagePath": "incretech/ai-creativity/6d8d69e1-8bcc-4c22-998e-2e3760555f57.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_creativity_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 17:06:20 GMT",
      "generateType": "ai-creativity",
      "id": 3,
      "imageName": "ada8e85c-1165-4721-9eef-364fe2e37c2d",
      "imagePath": "incretech/ai-creativity/ada8e85c-1165-4721-9eef-364fe2e37c2d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_creativity_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,电商风格,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 17:07:12 GMT",
      "generateType": "ai-creativity",
      "id": 4,
      "imageName": "b5f77114-2ee1-4bdb-b0be-d0399390ed2b",
      "imagePath": "incretech/ai-creativity/b5f77114-2ee1-4bdb-b0be-d0399390ed2b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_creativity_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,电商风格,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 17:08:28 GMT",
      "generateType": "ai-creativity",
      "id": 5,
      "imageName": "7e235352-d8d3-49b5-ad98-4db8933bc668",
      "imagePath": "incretech/ai-creativity/7e235352-d8d3-49b5-ad98-4db8933bc668.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_creativity_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,电商风格,室内场景,正面,特写，黄色, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 17:09:09 GMT",
      "generateType": "ai-creativity",
      "id": 6,
      "imageName": "a70ee5c1-2469-4834-b1ae-33afaedf41ca",
      "imagePath": "incretech/ai-creativity/a70ee5c1-2469-4834-b1ae-33afaedf41ca.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_creativity_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,电商风格,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 23:06:31 GMT",
      "generateType": "ai-creativity",
      "id": 7,
      "imageName": "85b69934-f54d-42bc-964b-efe65e1fe66d",
      "imagePath": "incretech/ai-creativity/85b69934-f54d-42bc-964b-efe65e1fe66d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 23:07:48 GMT",
      "generateType": "ai-creativity",
      "id": 8,
      "imageName": "3097743c-8514-4a1b-b7b0-6d8e24067ca0",
      "imagePath": "incretech/ai-creativity/3097743c-8514-4a1b-b7b0-6d8e24067ca0.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 24 Jul 2024 23:08:37 GMT",
      "generateType": "ai-rendering",
      "id": 9,
      "imageName": "4125aa56-1e90-4567-ae7e-ab5bd8171568",
      "imagePath": "incretech/ai-rendering/4125aa56-1e90-4567-ae7e-ab5bd8171568.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 10:04:19 GMT",
      "generateType": "ai-creativity",
      "id": 10,
      "imageName": "b375cf6f-eb0c-4e30-ad71-365509909382",
      "imagePath": "incretech/ai-creativity/b375cf6f-eb0c-4e30-ad71-365509909382.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 10:04:39 GMT",
      "generateType": "ai-creativity",
      "id": 11,
      "imageName": "b9ddcccd-5399-4624-8577-b129ee8b8608",
      "imagePath": "incretech/ai-creativity/b9ddcccd-5399-4624-8577-b129ee8b8608.png",
      "imageProductType": "压力锅",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "压力锅,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 10:05:06 GMT",
      "generateType": "ai-creativity",
      "id": 12,
      "imageName": "dece289d-d832-4478-a5c1-e3dadeb193d0",
      "imagePath": "incretech/ai-creativity/dece289d-d832-4478-a5c1-e3dadeb193d0.png",
      "imageProductType": "电热饭盒",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电热饭盒,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 10:05:28 GMT",
      "generateType": "ai-creativity",
      "id": 13,
      "imageName": "67a8ceca-8ca6-4789-8401-be40f1142f01",
      "imagePath": "incretech/ai-creativity/67a8ceca-8ca6-4789-8401-be40f1142f01.png",
      "imageProductType": "榨汁机",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "榨汁机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 11:56:04 GMT",
      "generateType": "ai-creativity",
      "id": 14,
      "imageName": "262eeb9a-3d43-4dfd-b8c9-988e9b02fd5a",
      "imagePath": "incretech/ai-creativity/262eeb9a-3d43-4dfd-b8c9-988e9b02fd5a.png",
      "imageProductType": "电炖锅",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电炖锅,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 15:13:45 GMT",
      "generateType": "ai-creativity",
      "id": 15,
      "imageName": "dee81702-d780-4575-b7e5-f87ba1fb18d1",
      "imagePath": "incretech/ai-creativity/dee81702-d780-4575-b7e5-f87ba1fb18d1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 15:15:20 GMT",
      "generateType": "ai-creativity",
      "id": 16,
      "imageName": "587fdaba-d701-4720-a058-074b45015d4f",
      "imagePath": "incretech/ai-creativity/587fdaba-d701-4720-a058-074b45015d4f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 15:16:40 GMT",
      "generateType": "editing",
      "id": 17,
      "imageName": "1723447000_587fdaba-d701-4720-a058-074b45015d4f.png",
      "imagePath": "incretech/editing/1723447000_587fdaba-d701-4720-a058-074b45015d4f.png",
      "imageProductType": "电饭煲",
      "loraModel": "",
      "originMarkPath": "",
      "originalImagePath": "incretech/ai-creativity/587fdaba-d701-4720-a058-074b45015d4f.png",
      "prompt": "",
      "sdModel": "",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 15:17:14 GMT",
      "generateType": "editing",
      "id": 18,
      "imageName": "8e49d656-bee5-4cbb-bb30-b9cb5baa1578",
      "imagePath": "incretech/editing/8e49d656-bee5-4cbb-bb30-b9cb5baa1578.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "incretech/marked_image/raw_0428a7cc-93ef-4bf2-a67f-ae7295efd531.png",
      "originalImagePath": "incretech/editing/1723447000_587fdaba-d701-4720-a058-074b45015d4f.png",
      "prompt": ", 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 15:30:33 GMT",
      "generateType": "ai-rendering",
      "id": 19,
      "imageName": "e860e498-312d-4121-9073-d6b997ad179a",
      "imagePath": "incretech/ai-rendering/e860e498-312d-4121-9073-d6b997ad179a.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 15:30:49 GMT",
      "generateType": "editing",
      "id": 20,
      "imageName": "1723447849_e860e498-312d-4121-9073-d6b997ad179a.png",
      "imagePath": "incretech/editing/1723447849_e860e498-312d-4121-9073-d6b997ad179a.png",
      "imageProductType": "电饭煲",
      "loraModel": "",
      "originMarkPath": "",
      "originalImagePath": "incretech/ai-rendering/e860e498-312d-4121-9073-d6b997ad179a.png",
      "prompt": "",
      "sdModel": "",
      "userId": 1
    },
    {
      "createdTime": "Mon, 12 Aug 2024 15:31:25 GMT",
      "generateType": "editing",
      "id": 21,
      "imageName": "006a9cd0-e062-4ed5-a84d-8f29ad4a00e9",
      "imagePath": "incretech/editing/006a9cd0-e062-4ed5-a84d-8f29ad4a00e9.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "incretech/marked_image/raw_52eb5918-f9d9-48fa-9387-ab8348f4df81.png",
      "originalImagePath": "incretech/editing/1723447849_e860e498-312d-4121-9073-d6b997ad179a.png",
      "prompt": ", 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 15 Aug 2024 11:44:52 GMT",
      "generateType": "ai-creativity",
      "id": 22,
      "imageName": "42305729-544b-4e78-9453-cff735c574e9",
      "imagePath": "incretech/ai-creativity/42305729-544b-4e78-9453-cff735c574e9.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/yilaite_second_creativity_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "car,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 16 Aug 2024 14:04:02 GMT",
      "generateType": "ai-creativity",
      "id": 23,
      "imageName": "106e76e0-aae9-484f-ac70-f966b6ad003d",
      "imagePath": "incretech/ai-creativity/106e76e0-aae9-484f-ac70-f966b6ad003d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 16 Aug 2024 14:54:15 GMT",
      "generateType": "ai-creativity",
      "id": 24,
      "imageName": "3cec9a8e-6a63-4642-b6c3-fe52b78691b8",
      "imagePath": "incretech/ai-creativity/3cec9a8e-6a63-4642-b6c3-fe52b78691b8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 16 Aug 2024 16:14:16 GMT",
      "generateType": "ai-creativity",
      "id": 25,
      "imageName": "b0b628b6-f739-48c8-adee-c1f89accd19d",
      "imagePath": "incretech/ai-creativity/b0b628b6-f739-48c8-adee-c1f89accd19d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 16 Aug 2024 16:14:55 GMT",
      "generateType": "ai-rendering",
      "id": 26,
      "imageName": "201b44fe-1c2f-4a45-8051-77f776b956ea",
      "imagePath": "incretech/ai-rendering/201b44fe-1c2f-4a45-8051-77f776b956ea.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 18 Aug 2024 11:07:36 GMT",
      "generateType": "ai-creativity",
      "id": 27,
      "imageName": "0196a430-e169-4937-817c-7b44b175338f",
      "imagePath": "incretech/ai-creativity/0196a430-e169-4937-817c-7b44b175338f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 18 Aug 2024 11:10:59 GMT",
      "generateType": "ai-creativity",
      "id": 28,
      "imageName": "651e6ed5-6e82-48ff-9370-ba6448b0e5e9",
      "imagePath": "incretech/ai-creativity/651e6ed5-6e82-48ff-9370-ba6448b0e5e9.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 18 Aug 2024 11:11:55 GMT",
      "generateType": "ai-rendering",
      "id": 29,
      "imageName": "eeb959eb-6f04-4231-bea2-71124e4b91ba",
      "imagePath": "incretech/ai-rendering/eeb959eb-6f04-4231-bea2-71124e4b91ba.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 22 Aug 2024 18:36:34 GMT",
      "generateType": "ai-creativity",
      "id": 30,
      "imageName": "0d5bbc7d-b438-4ae1-a8cb-bf8755980824",
      "imagePath": "incretech/ai-creativity/0d5bbc7d-b438-4ae1-a8cb-bf8755980824.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "sdxl/yilaite.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 22 Aug 2024 23:27:27 GMT",
      "generateType": "ai-creativity",
      "id": 31,
      "imageName": "f18afcc8-a63b-49bd-b718-2e6ca95a3728",
      "imagePath": "incretech/ai-creativity/f18afcc8-a63b-49bd-b718-2e6ca95a3728.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 22 Aug 2024 23:29:01 GMT",
      "generateType": "ai-creativity",
      "id": 32,
      "imageName": "f33f3023-253e-4a9a-9115-1dbc0b8357c3",
      "imagePath": "incretech/ai-creativity/f33f3023-253e-4a9a-9115-1dbc0b8357c3.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 22 Aug 2024 23:32:36 GMT",
      "generateType": "ai-rendering",
      "id": 33,
      "imageName": "8b2893d2-6ad3-4ffb-803a-293b6c78700d",
      "imagePath": "incretech/ai-rendering/8b2893d2-6ad3-4ffb-803a-293b6c78700d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 22 Aug 2024 23:33:32 GMT",
      "generateType": "editing",
      "id": 34,
      "imageName": "a12bcc87-5ba4-4d31-ad5f-b5e6dea07bd4",
      "imagePath": "incretech/editing/a12bcc87-5ba4-4d31-ad5f-b5e6dea07bd4.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "incretech/marked_image/raw_20bd1f1e-4488-4ce6-895d-5e6690146b3e.png",
      "originalImagePath": "incretech/editing/006a9cd0-e062-4ed5-a84d-8f29ad4a00e9.png",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 08 Sep 2024 14:27:45 GMT",
      "generateType": "editing",
      "id": 35,
      "imageName": "1725776865_f33f3023-253e-4a9a-9115-1dbc0b8357c3.png",
      "imagePath": "incretech/editing/1725776865_f33f3023-253e-4a9a-9115-1dbc0b8357c3.png",
      "imageProductType": "电饭煲",
      "loraModel": "",
      "originMarkPath": "",
      "originalImagePath": "incretech/ai-creativity/f33f3023-253e-4a9a-9115-1dbc0b8357c3.png",
      "prompt": "",
      "sdModel": "",
      "userId": 1
    },
    {
      "createdTime": "Wed, 25 Sep 2024 22:47:35 GMT",
      "generateType": "ai-creativity",
      "id": 36,
      "imageName": "2f6859d8-d519-44ef-ab13-622a2a0ccd2b",
      "imagePath": "incretech/ai-creativity/2f6859d8-d519-44ef-ab13-622a2a0ccd2b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:27:24 GMT",
      "generateType": "ai-creativity",
      "id": 37,
      "imageName": "77c3ddfa-ad71-4b11-b8c9-eed6f5fefb29",
      "imagePath": "incretech/ai-creativity/77c3ddfa-ad71-4b11-b8c9-eed6f5fefb29.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，独特 ，摩托车，简约造型，黑白配色，直线造型，硬朗,未来科技,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:29:21 GMT",
      "generateType": "ai-creativity",
      "id": 38,
      "imageName": "c648a40a-fc6d-49e4-9ef4-587f9f8f390e",
      "imagePath": "incretech/ai-creativity/c648a40a-fc6d-49e4-9ef4-587f9f8f390e.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，独特 ，摩托车，简约造型，黑白配色，直线造型，硬朗,未来科技,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:30:14 GMT",
      "generateType": "ai-creativity",
      "id": 39,
      "imageName": "373e2475-7329-4755-9aaf-f5285c7b7425",
      "imagePath": "incretech/ai-creativity/373e2475-7329-4755-9aaf-f5285c7b7425.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，摩托车，直线造型，硬朗,未来科技,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:31:20 GMT",
      "generateType": "ai-creativity",
      "id": 40,
      "imageName": "ae63fb8a-c3b1-4fbd-b459-6b783fe9e127",
      "imagePath": "incretech/ai-creativity/ae63fb8a-c3b1-4fbd-b459-6b783fe9e127.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，咖啡机，直线造型，硬朗,未来科技,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:32:41 GMT",
      "generateType": "ai-creativity",
      "id": 41,
      "imageName": "5449c42f-6a6f-4c37-b0ab-0ec856ad2621",
      "imagePath": "incretech/ai-creativity/5449c42f-6a6f-4c37-b0ab-0ec856ad2621.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，咖啡机，直线造型，硬朗,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:33:35 GMT",
      "generateType": "ai-creativity",
      "id": 42,
      "imageName": "55adcccb-6bcc-46b1-8273-8b4aaf5f2fce",
      "imagePath": "incretech/ai-creativity/55adcccb-6bcc-46b1-8273-8b4aaf5f2fce.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，建筑设计，直线造型，硬朗,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:34:22 GMT",
      "generateType": "ai-creativity",
      "id": 43,
      "imageName": "01e0add7-856a-438e-966a-0f4b1c25a254",
      "imagePath": "incretech/ai-creativity/01e0add7-856a-438e-966a-0f4b1c25a254.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，建筑，直线造型，硬朗,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:35:02 GMT",
      "generateType": "ai-creativity",
      "id": 44,
      "imageName": "80fdc9d5-a8ea-4687-8b7e-0bba37a06560",
      "imagePath": "incretech/ai-creativity/80fdc9d5-a8ea-4687-8b7e-0bba37a06560.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，家电，直线造型，硬朗,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:36:35 GMT",
      "generateType": "ai-creativity",
      "id": 45,
      "imageName": "85a529c0-30da-4930-882e-317defb907b5",
      "imagePath": "incretech/ai-creativity/85a529c0-30da-4930-882e-317defb907b5.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，汽车，曲线，动感，科技感,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:37:21 GMT",
      "generateType": "ai-creativity",
      "id": 46,
      "imageName": "5a2a4d93-0734-4458-8e35-d6dc72fe524e",
      "imagePath": "incretech/ai-creativity/5a2a4d93-0734-4458-8e35-d6dc72fe524e.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，自行车，曲线，动感，科技感,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 10:43:54 GMT",
      "generateType": "ai-creativity",
      "id": 47,
      "imageName": "d15fc70a-8def-4e0e-a5b3-ec4b752683b9",
      "imagePath": "incretech/ai-creativity/d15fc70a-8def-4e0e-a5b3-ec4b752683b9.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，自行车，曲线，动感，科技感,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 26 Sep 2024 11:02:52 GMT",
      "generateType": "ai-creativity",
      "id": 48,
      "imageName": "f218962c-c28f-43ef-8d6a-0fb7c3d1eb7a",
      "imagePath": "incretech/ai-creativity/f218962c-c28f-43ef-8d6a-0fb7c3d1eb7a.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆 ，创新 ，表现力，视觉冲击 ，自行车，曲线，动感，科技感,未来科技,室内场景,45度右侧,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 10:56:35 GMT",
      "generateType": "ai-rendering",
      "id": 49,
      "imageName": "401db032-3ceb-4514-8667-6bb428c25d81",
      "imagePath": "incretech/ai-rendering/401db032-3ceb-4514-8667-6bb428c25d81.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000045.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:19:22 GMT",
      "generateType": "ai-creativity",
      "id": 50,
      "imageName": "d43e80fa-a0a4-4189-9de5-cf4797d3ed29",
      "imagePath": "incretech/ai-creativity/d43e80fa-a0a4-4189-9de5-cf4797d3ed29.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆,创意,沙发,曲线,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:20:18 GMT",
      "generateType": "ai-creativity",
      "id": 51,
      "imageName": "3a63e7ea-f8e3-4ac7-805c-87c2c0da7585",
      "imagePath": "incretech/ai-creativity/3a63e7ea-f8e3-4ac7-805c-87c2c0da7585.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/yilaite_second_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆,创意,沙发,曲线,未来科技,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:21:34 GMT",
      "generateType": "ai-creativity",
      "id": 52,
      "imageName": "2863f7f2-b98e-4ec9-b787-9d2b7c5e643b",
      "imagePath": "incretech/ai-creativity/2863f7f2-b98e-4ec9-b787-9d2b7c5e643b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:23:29 GMT",
      "generateType": "ai-creativity",
      "id": 54,
      "imageName": "041da8fb-e676-4974-a3ac-0239257de5f3",
      "imagePath": "incretech/ai-creativity/041da8fb-e676-4974-a3ac-0239257de5f3.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，沙发,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:24:09 GMT",
      "generateType": "ai-creativity",
      "id": 56,
      "imageName": "ebfcdf4f-cd5e-457b-a180-f1cdb9c6318d",
      "imagePath": "incretech/ai-creativity/ebfcdf4f-cd5e-457b-a180-f1cdb9c6318d.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，科技感，,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:24:38 GMT",
      "generateType": "ai-creativity",
      "id": 57,
      "imageName": "e421dfa6-6326-433a-8521-15e1aa7b0566",
      "imagePath": "incretech/ai-creativity/e421dfa6-6326-433a-8521-15e1aa7b0566.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，科技感，,极简主义,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:25:11 GMT",
      "generateType": "ai-creativity",
      "id": 58,
      "imageName": "e2d6a339-4a8f-4c67-8d9a-2c618eac20b9",
      "imagePath": "incretech/ai-creativity/e2d6a339-4a8f-4c67-8d9a-2c618eac20b9.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，科技感，茶壶,极简主义,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:25:32 GMT",
      "generateType": "ai-creativity",
      "id": 60,
      "imageName": "67a06314-c758-4e70-962d-1df166460003",
      "imagePath": "incretech/ai-creativity/67a06314-c758-4e70-962d-1df166460003.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，科技感，茶壶,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:26:01 GMT",
      "generateType": "ai-creativity",
      "id": 61,
      "imageName": "508f31ae-b154-4aea-bdf0-dc00ab648a1e",
      "imagePath": "incretech/ai-creativity/508f31ae-b154-4aea-bdf0-dc00ab648a1e.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，科技感，壶,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:26:29 GMT",
      "generateType": "ai-creativity",
      "id": 62,
      "imageName": "9275a3c7-1a7e-42ff-83e4-d9a3c49dfbe6",
      "imagePath": "incretech/ai-creativity/9275a3c7-1a7e-42ff-83e4-d9a3c49dfbe6.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，科技感，陶艺,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:26:54 GMT",
      "generateType": "ai-creativity",
      "id": 63,
      "imageName": "bb0f89ac-0d4a-4d5c-81cf-059b53f9f0d0",
      "imagePath": "incretech/ai-creativity/bb0f89ac-0d4a-4d5c-81cf-059b53f9f0d0.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，科技感，陶艺作品,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:27:38 GMT",
      "generateType": "ai-creativity",
      "id": 64,
      "imageName": "e9aaa1dc-1582-4c49-be87-4665c5cf39eb",
      "imagePath": "incretech/ai-creativity/e9aaa1dc-1582-4c49-be87-4665c5cf39eb.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，流线,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:28:23 GMT",
      "generateType": "ai-creativity",
      "id": 65,
      "imageName": "8ea7381a-975a-4294-80ce-791b204452b3",
      "imagePath": "incretech/ai-creativity/8ea7381a-975a-4294-80ce-791b204452b3.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，流线，单车,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:28:50 GMT",
      "generateType": "ai-creativity",
      "id": 66,
      "imageName": "ac486ffa-31c5-4fb3-bec7-30e71d5ec9eb",
      "imagePath": "incretech/ai-creativity/ac486ffa-31c5-4fb3-bec7-30e71d5ec9eb.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，流线，头盔,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:29:07 GMT",
      "generateType": "ai-creativity",
      "id": 67,
      "imageName": "2d740c16-bad1-415f-b1e5-6e3bb34f8124",
      "imagePath": "incretech/ai-creativity/2d740c16-bad1-415f-b1e5-6e3bb34f8124.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，流线，头盔,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:31:08 GMT",
      "generateType": "ai-creativity",
      "id": 68,
      "imageName": "2fe860b1-8e75-4e2d-95a2-26fb788eaf04",
      "imagePath": "incretech/ai-creativity/2fe860b1-8e75-4e2d-95a2-26fb788eaf04.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，流线，头盔,未来科技,客厅场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:31:35 GMT",
      "generateType": "ai-creativity",
      "id": 69,
      "imageName": "cdabf4b6-2366-430b-82fe-02c7df2010b1",
      "imagePath": "incretech/ai-creativity/cdabf4b6-2366-430b-82fe-02c7df2010b1.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，流线，头盔,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:33:04 GMT",
      "generateType": "ai-creativity",
      "id": 70,
      "imageName": "d546bdf5-12cf-4fc5-980a-0ee2e11b21b0",
      "imagePath": "incretech/ai-creativity/d546bdf5-12cf-4fc5-980a-0ee2e11b21b0.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，动感，科技感，流线，飞机,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:34:29 GMT",
      "generateType": "ai-creativity",
      "id": 71,
      "imageName": "cf4a022c-c557-454f-97a8-15eae37c18f5",
      "imagePath": "incretech/ai-creativity/cf4a022c-c557-454f-97a8-15eae37c18f5.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，花瓶，优雅,摄影写实,室内场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:35:02 GMT",
      "generateType": "ai-creativity",
      "id": 72,
      "imageName": "74b672fb-81af-4181-8577-78d497ffe4e8",
      "imagePath": "incretech/ai-creativity/74b672fb-81af-4181-8577-78d497ffe4e8.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，花瓶，优雅,极简主义,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:35:48 GMT",
      "generateType": "ai-creativity",
      "id": 73,
      "imageName": "7c2cbc79-fdff-4204-a01e-703a108761a3",
      "imagePath": "incretech/ai-creativity/7c2cbc79-fdff-4204-a01e-703a108761a3.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，曲线，花瓶，优雅,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:36:25 GMT",
      "generateType": "ai-creativity",
      "id": 74,
      "imageName": "c0a00eec-a71b-487a-8b49-3d1830bc8b98",
      "imagePath": "incretech/ai-creativity/c0a00eec-a71b-487a-8b49-3d1830bc8b98.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，花瓶，,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:36:44 GMT",
      "generateType": "ai-creativity",
      "id": 75,
      "imageName": "110ccae8-47ba-4218-b393-79a7da87a872",
      "imagePath": "incretech/ai-creativity/110ccae8-47ba-4218-b393-79a7da87a872.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，花瓶，,极简主义,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:37:37 GMT",
      "generateType": "ai-creativity",
      "id": 76,
      "imageName": "9829b3be-5f48-4f5d-89a0-90e5ddabe5df",
      "imagePath": "incretech/ai-creativity/9829b3be-5f48-4f5d-89a0-90e5ddabe5df.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，鞋子,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:38:28 GMT",
      "generateType": "ai-creativity",
      "id": 77,
      "imageName": "817d389a-3d21-4cf6-b7c1-bc3dd5be9550",
      "imagePath": "incretech/ai-creativity/817d389a-3d21-4cf6-b7c1-bc3dd5be9550.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，鞋子，动感、黑白、科技感,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:38:51 GMT",
      "generateType": "ai-creativity",
      "id": 78,
      "imageName": "949ee6ea-ad47-488e-8450-2c90d1e0d221",
      "imagePath": "incretech/ai-creativity/949ee6ea-ad47-488e-8450-2c90d1e0d221.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，鞋子，动感、黑白、科技感,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 20:39:23 GMT",
      "generateType": "ai-creativity",
      "id": 79,
      "imageName": "15b61df8-9fc8-42b3-b175-e2a0de450c33",
      "imagePath": "incretech/ai-creativity/15b61df8-9fc8-42b3-b175-e2a0de450c33.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/dadan_64_dim-000050.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "大胆，创意，鞋子，动感、黑白、科技感,未来科技,简约场景,正面,中景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 21:15:41 GMT",
      "generateType": "ai-creativity",
      "id": 80,
      "imageName": "ba782e94-2105-4df7-8989-5a275fbb800e",
      "imagePath": "incretech/ai-creativity/ba782e94-2105-4df7-8989-5a275fbb800e.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 27 Sep 2024 21:17:18 GMT",
      "generateType": "ai-creativity",
      "id": 81,
      "imageName": "2212fd1b-ba18-4e4a-8f59-6d28aa84dce4",
      "imagePath": "incretech/ai-creativity/2212fd1b-ba18-4e4a-8f59-6d28aa84dce4.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 03 Oct 2024 14:40:22 GMT",
      "generateType": "ai-creativity",
      "id": 82,
      "imageName": "4a76ed50-c373-4901-9ea8-06d773c4c277",
      "imagePath": "incretech/ai-creativity/4a76ed50-c373-4901-9ea8-06d773c4c277.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 03 Oct 2024 14:41:28 GMT",
      "generateType": "ai-rendering",
      "id": 83,
      "imageName": "cc7f7be5-e4d0-438a-81da-8ec31d195da0",
      "imagePath": "incretech/ai-rendering/cc7f7be5-e4d0-438a-81da-8ec31d195da0.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 03 Oct 2024 14:42:24 GMT",
      "generateType": "ai-rendering",
      "id": 84,
      "imageName": "cbf68ecd-2239-4c5e-8cee-e8a4fdb5f766",
      "imagePath": "incretech/ai-rendering/cbf68ecd-2239-4c5e-8cee-e8a4fdb5f766.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/yilaite_first_combine_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 04 Oct 2024 09:52:34 GMT",
      "generateType": "ai-creativity",
      "id": 85,
      "imageName": "5b7b5b4d-e5b3-463d-86d4-1b707ae29c9a",
      "imagePath": "incretech/ai-creativity/5b7b5b4d-e5b3-463d-86d4-1b707ae29c9a.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 04 Oct 2024 09:53:50 GMT",
      "generateType": "ai-creativity",
      "id": 86,
      "imageName": "c61cea27-044e-4e5b-a473-4c40cfeffd3b",
      "imagePath": "incretech/ai-creativity/c61cea27-044e-4e5b-a473-4c40cfeffd3b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 04 Oct 2024 09:55:37 GMT",
      "generateType": "ai-rendering",
      "id": 87,
      "imageName": "375d33dc-d4f2-46f3-acc6-450c4c042138",
      "imagePath": "incretech/ai-rendering/375d33dc-d4f2-46f3-acc6-450c4c042138.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 04 Oct 2024 10:07:25 GMT",
      "generateType": "ai-creativity",
      "id": 88,
      "imageName": "36a0a49b-ecdf-4ea2-88b9-b9827afc3953",
      "imagePath": "incretech/ai-creativity/36a0a49b-ecdf-4ea2-88b9-b9827afc3953.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 04 Oct 2024 10:08:40 GMT",
      "generateType": "ai-rendering",
      "id": 89,
      "imageName": "c4da6c8c-25c4-49cb-b105-2ba00b4a87eb",
      "imagePath": "incretech/ai-rendering/c4da6c8c-25c4-49cb-b105-2ba00b4a87eb.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/dadan_64_dim-000040.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 07 Oct 2024 16:49:42 GMT",
      "generateType": "ai-car",
      "id": 90,
      "imageName": "9ef59a50-d75b-4184-ae38-24cd8af6bab9",
      "imagePath": "incretech/ai-car/9ef59a50-d75b-4184-ae38-24cd8af6bab9.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Mon, 07 Oct 2024 16:50:12 GMT",
      "generateType": "ai-car",
      "id": 91,
      "imageName": "82f1f80d-2174-4d05-b118-10662de3efc8",
      "imagePath": "incretech/ai-car/82f1f80d-2174-4d05-b118-10662de3efc8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 00:12:48 GMT",
      "generateType": "ai-car",
      "id": 92,
      "imageName": "85062586-3171-4cea-b33c-550e2eeb89de",
      "imagePath": "incretech/ai-car/85062586-3171-4cea-b33c-550e2eeb89de.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 00:14:39 GMT",
      "generateType": "ai-car",
      "id": 93,
      "imageName": "412be4aa-87b8-47ac-ad7a-61e36761083f",
      "imagePath": "incretech/ai-car/412be4aa-87b8-47ac-ad7a-61e36761083f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 01:09:23 GMT",
      "generateType": "editing",
      "id": 94,
      "imageName": "1728407363_c61cea27-044e-4e5b-a473-4c40cfeffd3b.png",
      "imagePath": "incretech/editing/1728407363_c61cea27-044e-4e5b-a473-4c40cfeffd3b.png",
      "imageProductType": "电饭煲",
      "loraModel": "",
      "originMarkPath": "",
      "originalImagePath": "incretech/ai-creativity/c61cea27-044e-4e5b-a473-4c40cfeffd3b.png",
      "prompt": "",
      "sdModel": "",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 01:17:41 GMT",
      "generateType": "editing",
      "id": 95,
      "imageName": "1728407861_c61cea27-044e-4e5b-a473-4c40cfeffd3b.png",
      "imagePath": "incretech/editing/1728407861_c61cea27-044e-4e5b-a473-4c40cfeffd3b.png",
      "imageProductType": "电饭煲",
      "loraModel": "",
      "originMarkPath": "",
      "originalImagePath": "incretech/ai-creativity/c61cea27-044e-4e5b-a473-4c40cfeffd3b.png",
      "prompt": "",
      "sdModel": "",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 15:24:10 GMT",
      "generateType": "ai-car",
      "id": 98,
      "imageName": "65380352-04b6-424a-ae85-5c2dcc9271af",
      "imagePath": "incretech/ai-car/65380352-04b6-424a-ae85-5c2dcc9271af.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 15:27:49 GMT",
      "generateType": "ai-car",
      "id": 99,
      "imageName": "90cdbecf-8245-41d3-a8a1-708392852563",
      "imagePath": "incretech/ai-car/90cdbecf-8245-41d3-a8a1-708392852563.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 15:28:07 GMT",
      "generateType": "ai-car",
      "id": 100,
      "imageName": "c9f4d8c1-5695-49be-b4c5-f19a57e81d99",
      "imagePath": "incretech/ai-car/c9f4d8c1-5695-49be-b4c5-f19a57e81d99.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 22:24:35 GMT",
      "generateType": "ai-car",
      "id": 101,
      "imageName": "51ec6174-0fd3-4f97-bff4-f279e38c1125",
      "imagePath": "incretech/ai-car/51ec6174-0fd3-4f97-bff4-f279e38c1125.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 22:25:57 GMT",
      "generateType": "ai-car",
      "id": 102,
      "imageName": "27d05ebd-0517-417f-81d0-3bd940407aeb",
      "imagePath": "incretech/ai-car/27d05ebd-0517-417f-81d0-3bd940407aeb.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 09 Oct 2024 22:26:24 GMT",
      "generateType": "ai-car",
      "id": 103,
      "imageName": "3fdd2628-051e-4471-8ddf-cf7d23b60bed",
      "imagePath": "incretech/ai-car/3fdd2628-051e-4471-8ddf-cf7d23b60bed.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 14:45:30 GMT",
      "generateType": "ai-car",
      "id": 104,
      "imageName": "a6ab39d4-1756-4ce9-a8bd-ce0ebd477af2",
      "imagePath": "incretech/ai-car/a6ab39d4-1756-4ce9-a8bd-ce0ebd477af2.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 14:47:18 GMT",
      "generateType": "ai-car",
      "id": 105,
      "imageName": "69f028b5-4125-4bcb-a7dc-b155ab77096b",
      "imagePath": "incretech/ai-car/69f028b5-4125-4bcb-a7dc-b155ab77096b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 16:48:45 GMT",
      "generateType": "ai-creativity",
      "id": 107,
      "imageName": "84343f66-d9e7-42b2-83af-d5b3fd3af522",
      "imagePath": "incretech/ai-creativity/84343f66-d9e7-42b2-83af-d5b3fd3af522.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 16:59:47 GMT",
      "generateType": "ai-creativity",
      "id": 108,
      "imageName": "70f1a8f2-035f-4d04-96f3-310f1fd0e926",
      "imagePath": "incretech/ai-creativity/70f1a8f2-035f-4d04-96f3-310f1fd0e926.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:01:32 GMT",
      "generateType": "ai-creativity",
      "id": 109,
      "imageName": "8e1e8f39-0808-4afc-83b0-75eac737b4d6",
      "imagePath": "incretech/ai-creativity/8e1e8f39-0808-4afc-83b0-75eac737b4d6.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:02:10 GMT",
      "generateType": "ai-rendering",
      "id": 110,
      "imageName": "a465b7fb-39dd-4aaf-8c32-cd1426d7b1da",
      "imagePath": "incretech/ai-rendering/a465b7fb-39dd-4aaf-8c32-cd1426d7b1da.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:02:24 GMT",
      "generateType": "editing",
      "id": 111,
      "imageName": "1728550944_a465b7fb-39dd-4aaf-8c32-cd1426d7b1da.png",
      "imagePath": "incretech/editing/1728550944_a465b7fb-39dd-4aaf-8c32-cd1426d7b1da.png",
      "imageProductType": "电饭煲",
      "loraModel": "",
      "originMarkPath": "",
      "originalImagePath": "incretech/ai-rendering/a465b7fb-39dd-4aaf-8c32-cd1426d7b1da.png",
      "prompt": "",
      "sdModel": "",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:03:06 GMT",
      "generateType": "editing",
      "id": 112,
      "imageName": "2d4a5056-ad5d-41ee-8f42-8cbc344a5779",
      "imagePath": "incretech/editing/2d4a5056-ad5d-41ee-8f42-8cbc344a5779.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "incretech/marked_image/raw_8c577ffb-ba1d-4445-a4e1-ffed3e25d1a0.png",
      "originalImagePath": "incretech/remove_background/1e7a7b6d-e4f9-4f02-819e-4acdb2546f46.png",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:05:08 GMT",
      "generateType": "ai-car",
      "id": 113,
      "imageName": "3c058c60-1e91-45e2-8311-f914ef3605f5",
      "imagePath": "incretech/ai-car/3c058c60-1e91-45e2-8311-f914ef3605f5.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:05:26 GMT",
      "generateType": "ai-car",
      "id": 114,
      "imageName": "94b0ec86-c1a9-4be5-a166-8b1bb6acd024",
      "imagePath": "incretech/ai-car/94b0ec86-c1a9-4be5-a166-8b1bb6acd024.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:08:46 GMT",
      "generateType": "ai-car",
      "id": 115,
      "imageName": "231c4299-75ef-449b-a255-d8e838937901",
      "imagePath": "incretech/ai-car/231c4299-75ef-449b-a255-d8e838937901.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:10:02 GMT",
      "generateType": "ai-car",
      "id": 116,
      "imageName": "6b837665-98c8-4467-896a-4bd925670814",
      "imagePath": "incretech/ai-car/6b837665-98c8-4467-896a-4bd925670814.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:10:22 GMT",
      "generateType": "ai-car",
      "id": 117,
      "imageName": "95de63a5-5a4c-4780-935f-9cc6908ffa77",
      "imagePath": "incretech/ai-car/95de63a5-5a4c-4780-935f-9cc6908ffa77.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:10:42 GMT",
      "generateType": "ai-car",
      "id": 118,
      "imageName": "d6b2ed63-f2e3-4fd2-8832-8b1973e4c3cc",
      "imagePath": "incretech/ai-car/d6b2ed63-f2e3-4fd2-8832-8b1973e4c3cc.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:11:18 GMT",
      "generateType": "ai-car",
      "id": 119,
      "imageName": "7026136f-e57f-48a5-9f03-5b792f625ed8",
      "imagePath": "incretech/ai-car/7026136f-e57f-48a5-9f03-5b792f625ed8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:13:19 GMT",
      "generateType": "ai-car",
      "id": 120,
      "imageName": "af872117-d958-4989-b26e-dbc7625c71c5",
      "imagePath": "incretech/ai-car/af872117-d958-4989-b26e-dbc7625c71c5.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:14:17 GMT",
      "generateType": "ai-car",
      "id": 121,
      "imageName": "195c2cac-5448-436f-a88c-16eb19cea58e",
      "imagePath": "incretech/ai-car/195c2cac-5448-436f-a88c-16eb19cea58e.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:19:52 GMT",
      "generateType": "ai-creativity",
      "id": 122,
      "imageName": "a04ac976-12ae-48f5-8f71-ea3519cc802e",
      "imagePath": "incretech/ai-creativity/a04ac976-12ae-48f5-8f71-ea3519cc802e.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:20:38 GMT",
      "generateType": "ai-rendering",
      "id": 123,
      "imageName": "c829deb5-56d5-4928-9df0-7710b5aeb8a2",
      "imagePath": "incretech/ai-rendering/c829deb5-56d5-4928-9df0-7710b5aeb8a2.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:21:53 GMT",
      "generateType": "ai-car",
      "id": 124,
      "imageName": "611e0bca-a2f1-4891-8bf2-7b207e1ccd17",
      "imagePath": "incretech/ai-car/611e0bca-a2f1-4891-8bf2-7b207e1ccd17.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:22:47 GMT",
      "generateType": "ai-car",
      "id": 125,
      "imageName": "9fb6ddb7-0d17-43ae-bac8-5bc5f95bfbae",
      "imagePath": "incretech/ai-car/9fb6ddb7-0d17-43ae-bac8-5bc5f95bfbae.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:23:15 GMT",
      "generateType": "ai-car",
      "id": 126,
      "imageName": "7b19624d-7f93-4328-b2dd-173191471334",
      "imagePath": "incretech/ai-car/7b19624d-7f93-4328-b2dd-173191471334.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 10 Oct 2024 17:23:48 GMT",
      "generateType": "ai-car",
      "id": 127,
      "imageName": "7945323c-0f8e-4698-a5c8-024505dcc888",
      "imagePath": "incretech/ai-car/7945323c-0f8e-4698-a5c8-024505dcc888.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 16:36:03 GMT",
      "generateType": "ai-creativity",
      "id": 128,
      "imageName": "f8092c75-8ccf-4ae3-9b59-7e7fceae2a18",
      "imagePath": "incretech/ai-creativity/f8092c75-8ccf-4ae3-9b59-7e7fceae2a18.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 16:37:25 GMT",
      "generateType": "ai-car",
      "id": 129,
      "imageName": "4f2a47d4-19cd-4eb4-bbda-150f52b5ff9a",
      "imagePath": "incretech/ai-car/4f2a47d4-19cd-4eb4-bbda-150f52b5ff9a.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 16:37:48 GMT",
      "generateType": "ai-creativity",
      "id": 130,
      "imageName": "e05ce20d-be03-4fa0-864c-60887600492c",
      "imagePath": "incretech/ai-creativity/e05ce20d-be03-4fa0-864c-60887600492c.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 16:41:17 GMT",
      "generateType": "ai-creativity",
      "id": 131,
      "imageName": "27b123a2-9047-4651-9a04-dbbb3f9034c5",
      "imagePath": "incretech/ai-creativity/27b123a2-9047-4651-9a04-dbbb3f9034c5.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 16:41:50 GMT",
      "generateType": "ai-creativity",
      "id": 132,
      "imageName": "c1e84777-7280-4da7-ad6c-6caa54c43df2",
      "imagePath": "incretech/ai-creativity/c1e84777-7280-4da7-ad6c-6caa54c43df2.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 16:43:20 GMT",
      "generateType": "ai-car",
      "id": 133,
      "imageName": "1beb5c76-f28a-47f0-a4a0-9b8a646e6c22",
      "imagePath": "incretech/ai-car/1beb5c76-f28a-47f0-a4a0-9b8a646e6c22.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 20:26:09 GMT",
      "generateType": "ai-creativity",
      "id": 134,
      "imageName": "73037b67-1d00-4b07-95ac-95d79b638c52",
      "imagePath": "incretech/ai-creativity/73037b67-1d00-4b07-95ac-95d79b638c52.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 20:27:29 GMT",
      "generateType": "ai-rendering",
      "id": 135,
      "imageName": "879209c4-457b-4557-bae4-472ba98a59c6",
      "imagePath": "incretech/ai-rendering/879209c4-457b-4557-bae4-472ba98a59c6.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 20:30:17 GMT",
      "generateType": "ai-rendering",
      "id": 136,
      "imageName": "1e1b0dde-1683-42d1-a312-2bf450a3c6a6",
      "imagePath": "incretech/ai-rendering/1e1b0dde-1683-42d1-a312-2bf450a3c6a6.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 20:31:20 GMT",
      "generateType": "editing",
      "id": 137,
      "imageName": "3bd6aa26-e91d-415e-be28-387469b1d87b",
      "imagePath": "incretech/editing/3bd6aa26-e91d-415e-be28-387469b1d87b.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "incretech/marked_image/raw_705c5f94-0531-4981-89a6-094ece56f07e.png",
      "originalImagePath": "incretech/remove_background/5fbc67d5-1bd6-4a68-a976-be3b9395a3cb.png",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 20:34:22 GMT",
      "generateType": "ai-car",
      "id": 138,
      "imageName": "71119d86-f219-4161-8987-d88f87cc8289",
      "imagePath": "incretech/ai-car/71119d86-f219-4161-8987-d88f87cc8289.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/creativity_rendering.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:01:15 GMT",
      "generateType": "ai-creativity",
      "id": 139,
      "imageName": "0d0d3e83-b248-4a4e-b2f3-6a7b607206d1",
      "imagePath": "incretech/ai-creativity/0d0d3e83-b248-4a4e-b2f3-6a7b607206d1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:01:41 GMT",
      "generateType": "ai-creativity",
      "id": 140,
      "imageName": "d38e94b5-d678-43f4-b7b6-94bf6eb88ead",
      "imagePath": "incretech/ai-creativity/d38e94b5-d678-43f4-b7b6-94bf6eb88ead.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "A电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:01:57 GMT",
      "generateType": "ai-creativity",
      "id": 141,
      "imageName": "7de40afe-dd26-4e0a-a5be-11aad7a64c90",
      "imagePath": "incretech/ai-creativity/7de40afe-dd26-4e0a-a5be-11aad7a64c90.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "a 电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:02:26 GMT",
      "generateType": "ai-creativity",
      "id": 142,
      "imageName": "b8501153-5d7d-493b-8501-1f3c9ffb7a35",
      "imagePath": "incretech/ai-creativity/b8501153-5d7d-493b-8501-1f3c9ffb7a35.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "a nice 电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:03:29 GMT",
      "generateType": "ai-creativity",
      "id": 143,
      "imageName": "c195ae80-3d31-46b8-b9ca-7592a49da555",
      "imagePath": "incretech/ai-creativity/c195ae80-3d31-46b8-b9ca-7592a49da555.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "a square 电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:04:59 GMT",
      "generateType": "ai-creativity",
      "id": 144,
      "imageName": "73c84138-521e-4a5e-8ac3-d970770387fa",
      "imagePath": "incretech/ai-creativity/73c84138-521e-4a5e-8ac3-d970770387fa.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "electric cooker 电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:05:24 GMT",
      "generateType": "ai-creativity",
      "id": 145,
      "imageName": "a615c490-febe-42cb-a5a2-bd5d1ce7d0d5",
      "imagePath": "incretech/ai-creativity/a615c490-febe-42cb-a5a2-bd5d1ce7d0d5.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "electric cooker 电饭煲,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:05:49 GMT",
      "generateType": "ai-creativity",
      "id": 146,
      "imageName": "c518851f-8abe-472a-a20e-ddbe77f4d69c",
      "imagePath": "incretech/ai-creativity/c518851f-8abe-472a-a20e-ddbe77f4d69c.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "electric cooker 电饭煲,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:06:51 GMT",
      "generateType": "ai-creativity",
      "id": 147,
      "imageName": "932b6a1c-7390-496f-b9f3-69d705a77e6b",
      "imagePath": "incretech/ai-creativity/932b6a1c-7390-496f-b9f3-69d705a77e6b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "European retro 电饭煲,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:07:34 GMT",
      "generateType": "ai-creativity",
      "id": 148,
      "imageName": "78e7267d-81dc-4960-a8cc-653e2553d8a1",
      "imagePath": "incretech/ai-creativity/78e7267d-81dc-4960-a8cc-653e2553d8a1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "Japanese style simple 电饭煲,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:10:56 GMT",
      "generateType": "ai-creativity",
      "id": 149,
      "imageName": "97a17101-a407-4055-a5d1-fdb202003d9a",
      "imagePath": "incretech/ai-creativity/97a17101-a407-4055-a5d1-fdb202003d9a.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,极简主义,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:11:14 GMT",
      "generateType": "ai-creativity",
      "id": 150,
      "imageName": "0e0e6e20-7db5-49a1-af50-e250fefdaefd",
      "imagePath": "incretech/ai-creativity/0e0e6e20-7db5-49a1-af50-e250fefdaefd.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,日系家居,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:12:13 GMT",
      "generateType": "ai-creativity",
      "id": 151,
      "imageName": "4f805cb6-ee3e-4cc0-a826-5307e5b5a7bb",
      "imagePath": "incretech/ai-creativity/4f805cb6-ee3e-4cc0-a826-5307e5b5a7bb.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,电商风格,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:12:29 GMT",
      "generateType": "ai-creativity",
      "id": 152,
      "imageName": "d610d0a0-a0f9-4bc9-aec4-f49386d10872",
      "imagePath": "incretech/ai-creativity/d610d0a0-a0f9-4bc9-aec4-f49386d10872.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,电商风格,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:13:37 GMT",
      "generateType": "ai-creativity",
      "id": 153,
      "imageName": "302bea66-3278-4eef-b44b-9eef666973ef",
      "imagePath": "incretech/ai-creativity/302bea66-3278-4eef-b44b-9eef666973ef.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,国风国潮,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:13:56 GMT",
      "generateType": "ai-creativity",
      "id": 154,
      "imageName": "d3dff44f-94c0-4b2c-8886-e7612adffe99",
      "imagePath": "incretech/ai-creativity/d3dff44f-94c0-4b2c-8886-e7612adffe99.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特创意80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,国风国潮,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:21:51 GMT",
      "generateType": "ai-creativity",
      "id": 155,
      "imageName": "209f38fd-84a1-48ed-b044-0be9ef730ca1",
      "imagePath": "incretech/ai-creativity/209f38fd-84a1-48ed-b044-0be9ef730ca1.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "摩托车,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:22:03 GMT",
      "generateType": "ai-creativity",
      "id": 156,
      "imageName": "0011de14-4882-485a-aed3-93d0302eff96",
      "imagePath": "incretech/ai-creativity/0011de14-4882-485a-aed3-93d0302eff96.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "摩托车,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:22:14 GMT",
      "generateType": "ai-creativity",
      "id": 157,
      "imageName": "85f3deb1-f0d8-431f-9395-1ce3fdf25c72",
      "imagePath": "incretech/ai-creativity/85f3deb1-f0d8-431f-9395-1ce3fdf25c72.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "摩托车,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:22:51 GMT",
      "generateType": "ai-creativity",
      "id": 158,
      "imageName": "5b9fe7ee-a207-4fcd-8340-1db3209c6787",
      "imagePath": "incretech/ai-creativity/5b9fe7ee-a207-4fcd-8340-1db3209c6787.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "摩托车,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:23:28 GMT",
      "generateType": "ai-creativity",
      "id": 159,
      "imageName": "adae3885-4f21-4cd2-96c9-c364c24efe2c",
      "imagePath": "incretech/ai-creativity/adae3885-4f21-4cd2-96c9-c364c24efe2c.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特创意80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:24:16 GMT",
      "generateType": "ai-creativity",
      "id": 160,
      "imageName": "41f3ea92-6183-4e74-bbed-dc58860ff181",
      "imagePath": "incretech/ai-creativity/41f3ea92-6183-4e74-bbed-dc58860ff181.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特创意80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,客厅场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:24:41 GMT",
      "generateType": "ai-creativity",
      "id": 161,
      "imageName": "d43951ec-438c-4b73-bed2-13fce46a9cc1",
      "imagePath": "incretech/ai-creativity/d43951ec-438c-4b73-bed2-13fce46a9cc1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:25:02 GMT",
      "generateType": "ai-creativity",
      "id": 162,
      "imageName": "233f7a77-0b13-4c77-bc1b-11403c56c8c2",
      "imagePath": "incretech/ai-creativity/233f7a77-0b13-4c77-bc1b-11403c56c8c2.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:25:42 GMT",
      "generateType": "ai-creativity",
      "id": 163,
      "imageName": "624e19fc-8443-49a5-af64-fe3e4c63cf11",
      "imagePath": "incretech/ai-creativity/624e19fc-8443-49a5-af64-fe3e4c63cf11.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:25:51 GMT",
      "generateType": "ai-creativity",
      "id": 164,
      "imageName": "9c48c849-5acf-4768-b486-a16efac604a6",
      "imagePath": "incretech/ai-creativity/9c48c849-5acf-4768-b486-a16efac604a6.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:26:03 GMT",
      "generateType": "ai-creativity",
      "id": 165,
      "imageName": "ccfb8fe1-8c83-4f31-b465-435ea94bd109",
      "imagePath": "incretech/ai-creativity/ccfb8fe1-8c83-4f31-b465-435ea94bd109.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:27:08 GMT",
      "generateType": "ai-creativity",
      "id": 166,
      "imageName": "60f6ada1-e7ef-4774-b852-c88530e9ab7f",
      "imagePath": "incretech/ai-creativity/60f6ada1-e7ef-4774-b852-c88530e9ab7f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/伊莱特写实.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 16 Oct 2024 21:28:38 GMT",
      "generateType": "ai-creativity",
      "id": 167,
      "imageName": "cf1566f5-0900-4909-ae7b-cf23de49026f",
      "imagePath": "incretech/ai-creativity/cf1566f5-0900-4909-ae7b-cf23de49026f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 20:39:59 GMT",
      "generateType": "ai-creativity",
      "id": 168,
      "imageName": "43d1ba89-7a19-4d30-bee8-1ad15c4aaeb7",
      "imagePath": "incretech/ai-creativity/43d1ba89-7a19-4d30-bee8-1ad15c4aaeb7.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 20:43:28 GMT",
      "generateType": "ai-creativity",
      "id": 169,
      "imageName": "74ee09b4-72cf-4dae-aa50-cac1cc7335ff",
      "imagePath": "incretech/ai-creativity/74ee09b4-72cf-4dae-aa50-cac1cc7335ff.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 21:06:02 GMT",
      "generateType": "ai-creativity",
      "id": 170,
      "imageName": "87790b99-87db-407f-8cbc-c61050b8eb87",
      "imagePath": "incretech/ai-creativity/87790b99-87db-407f-8cbc-c61050b8eb87.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 21:11:29 GMT",
      "generateType": "ai-creativity",
      "id": 171,
      "imageName": "b3863b9a-faac-4b55-b002-4a22a8b2b0e3",
      "imagePath": "incretech/ai-creativity/b3863b9a-faac-4b55-b002-4a22a8b2b0e3.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 21:12:58 GMT",
      "generateType": "ai-creativity",
      "id": 172,
      "imageName": "dab0848f-bc3c-4d58-aa37-3d0ba06d92f0",
      "imagePath": "incretech/ai-creativity/dab0848f-bc3c-4d58-aa37-3d0ba06d92f0.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 21:20:01 GMT",
      "generateType": "ai-rendering",
      "id": 173,
      "imageName": "cfa5cd2e-9c0b-4526-bfce-2d7d50f81bfc",
      "imagePath": "incretech/ai-rendering/cfa5cd2e-9c0b-4526-bfce-2d7d50f81bfc.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 21:27:59 GMT",
      "generateType": "ai-rendering",
      "id": 174,
      "imageName": "b275e2dc-d8be-4d29-b7a5-3fba109a6fcf",
      "imagePath": "incretech/ai-rendering/b275e2dc-d8be-4d29-b7a5-3fba109a6fcf.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 21:31:22 GMT",
      "generateType": "ai-rendering",
      "id": 175,
      "imageName": "c0f1bb47-4bfb-42d4-a0c5-89ae8f736a17",
      "imagePath": "incretech/ai-rendering/c0f1bb47-4bfb-42d4-a0c5-89ae8f736a17.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 21:33:58 GMT",
      "generateType": "ai-creativity",
      "id": 176,
      "imageName": "b5c0846a-766d-484f-9ca9-3f1c3a5435b8",
      "imagePath": "incretech/ai-creativity/b5c0846a-766d-484f-9ca9-3f1c3a5435b8.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 22:29:52 GMT",
      "generateType": "ai-creativity",
      "id": 177,
      "imageName": "d065921c-d3a3-463a-9ef8-bfde60e29c48",
      "imagePath": "incretech/ai-creativity/d065921c-d3a3-463a-9ef8-bfde60e29c48.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 22:31:01 GMT",
      "generateType": "ai-creativity",
      "id": 178,
      "imageName": "2afee03f-09bd-4ad2-bd7d-a7a579ee6ea4",
      "imagePath": "incretech/ai-creativity/2afee03f-09bd-4ad2-bd7d-a7a579ee6ea4.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 22:50:18 GMT",
      "generateType": "ai-creativity",
      "id": 179,
      "imageName": "da788b4d-8d2b-4553-a29c-a8f6c6293e42",
      "imagePath": "incretech/ai-creativity/da788b4d-8d2b-4553-a29c-a8f6c6293e42.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 23:00:28 GMT",
      "generateType": "ai-creativity",
      "id": 180,
      "imageName": "f112c6f9-cbd7-498f-9c02-8a31d331e088",
      "imagePath": "incretech/ai-creativity/f112c6f9-cbd7-498f-9c02-8a31d331e088.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Wed, 23 Oct 2024 23:02:35 GMT",
      "generateType": "ai-creativity",
      "id": 181,
      "imageName": "3371ef9c-05e4-4c19-9b4c-ad74bc6812b8",
      "imagePath": "incretech/ai-creativity/3371ef9c-05e4-4c19-9b4c-ad74bc6812b8.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 24 Oct 2024 12:13:08 GMT",
      "generateType": "ai-cmf",
      "id": 182,
      "imageName": "dec94974-b23c-4c27-9cd5-22a50f5f3ac1",
      "imagePath": "incretech/ai-cmf/dec94974-b23c-4c27-9cd5-22a50f5f3ac1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 24 Oct 2024 12:14:47 GMT",
      "generateType": "ai-cmf",
      "id": 183,
      "imageName": "5e639f1c-0ccc-4689-89a5-4a0e30b8601a",
      "imagePath": "incretech/ai-cmf/5e639f1c-0ccc-4689-89a5-4a0e30b8601a.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 24 Oct 2024 12:16:18 GMT",
      "generateType": "ai-cmf",
      "id": 184,
      "imageName": "2746842e-15ec-4a97-abc5-a8faecc8f391",
      "imagePath": "incretech/ai-cmf/2746842e-15ec-4a97-abc5-a8faecc8f391.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 24 Oct 2024 12:17:33 GMT",
      "generateType": "ai-cmf",
      "id": 185,
      "imageName": "b2f89086-1e54-45ff-85f3-5071c59ed6eb",
      "imagePath": "incretech/ai-cmf/b2f89086-1e54-45ff-85f3-5071c59ed6eb.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 24 Oct 2024 12:30:30 GMT",
      "generateType": "ai-cmf",
      "id": 186,
      "imageName": "b6d546cb-6593-465d-903a-7cb0ed418fc3",
      "imagePath": "incretech/ai-cmf/b6d546cb-6593-465d-903a-7cb0ed418fc3.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 24 Oct 2024 12:32:13 GMT",
      "generateType": "ai-cmf",
      "id": 187,
      "imageName": "9a6baef0-6bde-47f6-9159-ac70512c5587",
      "imagePath": "incretech/ai-cmf/9a6baef0-6bde-47f6-9159-ac70512c5587.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Thu, 24 Oct 2024 12:34:13 GMT",
      "generateType": "ai-cmf",
      "id": 188,
      "imageName": "32db3662-5748-4744-8167-e011d9597fc5",
      "imagePath": "incretech/ai-cmf/32db3662-5748-4744-8167-e011d9597fc5.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:09:52 GMT",
      "generateType": "ai-cmf",
      "id": 189,
      "imageName": "ba19bac6-8c25-45e4-9ead-1ccf5880a650",
      "imagePath": "incretech/ai-cmf/ba19bac6-8c25-45e4-9ead-1ccf5880a650.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:11:14 GMT",
      "generateType": "ai-cmf",
      "id": 190,
      "imageName": "67f3038c-38d8-43b5-8d04-ab5f3c00d62e",
      "imagePath": "incretech/ai-cmf/67f3038c-38d8-43b5-8d04-ab5f3c00d62e.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:12:24 GMT",
      "generateType": "ai-cmf",
      "id": 191,
      "imageName": "e018735c-d41a-40d7-9888-ca434b77be73",
      "imagePath": "incretech/ai-cmf/e018735c-d41a-40d7-9888-ca434b77be73.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:14:19 GMT",
      "generateType": "ai-cmf",
      "id": 192,
      "imageName": "87366fff-d628-43eb-af6d-d2e5a562d23b",
      "imagePath": "incretech/ai-cmf/87366fff-d628-43eb-af6d-d2e5a562d23b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:16:12 GMT",
      "generateType": "ai-cmf",
      "id": 193,
      "imageName": "808ec301-0c26-4588-92e5-725443def7a3",
      "imagePath": "incretech/ai-cmf/808ec301-0c26-4588-92e5-725443def7a3.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:19:47 GMT",
      "generateType": "ai-cmf",
      "id": 194,
      "imageName": "fea15ee7-7379-4d8a-b096-b97f3fdc6c80",
      "imagePath": "incretech/ai-cmf/fea15ee7-7379-4d8a-b096-b97f3fdc6c80.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:26:08 GMT",
      "generateType": "ai-cmf",
      "id": 195,
      "imageName": "3ef4f32b-f538-43c4-8d8a-5f772669e75d",
      "imagePath": "incretech/ai-cmf/3ef4f32b-f538-43c4-8d8a-5f772669e75d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:28:26 GMT",
      "generateType": "ai-cmf",
      "id": 196,
      "imageName": "906e0ca7-478c-470e-8c83-6be9e08ab35e",
      "imagePath": "incretech/ai-cmf/906e0ca7-478c-470e-8c83-6be9e08ab35e.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 13:39:12 GMT",
      "generateType": "ai-rendering",
      "id": 197,
      "imageName": "2c579509-a3c8-465e-8878-56b4c779777f",
      "imagePath": "incretech/ai-rendering/2c579509-a3c8-465e-8878-56b4c779777f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:20:07 GMT",
      "generateType": "ai-creativity",
      "id": 198,
      "imageName": "6351a037-e12a-482e-a631-f4b007e30be8",
      "imagePath": "incretech/ai-creativity/6351a037-e12a-482e-a631-f4b007e30be8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:20:47 GMT",
      "generateType": "ai-creativity",
      "id": 199,
      "imageName": "016cac00-4db4-4c9c-b46f-8fa0c34dc9e9",
      "imagePath": "incretech/ai-creativity/016cac00-4db4-4c9c-b46f-8fa0c34dc9e9.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:22:37 GMT",
      "generateType": "ai-creativity",
      "id": 200,
      "imageName": "819eb86d-325f-432b-a010-5ddc1435ad58",
      "imagePath": "incretech/ai-creativity/819eb86d-325f-432b-a010-5ddc1435ad58.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:23:04 GMT",
      "generateType": "ai-creativity",
      "id": 201,
      "imageName": "4afc9e36-a771-4e63-96c2-0ed5f86086dd",
      "imagePath": "incretech/ai-creativity/4afc9e36-a771-4e63-96c2-0ed5f86086dd.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:23:36 GMT",
      "generateType": "ai-creativity",
      "id": 202,
      "imageName": "ebcfa97c-d30c-42ad-8a3e-f8e055764a58",
      "imagePath": "incretech/ai-creativity/ebcfa97c-d30c-42ad-8a3e-f8e055764a58.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:24:19 GMT",
      "generateType": "ai-rendering",
      "id": 203,
      "imageName": "0f43ab91-31f0-460b-93ce-3d360817c0bf",
      "imagePath": "incretech/ai-rendering/0f43ab91-31f0-460b-93ce-3d360817c0bf.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:25:51 GMT",
      "generateType": "ai-rendering",
      "id": 204,
      "imageName": "d83bfb94-a999-4c6d-9a85-514baf340ac6",
      "imagePath": "incretech/ai-rendering/d83bfb94-a999-4c6d-9a85-514baf340ac6.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:26:45 GMT",
      "generateType": "ai-rendering",
      "id": 205,
      "imageName": "ff1c8241-6bad-4a45-9f1d-388fba901654",
      "imagePath": "incretech/ai-rendering/ff1c8241-6bad-4a45-9f1d-388fba901654.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:27:22 GMT",
      "generateType": "ai-rendering",
      "id": 206,
      "imageName": "6858110f-86e9-48e2-839c-223438f16618",
      "imagePath": "incretech/ai-rendering/6858110f-86e9-48e2-839c-223438f16618.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:28:03 GMT",
      "generateType": "ai-rendering",
      "id": 207,
      "imageName": "5ed27fc2-37ad-41a2-8a0f-9f0a37a8336d",
      "imagePath": "incretech/ai-rendering/5ed27fc2-37ad-41a2-8a0f-9f0a37a8336d.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:29:03 GMT",
      "generateType": "ai-rendering",
      "id": 208,
      "imageName": "ff332368-9934-43d5-8a2e-268719f076cf",
      "imagePath": "incretech/ai-rendering/ff332368-9934-43d5-8a2e-268719f076cf.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "咖啡机,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:31:07 GMT",
      "generateType": "ai-cmf",
      "id": 209,
      "imageName": "3c24dd96-3afa-453b-922a-6ed027922854",
      "imagePath": "incretech/ai-cmf/3c24dd96-3afa-453b-922a-6ed027922854.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:32:26 GMT",
      "generateType": "ai-cmf",
      "id": 210,
      "imageName": "5a136e68-38f2-4465-98a9-47ea3a6c7378",
      "imagePath": "incretech/ai-cmf/5a136e68-38f2-4465-98a9-47ea3a6c7378.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:33:04 GMT",
      "generateType": "ai-cmf",
      "id": 211,
      "imageName": "0153ce11-2730-4828-8b4b-e1e65140ab37",
      "imagePath": "incretech/ai-cmf/0153ce11-2730-4828-8b4b-e1e65140ab37.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:33:56 GMT",
      "generateType": "ai-cmf",
      "id": 212,
      "imageName": "6476dd8b-f45f-455b-8c4e-85cda4006fc7",
      "imagePath": "incretech/ai-cmf/6476dd8b-f45f-455b-8c4e-85cda4006fc7.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:34:52 GMT",
      "generateType": "ai-cmf",
      "id": 213,
      "imageName": "9fd316b2-ab29-4b6b-aeb6-75f5e68116c3",
      "imagePath": "incretech/ai-cmf/9fd316b2-ab29-4b6b-aeb6-75f5e68116c3.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:35:53 GMT",
      "generateType": "ai-cmf",
      "id": 214,
      "imageName": "a1086b94-86d1-4fa3-9a44-7dd22257ba92",
      "imagePath": "incretech/ai-cmf/a1086b94-86d1-4fa3-9a44-7dd22257ba92.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:41:47 GMT",
      "generateType": "ai-cmf",
      "id": 215,
      "imageName": "c1325cc1-4b1b-4c07-acdf-d35eb2e0517d",
      "imagePath": "incretech/ai-cmf/c1325cc1-4b1b-4c07-acdf-d35eb2e0517d.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 14:43:16 GMT",
      "generateType": "ai-cmf",
      "id": 216,
      "imageName": "de1a46d9-35cb-4e05-955b-5b536faab6e0",
      "imagePath": "incretech/ai-cmf/de1a46d9-35cb-4e05-955b-5b536faab6e0.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:19:33 GMT",
      "generateType": "ai-cmf",
      "id": 217,
      "imageName": "1101aa0e-be9b-47d8-8b52-d512b0d02c99",
      "imagePath": "incretech/ai-cmf/1101aa0e-be9b-47d8-8b52-d512b0d02c99.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:26:51 GMT",
      "generateType": "ai-cmf",
      "id": 218,
      "imageName": "4c4a2340-e8f2-4380-935a-e64a04b7904f",
      "imagePath": "incretech/ai-cmf/4c4a2340-e8f2-4380-935a-e64a04b7904f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:39:12 GMT",
      "generateType": "ai-cmf",
      "id": 219,
      "imageName": "5ed7b82f-05a1-4eca-a82e-7a3c9167eba6",
      "imagePath": "incretech/ai-cmf/5ed7b82f-05a1-4eca-a82e-7a3c9167eba6.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:39:39 GMT",
      "generateType": "ai-cmf",
      "id": 220,
      "imageName": "a75a9139-4dcf-49a1-9a43-3667ad3481aa",
      "imagePath": "incretech/ai-cmf/a75a9139-4dcf-49a1-9a43-3667ad3481aa.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:40:04 GMT",
      "generateType": "ai-cmf",
      "id": 221,
      "imageName": "461b6d89-955b-421a-8888-d95757c897c8",
      "imagePath": "incretech/ai-cmf/461b6d89-955b-421a-8888-d95757c897c8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:49:05 GMT",
      "generateType": "ai-cmf",
      "id": 222,
      "imageName": "eb5fdb17-2abf-4c3e-9188-9d5d49e4a3ec",
      "imagePath": "incretech/ai-cmf/eb5fdb17-2abf-4c3e-9188-9d5d49e4a3ec.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:50:38 GMT",
      "generateType": "ai-cmf",
      "id": 223,
      "imageName": "ef772aa9-5792-4996-b80e-d6241b79d313",
      "imagePath": "incretech/ai-cmf/ef772aa9-5792-4996-b80e-d6241b79d313.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:51:12 GMT",
      "generateType": "ai-cmf",
      "id": 224,
      "imageName": "8194dc5a-b4f6-4641-b112-756f4f980f9a",
      "imagePath": "incretech/ai-cmf/8194dc5a-b4f6-4641-b112-756f4f980f9a.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:52:09 GMT",
      "generateType": "ai-cmf",
      "id": 225,
      "imageName": "d0deb998-f4e8-4bc9-9036-7c5a5c0bad92",
      "imagePath": "incretech/ai-cmf/d0deb998-f4e8-4bc9-9036-7c5a5c0bad92.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:53:08 GMT",
      "generateType": "ai-cmf",
      "id": 226,
      "imageName": "0fdfac44-e66d-46f8-8e43-1a466d7c27c8",
      "imagePath": "incretech/ai-cmf/0fdfac44-e66d-46f8-8e43-1a466d7c27c8.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:53:57 GMT",
      "generateType": "ai-cmf",
      "id": 227,
      "imageName": "fe61e0ee-8638-4f39-8b62-645f61a40a02",
      "imagePath": "incretech/ai-cmf/fe61e0ee-8638-4f39-8b62-645f61a40a02.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 15:55:26 GMT",
      "generateType": "ai-cmf",
      "id": 228,
      "imageName": "f367f2c2-58e7-4355-87f3-f8d48b832474",
      "imagePath": "incretech/ai-cmf/f367f2c2-58e7-4355-87f3-f8d48b832474.png",
      "imageProductType": "咖啡机",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "跑车，高清, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:46:50 GMT",
      "generateType": "ai-cmf",
      "id": 229,
      "imageName": "1e0a5db9-629a-49d0-85c1-54e4dce1d251",
      "imagePath": "incretech/ai-cmf/1e0a5db9-629a-49d0-85c1-54e4dce1d251.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:47:41 GMT",
      "generateType": "ai-cmf",
      "id": 230,
      "imageName": "0d2548e0-27ae-4c9f-b159-0825134f470d",
      "imagePath": "incretech/ai-cmf/0d2548e0-27ae-4c9f-b159-0825134f470d.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:47:55 GMT",
      "generateType": "ai-cmf",
      "id": 231,
      "imageName": "f3a8bcfb-b790-4a7d-b70c-770856e55113",
      "imagePath": "incretech/ai-cmf/f3a8bcfb-b790-4a7d-b70c-770856e55113.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:48:30 GMT",
      "generateType": "ai-cmf",
      "id": 232,
      "imageName": "a7f72052-8295-4592-b8c9-38d371c9e596",
      "imagePath": "incretech/ai-cmf/a7f72052-8295-4592-b8c9-38d371c9e596.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:48:51 GMT",
      "generateType": "ai-cmf",
      "id": 233,
      "imageName": "18082b5f-0fe8-45b0-a0e0-e55f6ae4ef2d",
      "imagePath": "incretech/ai-cmf/18082b5f-0fe8-45b0-a0e0-e55f6ae4ef2d.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:53:00 GMT",
      "generateType": "ai-cmf",
      "id": 234,
      "imageName": "d9498aa2-ab81-46ee-9648-98da23df1d1b",
      "imagePath": "incretech/ai-cmf/d9498aa2-ab81-46ee-9648-98da23df1d1b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:54:51 GMT",
      "generateType": "ai-cmf",
      "id": 235,
      "imageName": "8d795dc8-2ff1-4284-bc72-41af85f8f964",
      "imagePath": "incretech/ai-cmf/8d795dc8-2ff1-4284-bc72-41af85f8f964.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:55:13 GMT",
      "generateType": "ai-cmf",
      "id": 236,
      "imageName": "816b2d8e-c62b-49c4-96d1-2842d1bb50cb",
      "imagePath": "incretech/ai-cmf/816b2d8e-c62b-49c4-96d1-2842d1bb50cb.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:56:22 GMT",
      "generateType": "ai-cmf",
      "id": 237,
      "imageName": "67043835-53b3-4ce7-9b22-95567ff4f7aa",
      "imagePath": "incretech/ai-cmf/67043835-53b3-4ce7-9b22-95567ff4f7aa.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:56:34 GMT",
      "generateType": "ai-cmf",
      "id": 238,
      "imageName": "d24b9033-f351-45e3-b2ce-2f3039c8a6ce",
      "imagePath": "incretech/ai-cmf/d24b9033-f351-45e3-b2ce-2f3039c8a6ce.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 18:56:52 GMT",
      "generateType": "ai-cmf",
      "id": 239,
      "imageName": "8ba4e7eb-fb58-44c5-a45b-82554f7b43f6",
      "imagePath": "incretech/ai-cmf/8ba4e7eb-fb58-44c5-a45b-82554f7b43f6.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:13:19 GMT",
      "generateType": "ai-cmf",
      "id": 240,
      "imageName": "f20deb12-d611-482b-946e-623567e8c32b",
      "imagePath": "incretech/ai-cmf/f20deb12-d611-482b-946e-623567e8c32b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:13:42 GMT",
      "generateType": "ai-cmf",
      "id": 241,
      "imageName": "c1cba6f0-cb06-454b-85db-075a7ec323db",
      "imagePath": "incretech/ai-cmf/c1cba6f0-cb06-454b-85db-075a7ec323db.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:15:30 GMT",
      "generateType": "ai-cmf",
      "id": 242,
      "imageName": "d71024c8-7458-463f-a3fa-5b9ac0693876",
      "imagePath": "incretech/ai-cmf/d71024c8-7458-463f-a3fa-5b9ac0693876.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:16:16 GMT",
      "generateType": "ai-cmf",
      "id": 243,
      "imageName": "407e33ed-91c8-4f5f-8bc3-036bd0cf7dae",
      "imagePath": "incretech/ai-cmf/407e33ed-91c8-4f5f-8bc3-036bd0cf7dae.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:18:15 GMT",
      "generateType": "ai-cmf",
      "id": 244,
      "imageName": "130d1223-7780-44a3-b71b-b93e611124c3",
      "imagePath": "incretech/ai-cmf/130d1223-7780-44a3-b71b-b93e611124c3.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:18:54 GMT",
      "generateType": "ai-cmf",
      "id": 245,
      "imageName": "27f670fd-2abf-4751-bd50-a10e25e38d65",
      "imagePath": "incretech/ai-cmf/27f670fd-2abf-4751-bd50-a10e25e38d65.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:23:31 GMT",
      "generateType": "ai-cmf",
      "id": 246,
      "imageName": "398e5261-d52f-41d1-b4ac-f14726b45f68",
      "imagePath": "incretech/ai-cmf/398e5261-d52f-41d1-b4ac-f14726b45f68.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:30:44 GMT",
      "generateType": "ai-cmf",
      "id": 247,
      "imageName": "1f92b880-2fb2-454b-8df2-36c1acab04bd",
      "imagePath": "incretech/ai-cmf/1f92b880-2fb2-454b-8df2-36c1acab04bd.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:31:37 GMT",
      "generateType": "ai-cmf",
      "id": 248,
      "imageName": "e1c5867c-e712-469a-b741-428cd4bab80f",
      "imagePath": "incretech/ai-cmf/e1c5867c-e712-469a-b741-428cd4bab80f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:34:29 GMT",
      "generateType": "ai-cmf",
      "id": 249,
      "imageName": "beafa565-0365-4a6e-b9ae-d54c65889f21",
      "imagePath": "incretech/ai-cmf/beafa565-0365-4a6e-b9ae-d54c65889f21.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:35:35 GMT",
      "generateType": "ai-cmf",
      "id": 250,
      "imageName": "3297a8d6-5c83-4344-80d6-9917ca26b0d8",
      "imagePath": "incretech/ai-cmf/3297a8d6-5c83-4344-80d6-9917ca26b0d8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:37:51 GMT",
      "generateType": "ai-cmf",
      "id": 251,
      "imageName": "f4a07946-b4bd-4f6e-8425-24196486ddd8",
      "imagePath": "incretech/ai-cmf/f4a07946-b4bd-4f6e-8425-24196486ddd8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:41:18 GMT",
      "generateType": "ai-cmf",
      "id": 252,
      "imageName": "f4bba146-1690-45cd-a154-83526958f4a0",
      "imagePath": "incretech/ai-cmf/f4bba146-1690-45cd-a154-83526958f4a0.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:42:12 GMT",
      "generateType": "ai-cmf",
      "id": 253,
      "imageName": "0c48ed5f-844e-4a43-bfa1-17d52338da95",
      "imagePath": "incretech/ai-cmf/0c48ed5f-844e-4a43-bfa1-17d52338da95.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 19:42:50 GMT",
      "generateType": "ai-cmf",
      "id": 254,
      "imageName": "ca6397af-9a64-4189-8c17-618592bd0187",
      "imagePath": "incretech/ai-cmf/ca6397af-9a64-4189-8c17-618592bd0187.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 22:42:30 GMT",
      "generateType": "ai-cmf",
      "id": 255,
      "imageName": "148c21d1-9a82-42bf-956c-6797164d76a0",
      "imagePath": "incretech/ai-cmf/148c21d1-9a82-42bf-956c-6797164d76a0.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 22:46:33 GMT",
      "generateType": "ai-cmf",
      "id": 256,
      "imageName": "bc8d1e76-27e4-4c51-ae98-fab53588f15a",
      "imagePath": "incretech/ai-cmf/bc8d1e76-27e4-4c51-ae98-fab53588f15a.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 22:57:49 GMT",
      "generateType": "ai-creativity",
      "id": 257,
      "imageName": "1c0fb1d6-b4a2-4ef3-96c1-3a1c321dc570",
      "imagePath": "incretech/ai-creativity/1c0fb1d6-b4a2-4ef3-96c1-3a1c321dc570.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室内场景,正面,特写, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:13:09 GMT",
      "generateType": "ai-creativity",
      "id": 258,
      "imageName": "6c41d9e0-e861-49ee-99d8-9a73c31e9c36",
      "imagePath": "incretech/ai-creativity/6c41d9e0-e861-49ee-99d8-9a73c31e9c36.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度右侧,远景，沙丘，瘦长型瓶体，半透明玻璃材质，琥珀色香水, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:13:40 GMT",
      "generateType": "ai-creativity",
      "id": 259,
      "imageName": "b29e6df0-7c87-4ad7-9f44-aec1fd69d50c",
      "imagePath": "incretech/ai-creativity/b29e6df0-7c87-4ad7-9f44-aec1fd69d50c.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度右侧,远景，沙丘，细细高高长方型瓶体，半透明玻璃材质，琥珀色香水, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:14:13 GMT",
      "generateType": "ai-creativity",
      "id": 260,
      "imageName": "083af136-43dd-49c3-bb28-136b825b7710",
      "imagePath": "incretech/ai-creativity/083af136-43dd-49c3-bb28-136b825b7710.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度左侧,远景，沙丘，细细高高长方型瓶体，半透明玻璃材质，琥珀色香水，岩石, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:14:31 GMT",
      "generateType": "ai-creativity",
      "id": 261,
      "imageName": "7bcc6fbd-d74c-485c-a3ed-750c74890e2c",
      "imagePath": "incretech/ai-creativity/7bcc6fbd-d74c-485c-a3ed-750c74890e2c.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度左侧,远景，沙丘，细细高高长方型瓶体，半透明玻璃材质，琥珀色香水，岩石, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:17:52 GMT",
      "generateType": "ai-creativity",
      "id": 262,
      "imageName": "47b4e7a2-358b-40cb-90f2-27840b0df260",
      "imagePath": "incretech/ai-creativity/47b4e7a2-358b-40cb-90f2-27840b0df260.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度左侧,远景，沙丘，细细高高长方型瓶体，半透明玻璃材质，琥珀色香水，岩石, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:18:10 GMT",
      "generateType": "ai-creativity",
      "id": 263,
      "imageName": "75d905a5-3e93-46e5-82d6-7f4a971d8ad2",
      "imagePath": "incretech/ai-creativity/75d905a5-3e93-46e5-82d6-7f4a971d8ad2.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度左侧,远景，沙丘，细细高高长方型瓶体，半透明玻璃材质，琥珀色香水，岩石, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:18:27 GMT",
      "generateType": "ai-creativity",
      "id": 264,
      "imageName": "e235e301-a6b7-4922-889f-668c19352679",
      "imagePath": "incretech/ai-creativity/e235e301-a6b7-4922-889f-668c19352679.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度左侧,远景，沙丘，细细高高长方型瓶体，半透明玻璃材质，琥珀色香水，岩石, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:19:02 GMT",
      "generateType": "ai-creativity",
      "id": 265,
      "imageName": "e06e476e-e46b-4755-891b-0a21fed5a7d5",
      "imagePath": "incretech/ai-creativity/e06e476e-e46b-4755-891b-0a21fed5a7d5.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度左侧,远景，沙丘，细细高高长方型瓶体，半透明玻璃材质，琥珀色香水，岩石, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Fri, 25 Oct 2024 23:20:00 GMT",
      "generateType": "ai-creativity",
      "id": 266,
      "imageName": "6a9ec754-14f2-49b8-a91b-23e4dd841e86",
      "imagePath": "incretech/ai-creativity/6a9ec754-14f2-49b8-a91b-23e4dd841e86.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特创意80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室外场景,45度左侧,远景，沙丘，细高长方型瓶体，玻璃材质，琥珀色香水，岩石, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sat, 26 Oct 2024 11:46:46 GMT",
      "generateType": "ai-cmf",
      "id": 267,
      "imageName": "1667fce2-9e4a-432a-9d3e-b1b6e39cb31e",
      "imagePath": "incretech/ai-cmf/1667fce2-9e4a-432a-9d3e-b1b6e39cb31e.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:31:22 GMT",
      "generateType": "ai-creativity",
      "id": 268,
      "imageName": "ac03bd0f-96da-4570-9dc3-241e48ce6bb6",
      "imagePath": "incretech/ai-creativity/ac03bd0f-96da-4570-9dc3-241e48ce6bb6.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:32:02 GMT",
      "generateType": "ai-creativity",
      "id": 269,
      "imageName": "a2bde904-35b0-4b6c-9b61-bcdde85b602d",
      "imagePath": "incretech/ai-creativity/a2bde904-35b0-4b6c-9b61-bcdde85b602d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:35:00 GMT",
      "generateType": "ai-creativity",
      "id": 270,
      "imageName": "d8c8aed5-29a3-4773-840a-9a5afe9d242f",
      "imagePath": "incretech/ai-creativity/d8c8aed5-29a3-4773-840a-9a5afe9d242f.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:35:32 GMT",
      "generateType": "ai-creativity",
      "id": 271,
      "imageName": "14409ba2-d641-47f4-b6ad-ea03774598f3",
      "imagePath": "incretech/ai-creativity/14409ba2-d641-47f4-b6ad-ea03774598f3.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:36:27 GMT",
      "generateType": "ai-creativity",
      "id": 272,
      "imageName": "3ac49574-cac1-4e41-b481-a365b5aafea1",
      "imagePath": "incretech/ai-creativity/3ac49574-cac1-4e41-b481-a365b5aafea1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:37:33 GMT",
      "generateType": "ai-creativity",
      "id": 273,
      "imageName": "10551657-3ec7-4f51-99b8-81ebff721103",
      "imagePath": "incretech/ai-creativity/10551657-3ec7-4f51-99b8-81ebff721103.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:38:00 GMT",
      "generateType": "ai-creativity",
      "id": 274,
      "imageName": "ac448ba8-9168-4bc2-b5aa-40df58d930ed",
      "imagePath": "incretech/ai-creativity/ac448ba8-9168-4bc2-b5aa-40df58d930ed.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:39:26 GMT",
      "generateType": "ai-creativity",
      "id": 275,
      "imageName": "d7b48034-2062-4870-a156-3b59d8e73776",
      "imagePath": "incretech/ai-creativity/d7b48034-2062-4870-a156-3b59d8e73776.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:40:49 GMT",
      "generateType": "ai-creativity",
      "id": 276,
      "imageName": "135a5fda-fd09-483e-a5e6-b4982bda2ca1",
      "imagePath": "incretech/ai-creativity/135a5fda-fd09-483e-a5e6-b4982bda2ca1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:41:09 GMT",
      "generateType": "ai-creativity",
      "id": 277,
      "imageName": "ad48ffe2-cf75-49f2-abb5-21407499a01b",
      "imagePath": "incretech/ai-creativity/ad48ffe2-cf75-49f2-abb5-21407499a01b.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:41:29 GMT",
      "generateType": "ai-creativity",
      "id": 278,
      "imageName": "db5286cf-6c67-4a5f-a976-4695dcf71cff",
      "imagePath": "incretech/ai-creativity/db5286cf-6c67-4a5f-a976-4695dcf71cff.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:42:28 GMT",
      "generateType": "ai-creativity",
      "id": 279,
      "imageName": "3db884af-d504-4aac-807f-414b2d6dfe05",
      "imagePath": "incretech/ai-creativity/3db884af-d504-4aac-807f-414b2d6dfe05.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:42:56 GMT",
      "generateType": "ai-creativity",
      "id": 280,
      "imageName": "7c23289e-056c-4fb8-808a-1ee67cf454b7",
      "imagePath": "incretech/ai-creativity/7c23289e-056c-4fb8-808a-1ee67cf454b7.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:43:27 GMT",
      "generateType": "ai-creativity",
      "id": 281,
      "imageName": "55f69b40-26f0-4736-9a56-bdfaa2b7992d",
      "imagePath": "incretech/ai-creativity/55f69b40-26f0-4736-9a56-bdfaa2b7992d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:44:13 GMT",
      "generateType": "ai-creativity",
      "id": 282,
      "imageName": "f28bee25-c48c-4020-96ec-294bfd9db949",
      "imagePath": "incretech/ai-creativity/f28bee25-c48c-4020-96ec-294bfd9db949.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,活力可爱,室内场景,正面,远景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:45:47 GMT",
      "generateType": "ai-rendering",
      "id": 283,
      "imageName": "56d49c5e-c1b8-4068-ab68-9ff839e44340",
      "imagePath": "incretech/ai-rendering/56d49c5e-c1b8-4068-ab68-9ff839e44340.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:46:34 GMT",
      "generateType": "ai-rendering",
      "id": 284,
      "imageName": "229734dc-2cbb-4db3-9fea-cd3cbb9ff610",
      "imagePath": "incretech/ai-rendering/229734dc-2cbb-4db3-9fea-cd3cbb9ff610.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:50:22 GMT",
      "generateType": "ai-rendering",
      "id": 285,
      "imageName": "a1a0b3c2-f837-4899-9d91-e3ce4aaddd44",
      "imagePath": "incretech/ai-rendering/a1a0b3c2-f837-4899-9d91-e3ce4aaddd44.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:50:52 GMT",
      "generateType": "ai-rendering",
      "id": 286,
      "imageName": "6764f9d1-2e9f-4dae-8bfc-faf28bc515bc",
      "imagePath": "incretech/ai-rendering/6764f9d1-2e9f-4dae-8bfc-faf28bc515bc.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:51:44 GMT",
      "generateType": "ai-rendering",
      "id": 287,
      "imageName": "7e765a89-a0c5-41e0-88a5-7ab3f6500a9e",
      "imagePath": "incretech/ai-rendering/7e765a89-a0c5-41e0-88a5-7ab3f6500a9e.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:52:24 GMT",
      "generateType": "ai-rendering",
      "id": 288,
      "imageName": "136572e8-b5b7-48a7-92f6-118b99c9b2cb",
      "imagePath": "incretech/ai-rendering/136572e8-b5b7-48a7-92f6-118b99c9b2cb.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:53:27 GMT",
      "generateType": "ai-rendering",
      "id": 289,
      "imageName": "3aa3f8ec-2311-40d6-8853-1d25bc9feb76",
      "imagePath": "incretech/ai-rendering/3aa3f8ec-2311-40d6-8853-1d25bc9feb76.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "电饭煲,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:55:00 GMT",
      "generateType": "ai-rendering",
      "id": 290,
      "imageName": "e1d29f5d-82dd-47de-acdb-376e864da94f",
      "imagePath": "incretech/ai-rendering/e1d29f5d-82dd-47de-acdb-376e864da94f.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:55:32 GMT",
      "generateType": "ai-rendering",
      "id": 291,
      "imageName": "77191656-ef7b-4254-b701-8ac6e591a795",
      "imagePath": "incretech/ai-rendering/77191656-ef7b-4254-b701-8ac6e591a795.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 17:56:34 GMT",
      "generateType": "ai-rendering",
      "id": 292,
      "imageName": "68d2fbc5-7dec-44a8-a32b-28c7cb17fd79",
      "imagePath": "incretech/ai-rendering/68d2fbc5-7dec-44a8-a32b-28c7cb17fd79.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/大胆创新.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": "香水,摄影写实,室内场景, 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 18:04:19 GMT",
      "generateType": "ai-cmf",
      "id": 293,
      "imageName": "3c4238bf-6ccb-4c3b-965a-bd0fa1a094e7",
      "imagePath": "incretech/ai-cmf/3c4238bf-6ccb-4c3b-965a-bd0fa1a094e7.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 18:04:55 GMT",
      "generateType": "ai-cmf",
      "id": 294,
      "imageName": "46a7b1d6-f62f-48f2-833e-6104ee748805",
      "imagePath": "incretech/ai-cmf/46a7b1d6-f62f-48f2-833e-6104ee748805.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 18:05:15 GMT",
      "generateType": "ai-cmf",
      "id": 295,
      "imageName": "ee19be00-d178-4513-b30d-639d97b1f60f",
      "imagePath": "incretech/ai-cmf/ee19be00-d178-4513-b30d-639d97b1f60f.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 18:05:32 GMT",
      "generateType": "ai-cmf",
      "id": 296,
      "imageName": "3c9624aa-6504-49c2-9aa3-ac3a2166f672",
      "imagePath": "incretech/ai-cmf/3c9624aa-6504-49c2-9aa3-ac3a2166f672.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 18:06:17 GMT",
      "generateType": "ai-cmf",
      "id": 297,
      "imageName": "7c962f96-9be1-468e-b976-328a7aa1b3f0",
      "imagePath": "incretech/ai-cmf/7c962f96-9be1-468e-b976-328a7aa1b3f0.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:40:50 GMT",
      "generateType": "ai-cmf",
      "id": 298,
      "imageName": "31ea7fea-cb58-471c-acb7-20ed80f8c377",
      "imagePath": "incretech/ai-cmf/31ea7fea-cb58-471c-acb7-20ed80f8c377.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:41:12 GMT",
      "generateType": "ai-cmf",
      "id": 299,
      "imageName": "32da7445-5724-4772-afbd-94cbc1a4ddf7",
      "imagePath": "incretech/ai-cmf/32da7445-5724-4772-afbd-94cbc1a4ddf7.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:43:01 GMT",
      "generateType": "ai-cmf",
      "id": 300,
      "imageName": "f21a4ec1-3d91-4a35-9940-971df9db68b5",
      "imagePath": "incretech/ai-cmf/f21a4ec1-3d91-4a35-9940-971df9db68b5.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:51:26 GMT",
      "generateType": "ai-cmf",
      "id": 301,
      "imageName": "0e996cb9-b599-43a4-ba58-626df55fe750",
      "imagePath": "incretech/ai-cmf/0e996cb9-b599-43a4-ba58-626df55fe750.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:51:50 GMT",
      "generateType": "ai-cmf",
      "id": 302,
      "imageName": "8341c08e-0318-4504-99dc-087a2c2da494",
      "imagePath": "incretech/ai-cmf/8341c08e-0318-4504-99dc-087a2c2da494.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:52:21 GMT",
      "generateType": "ai-cmf",
      "id": 303,
      "imageName": "6762fb20-4ffd-4820-8a4a-5ba14d4b0688",
      "imagePath": "incretech/ai-cmf/6762fb20-4ffd-4820-8a4a-5ba14d4b0688.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:52:57 GMT",
      "generateType": "ai-cmf",
      "id": 304,
      "imageName": "26e4139a-c9ee-4c64-b2f4-3a272071139d",
      "imagePath": "incretech/ai-cmf/26e4139a-c9ee-4c64-b2f4-3a272071139d.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:53:24 GMT",
      "generateType": "ai-cmf",
      "id": 305,
      "imageName": "fa9212eb-2d9e-4f9f-9c07-7a4aa9cd6c39",
      "imagePath": "incretech/ai-cmf/fa9212eb-2d9e-4f9f-9c07-7a4aa9cd6c39.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:53:46 GMT",
      "generateType": "ai-cmf",
      "id": 306,
      "imageName": "e54fd195-07b3-489e-a0e9-86fede475415",
      "imagePath": "incretech/ai-cmf/e54fd195-07b3-489e-a0e9-86fede475415.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:54:02 GMT",
      "generateType": "ai-cmf",
      "id": 307,
      "imageName": "4a3d0768-e185-4324-8c65-1e70731e8230",
      "imagePath": "incretech/ai-cmf/4a3d0768-e185-4324-8c65-1e70731e8230.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:54:27 GMT",
      "generateType": "ai-cmf",
      "id": 308,
      "imageName": "720fc154-f233-4ff8-b618-a7309ba2476f",
      "imagePath": "incretech/ai-cmf/720fc154-f233-4ff8-b618-a7309ba2476f.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:54:57 GMT",
      "generateType": "ai-cmf",
      "id": 309,
      "imageName": "47d50cc4-5e4e-43c0-91cd-b8b0a49d061f",
      "imagePath": "incretech/ai-cmf/47d50cc4-5e4e-43c0-91cd-b8b0a49d061f.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:56:32 GMT",
      "generateType": "ai-cmf",
      "id": 310,
      "imageName": "3c569454-0847-4642-ab53-c627dd871855",
      "imagePath": "incretech/ai-cmf/3c569454-0847-4642-ab53-c627dd871855.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:57:15 GMT",
      "generateType": "ai-cmf",
      "id": 311,
      "imageName": "7f610403-0d55-4b63-9c9f-8d9899a0ead1",
      "imagePath": "incretech/ai-cmf/7f610403-0d55-4b63-9c9f-8d9899a0ead1.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 19:57:59 GMT",
      "generateType": "ai-cmf",
      "id": 312,
      "imageName": "f6a4b9d6-6d26-47cb-82b1-45acc5c52e0b",
      "imagePath": "incretech/ai-cmf/f6a4b9d6-6d26-47cb-82b1-45acc5c52e0b.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:01:00 GMT",
      "generateType": "ai-cmf",
      "id": 313,
      "imageName": "27293204-a332-4df0-b715-0ecf78ad7927",
      "imagePath": "incretech/ai-cmf/27293204-a332-4df0-b715-0ecf78ad7927.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:07:04 GMT",
      "generateType": "ai-cmf",
      "id": 314,
      "imageName": "1aff029d-40fe-458c-b738-4443a2c81990",
      "imagePath": "incretech/ai-cmf/1aff029d-40fe-458c-b738-4443a2c81990.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:09:09 GMT",
      "generateType": "ai-cmf",
      "id": 315,
      "imageName": "0c1433d2-7368-433e-aca9-f4d15afe1904",
      "imagePath": "incretech/ai-cmf/0c1433d2-7368-433e-aca9-f4d15afe1904.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:09:39 GMT",
      "generateType": "ai-cmf",
      "id": 316,
      "imageName": "7b0be864-998e-44b5-86d7-4669f8d2f2a0",
      "imagePath": "incretech/ai-cmf/7b0be864-998e-44b5-86d7-4669f8d2f2a0.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:10:11 GMT",
      "generateType": "ai-cmf",
      "id": 317,
      "imageName": "a1aa1584-284c-4308-9e46-14edc4541d31",
      "imagePath": "incretech/ai-cmf/a1aa1584-284c-4308-9e46-14edc4541d31.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:10:31 GMT",
      "generateType": "ai-cmf",
      "id": 318,
      "imageName": "09966ab8-29b2-4c63-b695-f2ff969cca41",
      "imagePath": "incretech/ai-cmf/09966ab8-29b2-4c63-b695-f2ff969cca41.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:10:56 GMT",
      "generateType": "ai-cmf",
      "id": 319,
      "imageName": "c32635d0-5190-47cf-8950-f92ce0944c79",
      "imagePath": "incretech/ai-cmf/c32635d0-5190-47cf-8950-f92ce0944c79.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:11:59 GMT",
      "generateType": "ai-cmf",
      "id": 320,
      "imageName": "48c25fea-5307-4072-a9d5-30443794aa33",
      "imagePath": "incretech/ai-cmf/48c25fea-5307-4072-a9d5-30443794aa33.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 20:12:33 GMT",
      "generateType": "ai-cmf",
      "id": 321,
      "imageName": "3ae31cbe-bfe4-430a-82f6-07ce0cb03780",
      "imagePath": "incretech/ai-cmf/3ae31cbe-bfe4-430a-82f6-07ce0cb03780.png",
      "imageProductType": "其他",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 21:45:38 GMT",
      "generateType": "ai-cmf",
      "id": 322,
      "imageName": "5c236a02-652d-4911-b12b-914f6dcdbd87",
      "imagePath": "incretech/ai-cmf/5c236a02-652d-4911-b12b-914f6dcdbd87.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:15:14 GMT",
      "generateType": "ai-cmf",
      "id": 323,
      "imageName": "e0090295-cb6e-4ee9-bcae-37ff0cd8cbd7",
      "imagePath": "incretech/ai-cmf/e0090295-cb6e-4ee9-bcae-37ff0cd8cbd7.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:15:40 GMT",
      "generateType": "ai-cmf",
      "id": 324,
      "imageName": "2b236d1f-88fe-4cb6-9a9d-bf56b674b946",
      "imagePath": "incretech/ai-cmf/2b236d1f-88fe-4cb6-9a9d-bf56b674b946.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:16:16 GMT",
      "generateType": "ai-cmf",
      "id": 325,
      "imageName": "4804b70b-c286-49bb-ab32-220026860f68",
      "imagePath": "incretech/ai-cmf/4804b70b-c286-49bb-ab32-220026860f68.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:16:36 GMT",
      "generateType": "ai-cmf",
      "id": 326,
      "imageName": "8098be76-619a-412a-b778-6a802828d54c",
      "imagePath": "incretech/ai-cmf/8098be76-619a-412a-b778-6a802828d54c.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:16:52 GMT",
      "generateType": "ai-cmf",
      "id": 327,
      "imageName": "e6e505e0-fca0-4215-82ad-085a22928e59",
      "imagePath": "incretech/ai-cmf/e6e505e0-fca0-4215-82ad-085a22928e59.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:17:08 GMT",
      "generateType": "ai-cmf",
      "id": 328,
      "imageName": "1fad6143-f42e-417b-8452-f7d571596677",
      "imagePath": "incretech/ai-cmf/1fad6143-f42e-417b-8452-f7d571596677.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:17:31 GMT",
      "generateType": "ai-cmf",
      "id": 329,
      "imageName": "dd5bc523-81f4-4127-a364-5b38e167c217",
      "imagePath": "incretech/ai-cmf/dd5bc523-81f4-4127-a364-5b38e167c217.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:18:22 GMT",
      "generateType": "ai-cmf",
      "id": 330,
      "imageName": "4f6da85d-dec7-43a0-a0e3-3e36aae5bd54",
      "imagePath": "incretech/ai-cmf/4f6da85d-dec7-43a0-a0e3-3e36aae5bd54.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:18:42 GMT",
      "generateType": "ai-cmf",
      "id": 331,
      "imageName": "38bf3e94-4aa5-45d6-b028-231144dbd923",
      "imagePath": "incretech/ai-cmf/38bf3e94-4aa5-45d6-b028-231144dbd923.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:26:51 GMT",
      "generateType": "ai-cmf",
      "id": 332,
      "imageName": "ced35fe7-8efe-4afc-ac97-06625b86112a",
      "imagePath": "incretech/ai-cmf/ced35fe7-8efe-4afc-ac97-06625b86112a.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:27:51 GMT",
      "generateType": "ai-cmf",
      "id": 333,
      "imageName": "2b8a5fa3-42ca-42f4-8141-7b40c3358955",
      "imagePath": "incretech/ai-cmf/2b8a5fa3-42ca-42f4-8141-7b40c3358955.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:28:07 GMT",
      "generateType": "ai-cmf",
      "id": 334,
      "imageName": "327d6c60-2ed1-4a18-aba6-01deb027f35c",
      "imagePath": "incretech/ai-cmf/327d6c60-2ed1-4a18-aba6-01deb027f35c.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:28:29 GMT",
      "generateType": "ai-cmf",
      "id": 335,
      "imageName": "76da2eb9-4a3e-4d73-a457-ca8d446d1c1d",
      "imagePath": "incretech/ai-cmf/76da2eb9-4a3e-4d73-a457-ca8d446d1c1d.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:28:52 GMT",
      "generateType": "ai-cmf",
      "id": 336,
      "imageName": "de1666e5-3946-4a01-b66d-bcca8b935ed8",
      "imagePath": "incretech/ai-cmf/de1666e5-3946-4a01-b66d-bcca8b935ed8.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:29:08 GMT",
      "generateType": "ai-cmf",
      "id": 337,
      "imageName": "2eb22c72-e71d-4470-98f9-386495422aec",
      "imagePath": "incretech/ai-cmf/2eb22c72-e71d-4470-98f9-386495422aec.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:29:18 GMT",
      "generateType": "ai-cmf",
      "id": 338,
      "imageName": "5d347134-f234-4740-99a3-2856fdaf35b2",
      "imagePath": "incretech/ai-cmf/5d347134-f234-4740-99a3-2856fdaf35b2.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    },
    {
      "createdTime": "Sun, 27 Oct 2024 22:30:39 GMT",
      "generateType": "ai-cmf",
      "id": 339,
      "imageName": "b5e799ae-e6ba-4163-9739-a8d6746ab1e1",
      "imagePath": "incretech/ai-cmf/b5e799ae-e6ba-4163-9739-a8d6746ab1e1.png",
      "imageProductType": "电饭煲",
      "loraModel": "yilaite/新伊莱特混合80.safetensors",
      "originMarkPath": "",
      "originalImagePath": "",
      "prompt": ", 高清",
      "sdModel": "creativity/creativity.safetensors",
      "userId": 1
    }
  ]